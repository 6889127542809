import { Container, Content, FlexboxGrid, Panel, Progress } from "rsuite";

interface IProps {
    header?: string,
    text?: string,
    progress?: number,
}

export function _DbProgress(props: IProps) {
    return <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={12}>
            <Panel style={{ margin: "300px 0 0 0" }} header={<h3>{props.header}</h3>} bordered>
                {props.text}
            </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
            <Progress percent={props.progress} />
        </FlexboxGrid.Item>
    </FlexboxGrid>
}