import { Icon } from "@rsuite/icons";
import { FaArchive, FaBoxes, FaDolly, FaRegObjectGroup, FaShoppingCart, FaTruck, FaUsersCog, FaWrench } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Nav } from "rsuite";

export function _HNav() {
    const activeKey = location.pathname.replace(/(\/\w*)(\/\w*)?.*/, "$1$2");
    const nav = useNavigate();
    const handleNav = (eventKey?: string) => {
        nav(eventKey?.replaceAll('*', '') ?? "");
    }
    return (
        <Nav className="PrintNone" activeKey={activeKey} appearance="tabs" color="#ccc">
            <Nav.Item eventKey="/Orders/Mua" icon={<Icon as={FaTruck} />} onSelect={handleNav}>Mua</Nav.Item>
            <Nav.Item eventKey="/Orders/BanSi" icon={<Icon as={FaDolly} />} onSelect={handleNav}>Bán sỉ</Nav.Item>
            <Nav.Item eventKey="/Orders/BanLe" icon={<Icon as={FaShoppingCart} />} onSelect={handleNav}>Bán lẻ</Nav.Item>
            <Nav.Item eventKey="/Orders/SuaChua" icon={<Icon as={FaWrench} />} onSelect={handleNav}>Sửa chữa</Nav.Item>
            <Nav.Item eventKey="/NewOrders/SuaChua" icon={<Icon as={FaUsersCog} />} onSelect={handleNav}>Phân công</Nav.Item>
        </Nav>)
}