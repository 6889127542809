import { Icon } from "@rsuite/icons";
import { FaClosedCaptioning, FaExpandAlt } from "react-icons/fa";
import { AutoComplete, IconButton, Input, InputGroup, InputNumber, Tooltip, Whisper } from "rsuite";
import { Cell } from "rsuite-table";
export interface IdRow {
    Selected?: boolean;
    Warning?: boolean;
    Disabled?: boolean;
    Deprecated?: boolean;
    DeprecatedSp?: boolean;
    Editing?: boolean;
    Expanded?: boolean;
    Alt?: boolean;
}
export function getProps(key: string | undefined, value: any): any {
    if (!!!key) return value;
    const idx = key.indexOf('.');
    //console.debug(key,value);
    if (!!!value) return value;
    if (idx > -1)
        return (getProps(key.substring(idx + 1), value[key.substring(0, idx)]));
    return value[key];
}
export function setProps(key: string | undefined, data: any, value: any) {
    if (!!!key) return;
    const idx = key.indexOf('.');
    if (!!!data) return;
    if (idx > -1)
        setProps(key.substring(idx + 1), data[key.substring(0, idx)], value);
    else
        data[key] = value;
}
function validProps(key: string | undefined, data: any): boolean {
    if (!!!key) return false;
    const idx = key.indexOf('.');
    if (!!!data) return false;
    if (idx > -1)
        return validProps(key.substring(idx + 1), data[key.substring(0, idx)]);
    else
        return true;
}
export const shortDate = (date?: string) => {
    if (!!!date) return 'Chưa';
    const m = date.match(/\d{2}(\d{2})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/);
    return `${m?.[3]}/${m?.[2]} ${m?.[4]}:${m?.[5]}`;
}
export const shortDateY = (date?: string) => {
    if (!!!date) return 'Chưa';
    const m = date.match(/\d{2}(\d{2})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/);
    return `${m?.[3]}/${m?.[2]}/${m?.[1]} ${m?.[4]}:${m?.[5]}`;
}
// export const RowDat2Style: (rowData: any, def?: React.CSSProperties) => React.CSSProperties = (rowData, def) => {
//     def = def ?? {};
//     // if (!!rowData?.Warning)
//     //     return { ...def, backgroundColor: '#ff3434', color: '#fff' };
//     // if (!!rowData?.Disabled)
//     //     return { ...def, backgroundColor: '#fff', color: '#ccc' };
//     return def;
// };
export const ClassByRowData: (rowData: any, def?: string) => string = (rowData, def) => {
    var Out: string[] = [];
    if (!!def) Out.push(def);
    if (!!rowData?.Alt)
        Out.push("Alt");
    if (!!rowData?.Deprecated)
        Out.push("Deprecated");
    if (!!rowData?.DeprecatedSp)
        Out.push("DeprecatedSp");
    if (!!rowData?.Selected)
        Out.push("Selected");
    if (!!rowData?.Warning)
        Out.push("Warning");
    if (!!rowData?.Disabled)
        Out.push("Disabled");
    return Out.join(' ');
};
export const ReadonlyCell = ({ rowData, dataKey, children, ...props }: any) => {
    return (
        <Cell className={ClassByRowData(rowData, dataKey + " p4")} {...props}>
            {children ? <span key={rowData.Id}>{children?.(rowData, getProps(dataKey, rowData))}</span> : <span>{getProps(dataKey, rowData)}</span>}
        </Cell>);
};
export const CompactSpeakerReadonlyCell = ({ rowData, speaker, ...props }: any) => {
    return (<Cell className={ClassByRowData(rowData, "p4")} {...props}><Whisper placement="top" speaker={<Tooltip>{speaker?.(rowData)}</Tooltip>}>
        <span>{props.children(rowData)}</span></Whisper></Cell>);
};
export const baseOnChange = (rowData: any, dataKey: string, value: any, callBack?: (dat: any) => void) => {
    setProps(dataKey, rowData, value);
    callBack?.(rowData);
};
export const btnPlus = (evt: React.MouseEvent) => {
    var inputElm = evt.currentTarget.parentElement?.querySelector("input") as HTMLInputElement;
    if (evt.ctrlKey)
        inputElm.value = "" + (Number(inputElm.value) + 10);
    else
        inputElm.value = "" + (Number(inputElm.value) + 1);
    inputElm.focus();
};
export const btnMinus = (evt: React.MouseEvent) => {
    var inputElm = evt.currentTarget.parentElement?.querySelector("input") as HTMLInputElement;
    if (evt.ctrlKey)
        inputElm.value = "" + (Number(inputElm.value) - 10);
    else
        inputElm.value = "" + (Number(inputElm.value) - 1);
    inputElm.focus();
};
export const CustomCell = ({ rowData, dataKey, onChange, ...props }: any) => {
    return (
        <Cell className={ClassByRowData(rowData, "p4")} {...props}>
            {(rowData?.Editing && validProps(dataKey, rowData)) ? (!!props.children ? props.children?.(rowData) :
                <Input className="rs-input rs-input-xs"
                    placeholder={dataKey}
                    defaultValue={getProps(dataKey, rowData)}
                    onChange={(value: any) => { baseOnChange(rowData, dataKey, value, onChange); }}
                    disabled={props.disabled}
                />) :
                (!!props.display ? <span key={rowData.Id}>{props.display?.(rowData)}</span> : <span>{getProps(dataKey, rowData)}</span>)}
        </Cell >)
}
export const InputCell = ({ rowData, dataKey, onChange, ...props }: any) => {
    return (
        <Cell className={ClassByRowData(rowData, "p4")} {...props}>
            {(rowData?.Editing && validProps(dataKey, rowData)) ? (
                <Input className="rs-input rs-input-xs"
                    placeholder={dataKey}
                    defaultValue={getProps(dataKey, rowData)}
                    onChange={(value: any) => { baseOnChange(rowData, dataKey, value, onChange); }}
                    disabled={props.disabled}
                />
            ) : (
                <span className="table-content-edit-span">{getProps(dataKey, rowData)}</span>
            )}
        </Cell>);
};
export const NumCell = ({ rowData, dataKey, onChange, ...props }: any) => {
    return (
        <Cell className={ClassByRowData(rowData, "p4")} {...props}>
            {(rowData?.Editing && validProps(dataKey, rowData)) ? (
                !!!props.btns ?
                    (<InputNumber className="a-right"
                        size="xs"
                        placeholder={dataKey}
                        defaultValue={getProps(dataKey, rowData)}
                        onChange={(value) => { baseOnChange(rowData, dataKey, value, onChange); }}
                        disabled={props.disabled}
                    />) :
                    (<InputGroup size="xs">
                        <InputGroup.Button onClick={(evt) => btnPlus(evt)}>+</InputGroup.Button>
                        <InputNumber className="a-right"
                            size="xs"
                            placeholder={dataKey}
                            defaultValue={getProps(dataKey, rowData)}
                            onChange={(value) => { baseOnChange(rowData, dataKey, value, onChange); }}
                            disabled={props.disabled}
                        />
                        <InputGroup.Button onClick={(evt) => btnMinus(evt)}>-</InputGroup.Button>
                    </InputGroup>)
            ) : (
                <span className="table-content-edit-span a-right">{getProps(dataKey, rowData)}</span>
            )}
        </Cell>);
};
export const AutoCell = ({ rowData, dataKey, onSelect, data, labelKey, filterBy, ...props }: any) => {
    return (
        <Cell className={ClassByRowData(rowData)} {...props}>
            {(rowData?.Editing && validProps(dataKey, rowData)) ? (
                <AutoComplete size="xs" className="rs-input rs-input-xs"
                    placeholder={dataKey}
                    defaultValue={getProps(dataKey, rowData) ?? ""}
                    onSelect={(value: any, item: any, event: any) => { onSelect(value, item); event.preventDefault(); }}
                    disabled={props.disabled} data={data} filterBy={filterBy} />
            ) : (
                <span className="table-content-edit-span">{getProps(dataKey, rowData)}</span>
            )}
        </Cell>);
};
export const ExpandCell = ({ rowData, dataKey, onChange, ...props }: any) => (
    <Cell className={ClassByRowData(rowData, "p4")} {...props} style={{ padding: 5 }}>
        <IconButton
            appearance="subtle"
            onClick={() => {
                onChange(rowData);
            }}
            icon={rowData?.Expanded ? (
                <Icon as={FaClosedCaptioning} />
            ) : (
                <Icon as={FaExpandAlt} />
            )
            }
        />
    </Cell>
);