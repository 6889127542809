import { Icon } from '@rsuite/icons';
import React from 'react';
import { FaQrcode, FaTags } from 'react-icons/fa';
import { AutoComplete, Badge, IconButton, InputGroup, Modal, Tag, TagGroup } from 'rsuite';
import { Db } from '../../c/Db';
import { c } from '../../c/ModelHelper';
import { jm } from '../../m/JModel';
import { m } from '../../m/Model';
interface INode {
    value?: string,
    label?: string,
    cound?: number,
    type?: "Tag" | undefined,
    item: m.Tag | undefined
}
interface IProps {
    children?: React.ReactNode;
    lastOnly?: boolean;
    icon?: React.ReactNode;
    defaultValue?: string;
    tagsCat?: m.CATALOG;
    onChange?: (text?: string, ids?: number[]) => void;
    onSubmit?: () => void;
}
export function _SearchTags(props: IProps) {
    const [Nodes, setNodes] = React.useState<INode[]>();
    const [sTags, setSTags] = React.useState<m.Tag[]>([]);
    const [bTags, setBTags] = React.useState(true);
    const [Text, setText] = React.useState(props.defaultValue ?? "");
    const [isPedding, start] = React.useTransition();
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        start(() => {
            (async () => {
                var testI = 0;
                try {
                    const tpSr = await Db.All<jm.TagProduct>("TagProducts") ?? [];
                    var pIds: number[] = (await Db.All<jm.TagProduct>("TagProducts")).map(x => x.P).filter((x, i, a) => a.indexOf(x) == i) ?? [];
                    testI++;
                    sTags.forEach(async t => {
                        const tp = tpSr.filter(x => x.T == t.ID).map(x => x.P);
                        pIds = pIds.filter(x => tp.includes(x));
                    });
                    testI++;
                    var countT = new Map<number, number>();
                    (await Db.Where<jm.TagProduct>("TagProducts", tp => pIds.includes(tp.P))).forEach(x => {
                        if (!countT.has(x.T)) {
                            countT.set(x.T, 1);
                        } else {
                            countT.set(x.T, (countT.get(x.T) ?? 0) + 1);
                        }
                    });
                    testI++;
                    var node = (await Db.Where<m.Tag>("Tags", t => {
                        if (sTags.length == 0)
                            return c.Tag.check(t, props.tagsCat);
                        else {
                            return (!!props.lastOnly||countT.has(t.ID)) && c.Tag.check(t, props.tagsCat);
                        }
                    })).map(t => ({ value: `#${t.ID}`, label: `${t.Name}`, type: "Tag", item: t, cound: countT.get(t.ID) } as INode))
                        .sort((b, a) => (a.cound ?? 0) - (b.cound ?? 0));
                    setNodes(node);
                    testI++;
                    setLoading(false);
                } catch (ex) {
                    console.debug("error", Db.db);
                    console.debug("error", ex, testI);
                }
            })();
        })
    }, [props.tagsCat, sTags]);
    React.useEffect(() => {
        props.onChange?.(Text, sTags.map(x => x.ID));
    }, [Text, sTags])
    const OnSelect = (_value: any, _item: any, evt: React.SyntheticEvent<Element, Event>) => {
        const item = _item as INode;
        //var nTags: INode[] = [];
        if (item.type == "Tag" && !!item.item) {
            var ids = item.item.TreeKeys.split('.').map(x => Number(x));
            if (!!props.lastOnly)
                ids = ids.slice(-2, -1);
            const cTags = Nodes?.filter(x => !!x.item).map(x => x.item) as m.Tag[];
            const nTags = [...sTags, ...(cTags?.filter(x => ids.includes(x.ID) && c.Tag.check(x, props.tagsCat)) ?? [])];
            setSTags(nTags.filter((x, i, a) => a.map(y => y.ID).indexOf(x.ID) == i).sort((a, b) => a.TreeKeys.localeCompare(b.TreeKeys)));
            console.debug("S", sTags);
        }
    }
    const onChange = (value: string, evt: any) => {
        if (value.startsWith("#"))
            setText("");
        else {
            setText(value);
        }
    }
    const FilterBy = (value: string, _item: any) => {
        // const str = (value).toUpperCase();
        var p = _item as INode;
        if (!!!p.item || (!!!props.lastOnly && p.cound == 0)) return false;
        //if (!c.Tag.check(p.item, props.tagsCat)) return false;
        if (sTags.map(x => x.ID).includes(p.item.ID ?? -1)) return false;
        return (p.item.UName.indexOf(value.toUpperCase()) ?? -2) > -1;
    }
    const renderTag = (dat: m.Tag) => {
        return <Tag color='violet' key={`Tag${dat.ID}`} closable onClose={() => setSTags(sTags.filter(x => x.ID != dat.ID))}>{dat.Name}</Tag>
    }
    const _renderItemParent = (PID: number | undefined): string => {
        if (!!!Nodes || !!!PID) return "";
        var n = Nodes.find(x => x.item?.ID == PID);
        if (!!!n || !!!n.item || !c.Tag.check(n.item, props.tagsCat)) return "";
        return _renderItemParent(n.item?.PID) + " " + n.label;
    };
    const renderItem = (label: any, _dat: any) => {
        const item = _dat as INode;
        return <div><Badge color='orange' content={`${item.cound ?? ""}`}>{_renderItemParent(item.item?.ID)}</Badge></div>
        // return <div><Badge color='orange' content={`${item.cound ?? ""}`}>{item.label}</Badge></div>
    }
    const OnKey = (event: React.KeyboardEvent<Element>) => {
        //console.debug(event.key,event.ctrlKey);
        if (event.key == "Enter") {
            console.debug(Text, sTags);
            event.preventDefault();
            props.onSubmit?.();
        }
    }
    const toggleBTag = () => {
        setBTags(!bTags);
    }
    return <InputGroup>
        {!!!props.icon ?
            <InputGroup.Button loading={isPedding || loading} onClick={toggleBTag}>
                {bTags ? <Icon as={FaTags} /> : <Icon as={FaQrcode} />}
            </InputGroup.Button> : <IconButton loading={isPedding || loading}>{props.icon}</IconButton>}
        <TagGroup>
            {sTags.map(x => renderTag(x))}
        </TagGroup>
        <AutoComplete onKeyUp={OnKey} selectOnEnter={false} renderMenuItem={renderItem} value={Text} onChange={onChange} data={Nodes ?? []} filterBy={FilterBy} onSelect={OnSelect} />
        {props.children}
    </InputGroup>
}