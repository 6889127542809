import React from "react";
import { Icon } from "@rsuite/icons";
import { FaUserCheck, FaUserSlash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonToolbar, Container, Content, FlexboxGrid, Form, IconButton, Input, Panel } from "rsuite";
import { Lib } from "../../c/Lib";

export function Login() {
    const param = useParams<{ url?: string }>();
    const nav = useNavigate();
    const [email, SetEmail] = React.useState<string | undefined>("");
    const [password, SetPassword] = React.useState<string | undefined>("");
    const handleEmail = (value: any) => {
        SetEmail(value);
    };
    const handlePassword = (value: any) => {
        SetPassword(value)
    };
    const Submit = (evt: any) => {
        //evt.preventDefault();
        Lib.Post(`/api/Account/Login`, JSON.stringify({ Email: email, Password: password }), "", async () => {
            nav(-1);
        });
    }
    const btnLogOff = () => {
        Lib.Get(`/api/Account/Logout`, "", async () => {
            nav(-1);
        });
    }
    return (
        <Container>
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={12}>
                        <Panel header={<h3>Login</h3>} bordered>
                            <Form fluid onSubmit={Submit}>
                                <Form.Group>
                                    <Form.ControlLabel>Email</Form.ControlLabel>
                                    <Input name="Email" type="email" autoComplete="Email" onChange={handleEmail} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>Password</Form.ControlLabel>
                                    <Input name="Password" type="password" autoComplete="current-password" onChange={handlePassword} />
                                </Form.Group>
                                <ButtonToolbar>
                                    <IconButton type="submit" icon={<Icon as={FaUserCheck} />} placement="left">
                                        Đăng nhập
                                    </IconButton>
                                    <IconButton onClick={btnLogOff} icon={<Icon as={FaUserSlash} />} placement="left">
                                        Đăng xuất
                                    </IconButton>
                                </ButtonToolbar>
                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    )
}