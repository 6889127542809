import React from "react";
import { Icon } from "@rsuite/icons";
import { FaCogs, FaPlug, FaQrcode, FaToolbox } from "react-icons/fa";
import { AutoComplete, InputGroup } from "rsuite";
import { m } from "../../m/Model";
import { Lib } from "../../c/Lib";
interface IProps {
    disableSelect?: boolean;
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: string;
    onOrder?: (order?: m.Order) => void;
    onProduct?: (propduct?: m.Product) => void;
    onManualProduct?: (propduct?: m.Product) => void;
    onUnit?: (unit: m.Unit, manual?: boolean) => void;
    onSerial?: (serial: string, SKU?: string, PCode?: string) => void;
    onText?: (keyword?: string) => void;
    Ec?: boolean;
    catalog?: string;
    children?: any;
    onSubmit?: () => void;
}
interface IData extends m.Product {
    label?: string;
}
export function Barcode(props: IProps) {
    //#region const
    // const [barcodeLog, setBarcodeLog] = useCache<string[]>("barcodeLog");
    const [Code, setCode] = React.useState<string>(props.defaultValue ?? "");
    const [Data, setData] = React.useState<IData[]>([]);
    const [loading, setLoading] = React.useState(false);
    const timerRef = React.useRef<number>();
    //#endregion
    //#region function
    const barcodeProcess = (code?: string, nonProcess?: boolean) => {
        console.debug("barcode", code);
        clearTimeout(timerRef?.current);
        setLoading(false);
        if (!!!code) return false;
        var isFound = false;
        var _CODE = code.toUpperCase()
            .replace("HTTP://", "")
            .replace("CODE.LANANHPT.COM/", "")
            .replaceAll("@", "");
        const CODE = _CODE.replaceAll(" ", "");
        if (!!props.onOrder && (CODE.startsWith("+O") || CODE.startsWith("O"))) {
            _CODE = _CODE.trim().replace("+", "").replace("O", "");
            if (!!!nonProcess) {
                setLoading(true);
                const str = `/API/Orders/Get?Id=${m.GetOId(_CODE)}&Otype=${m.GetOType(_CODE)}`;
                Lib.Get(`/API/Orders/Get?Id=${m.GetOId(_CODE)}&Otype=${m.GetOType(_CODE)}`, "Load m.Order", async (rs) => {
                    setLoading(false);
                    setCode("");
                    if (rs.status == 200) {
                        const o = await rs.json() as m.Order;
                        props.onOrder?.(o);
                    }
                });
            }
            isFound = true;
        } else if (!!props.onUnit && (CODE.startsWith("+U") || CODE.startsWith("U"))) {
            _CODE = _CODE.trim().replace(" ", "/").replace(/[^\d]+(\d+)$/g, "$1");
            if (!!!nonProcess) {
                setLoading(true);
                Lib.Get(`/API/Units/Get?Id=${_CODE}`, "Load m.Unit", async (rs) => {
                    setLoading(false);
                    setCode("");
                    if (rs.status == 200) {
                        const u = await rs.json() as m.Unit;
                        props.onUnit?.(u, CODE.startsWith("+U"));
                    }
                    else {
                        const u = { ...m.DEFAULT.Unit, Id: Number(_CODE) } as m.Unit;
                        props.onUnit?.(u, CODE.startsWith("+U"));
                    }
                });
            }
            isFound = true;
        } else if (!!props.onSerial && (CODE.startsWith("+S") || CODE.startsWith("S"))) {
            if (!!!nonProcess) {
                props.onSerial?.(_CODE.trim().replace(" ", "/").replace(/\+?\w\/?([^\/]+)$/, "$1"));
                setCode("");
            }
            isFound = true;
        } else if (!!props.onSerial && (/(\w+)\s+(\d{9})(\d{9}\w?)0(\d{12})VN\w$/).test(_CODE)) {
            var reg = _CODE.match(/(\w+)\s+(\d{9})(\d{9}\w?)0(\d{12})VN\w$/);
            if (reg && reg[3].startsWith(reg[2])) {
                if (!!!nonProcess) {
                    props.onSerial?.(reg[3].replace(/^0+/, ""), reg[4], reg[1]);
                    setCode("");
                }
                isFound = true;
            }
            // m = _CODE.match(/^01\d{1}(\d{13})11(\d{6})21(\d{9})\.*$/);
            // if (!!m) {
            //     if (!!!nonProcess) {
            //         props.onSerial?.(m[3].replace(/^0+/, ""), m[1]);
            //         setCode("");
            //     }
            //     isFound = true;
            // }
        } else if (!!props.onProduct && (CODE.startsWith("+P") || CODE.startsWith("P"))) {
            _CODE = CODE.replace(" ", "/").replace(/[^\d]+(\d+)$/g, "$1");
            if (!!!nonProcess) {
                setLoading(true);
                Lib.Get(`/API/Products/Scan?code=${_CODE}`, "Load m.Product", async (rs) => {
                    setLoading(false);
                    setCode("");
                    props.onProduct?.(await rs.json() as m.Product);
                });
            }
            isFound = true;
        } else if (!!props.onProduct && (/^\d{11,13}$/).test(_CODE)) {
            if (!!!nonProcess) {
                setLoading(true);
                Lib.Get(`/API/Products/Scan?code=${_CODE}`, "Load m.Product", async (rs) => {
                    const p = await rs.json() as m.Product;
                    setLoading(false);
                    if (!!p) {
                        props.onProduct?.(p);
                        setCode("");
                    } else {
                    }
                });
            }
            isFound = true;
        } else if (!!props.onProduct && (/d{6,9}[ABCDE-]\d+ ?.*/).test(_CODE)) {
            const S_CODE = _CODE.split(' ');
            if (!!!nonProcess) {
                setLoading(true);
                Lib.Get(`/API/Products/Scan?code=${S_CODE[0]}`, "Load m.Product", async (rs) => {
                    const p = await rs.json() as m.Product;
                    setLoading(false);
                    if (!!p) {
                        props.onProduct?.(p);
                        setCode("");
                    } else {
                    }
                });
            }
            isFound = true;
        } else if (CODE.startsWith("+")) {
            return false;
        }
        if (!isFound && !!props.onText && _CODE != "" && !!_CODE) {
            props.onText?.(_CODE);
        }
        return isFound;
    };
    //#endregion
    //#region hook
    //#region callback
    //#endregion callback
    //#region effect
    React.useEffect(() => {
        return () => clearTimeout(timerRef?.current);
    }, []);
    React.useEffect(() => {
        clearTimeout(timerRef?.current);
        if (Code?.length > 0)
            timerRef.current =
                setTimeout(() => {
                    DelayChange(Code);
                }, 500) as unknown as number;
    }, [Code]);
    //#endregion effect
    //#endregion hook
    //#region event
    const DelayChange = (keyword?: string) => {
        const str = (keyword ?? "").toUpperCase().split('=');

        const nextData = Data.filter(x => x.Code.includes(str[str.length - 1]));
        if (nextData.length > 0) {

        } else if (barcodeProcess(keyword, true)) {
            setCode(keyword ?? "");
        } else if (!!!props.disableSelect) {
            if (str[str.length - 1].length == 0)
                return;
            setLoading(true);
            Lib.Get(`/api/Products/${props.Ec ? "FindEc" : "Find"}?q=${str[str.length - 1]}&cat=${props.catalog ?? "1,3,2"}`, "search", async (rs) => {
                setLoading(false);
                setData((await rs.json() as IData[]).map(x => ({ ...x, label: (x.Code ?? "") + (x.PNames ?? "") })));
            });
        }
        //  else if (!!props.onSelect) {
        //     props.onSelect?.(keyword as string);
        // }
        // InputRef.current.root.querySelector(".rs-picker-search-input").focus();
    }
    const OnSelect = (_value: any, _item: any) => {
        const item = _item as m.Product;
        //if()return;
        if (!!Code && Code.indexOf("=") > -1) {
            if (Code.indexOf(":") > -1) {
                const strs1 = Code.split('=');
                const strs2 = strs1[0].split(':');
                Lib.Post(`/API/Products/Update`, JSON.stringify({ ...item, PKG_SKU: strs2[0], PCSinPKG: Number(strs2[1]) } as m.Product), "Update sku", async (rs) => {
                    props.onProduct?.(await rs.json() as m.Product);
                });
            } else {
                Lib.Post(`/API/Products/Update`, JSON.stringify({ ...item, SKU: Code.replace(/^([^\=]*)\=.*$/g, "$1") } as m.Product), "Update sku", async (rs) => {
                    props.onProduct?.(await rs.json() as m.Product);
                });
            }
        } else {
            props.onManualProduct?.(item);
        }
        // if (!!!props.onSelect) {
        //     setCode("");
        //     setData([]);
        // }
    }
    const OnKey = (event: React.KeyboardEvent<Element>) => {
        //console.debug(event.key,event.ctrlKey);
        if (event.key == "Enter") {
            if (!!!props.onText || !!!props.onSubmit || barcodeProcess(Code, true)) {
                barcodeProcess(Code);
                event.preventDefault();
            } else {
                event.preventDefault();
                props.onText?.(Code);
                props.onSubmit?.();
            }
        }
    }
    const FilterBy = (value: string, _item: any) => {
        const str = (value ?? "").toUpperCase().split('=');
        if (str[0].length < 1) return true;
        var p = _item as IData;
        var Regex = new RegExp(`.*${str[str.length - 1].replaceAll(" ", ".*")}.*`);
        return Regex.test(p.label ?? "");
    }
    const RenderMenuItem = (lable: any, item: any) => {
        const p = item as IData;
        return <div>
            {p.Catalog == m.CATALOG.Machine ? <Icon as={FaPlug} /> :
                p.Catalog == m.CATALOG.Accessory ? <Icon as={FaToolbox} /> : <Icon as={FaCogs} />}
            {p.Name}
        </div>
    }
    //#endregion
    //#region btn
    //#endregion
    //#endregion
    return <InputGroup disabled={props.disabled}>
        <InputGroup.Button loading={loading}>
            <Icon as={FaQrcode} />
        </InputGroup.Button>
        {/* value={code ?? ""} */}
        <AutoComplete onKeyPress={OnKey} value={Code} placeholder={props.placeholder ?? "Mã vạch=Mã số"} onChange={setCode} onSelect={OnSelect} data={Data}
            filterBy={FilterBy}
            renderMenuItem={RenderMenuItem} />
        {props.children}
    </InputGroup>
}