import { Icon } from "@rsuite/icons";
import { FaSave, FaTimes, FaWindowClose } from "react-icons/fa";
import { IconButton, Modal } from "rsuite";
interface IProps {
    open?: boolean;
    onSave?: () => void;
    onClose?: () => void;
    onDismiss?: () => void;
}
export function _Confirm(props: IProps) {
    return <Modal open={props.open}>
        <Modal.Body>Lưu thay đổi</Modal.Body>
        <Modal.Footer>
            <IconButton onClick={props.onSave} appearance="primary" color="blue" icon={<Icon as={FaSave} />}>Lưu thay đổi</IconButton>
            <IconButton onClick={props.onClose} appearance="primary" color="red" icon={<Icon as={FaTimes} />}>Hủy thay đổi</IconButton>
            <IconButton onClick={props.onDismiss} appearance="primary" color="orange" icon={<Icon as={FaWindowClose} />}>Xem lại</IconButton>
        </Modal.Footer>
    </Modal>
}