import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Lib } from "../../c/Lib";
import { m } from "../../m/Model";
import { NotFound } from "../Home/Index";
import { Loader, Placeholder } from "rsuite";

interface IProps {
}
export function Scan(props: IProps) {
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading,setLoading]=useState(false);
    React.useEffect(() => {
        const c = searchParams.get("s");
        ProductByCode(c).then(rs => {
            if (!!(rs as m.Product)) {
                const p = rs as m.Product;
                nav(`/Products/Stores/${p.Id}`);
            }
        });
    }, []);
    const ProductByCode = (code?: string | null): Promise<any> => new Promise<any>((resolve, reject) => {
        if (!!!code) { reject("Emty"); return; }
        var _CODE = code.toUpperCase()
            .replace("HTTP://", "")
            .replace("CODE.LANANHPT.COM/", "")
            .replaceAll("@", "");///barcode witch space
        setLoading(true);
        Lib.Get(`/API/Products/Scan?code=${_CODE}`, "Load m.Product", async (rs) => {
            const p = await rs.json() as m.Product;
            resolve(p);
            setLoading(false);
        });
    });
    return loading?<Placeholder.Paragraph/>:<NotFound />
}