import React from 'react';
import { useState } from 'react';
import { Col, Container, Content, Grid, Input, Progress, Row, Timeline } from 'rsuite';
import { c } from '../../c/ModelHelper';
import { jm } from '../../m/JModel';
export function Index(props: any) {
    const [percent, setPercent] = useState(0);
    const [Log, setLog] = useState<jm.TimeLog[]>([]);
    const [date, setDate] = useState<Date>();
    const [status, setStatus] = useState<"active" | "success" | "fail">("active");
    const [money, setMoney] = useState<number>(0)
    const OnLog = (log?: string) => {
        const now = new Date();
        // const l=[...Log, {time:`${now.getSeconds()}s ${now.getMilliseconds()}`,log}];
        // setLog(l);
        setLog(ls => [{ time: `${now.toISOString()}`, log }, ...ls]);
    }
    const [txt, setTxt] = useState("");

    React.useEffect(() => {
        setTxt(c.Num.moneyTxt(money)+ " " + c.Num.money(money));
    }, [money]);
    return <Container><Content>
        <Grid fluid>
            <Row>
                <Col xs={24}>
                    <Progress percent={percent} status={status} />
                </Col>
            </Row>
            <Row>
                <Input value={money} onChange={(v) => setMoney(Number(v))} />
            </Row>
            <Row>
                {txt}
                <Timeline align="left">
                    {Log.map(x =>
                        <Timeline.Item time={x.time}><span dangerouslySetInnerHTML={{ __html: x.log ?? "" }}></span></Timeline.Item>
                    )}
                </Timeline>
            </Row>
        </Grid>
    </Content></Container>
}