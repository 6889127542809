import React, { useEffect } from "react";
import { InputGroup, InputPicker } from "rsuite";
import { FaPlug, FaRegObjectGroup } from "react-icons/fa";
import { Icon } from "@rsuite/icons";
import { Lib } from "../../c/Lib";
import { getCache, setCache } from "../../c/UrlHelper";
import { m } from "../../m/Model";
interface IProps {
	disabled?: boolean;
	PName?: string;
	PId?: number;
	children?: React.ReactNode;
	onChange?: (PName?: string, PId?: number) => void;
}
var PNAMES: m.PName[] = [];
export const loadPNames = (forceLoad?: boolean) => {
	forceLoad ||= !!!getCache<m.PName[]>("PNames");
	if (forceLoad) {
		Lib.Get(`/API/PNames/GetAll`, "load m.PName", async (rs) => {
			const rows = await rs.json() as m.PName[];
			setCache("PNames", rows)
			PNAMES = rows;
		});
	} else {
		PNAMES = getCache<m.PName[]>("PNames") ?? [];
	}
}
export function _PName(props: IProps) {
	//#region const
	const [CurRow, setCurRow] = React.useState<m.PName>();
	//#endregion
	//#region function
	const searchBy = (keyword: string, label: React.ReactNode, _item: any) => {
		const item = _item as m.PName;
		return item.Code?.toUpperCase().includes(keyword?.toUpperCase());
	}
	const renderMenuItem = (label: any, _item: any) => {
		var item = _item as m.PName;
		return <span>
			{label}
			{((item.State ?? 0) & m.PROD_STATE.SI) > 0 ? <Icon as={FaRegObjectGroup} /> :
				(item.PId ?? 0) > 0 && <Icon as={FaPlug} />}
		</span>
	}
	//#endregion
	//#region effect
	useEffect(() => {
		// if (!!props.disabled) return;
		loadPNames();
		if (!!!props.PName){
			loadPNames(true);
			return;
		} 
		setCurRow({ Code: props.PName, PId: props.PId } as m.PName);
	}, [props.PName, props.PId, props.disabled]);
	useEffect(() => {
		loadPNames();
		props.onChange?.(CurRow?.Code, CurRow?.PId);
		if (!!!CurRow) return;
		if (!!!PNAMES.find(x => x.Code == CurRow.Code))
			PNAMES = [CurRow, ...PNAMES];
	}, [CurRow]);
	//#endregion effect
	//#region event
	const handleClean = () => {
		loadPNames(true);
		setCurRow({} as m.PName);
	}
	const handleCreate = (keyword: string) => {
		setCurRow({ Code: keyword } as m.PName);
	};
	const handleSelect = (keyword: string, _item: any) => {
		//const item = _item as m.PName;
		setCurRow(_item as m.PName);
	}
	//#endregion
	//#region btn
	const btn=()=>{
		window.open(`/Products/Units/${CurRow?.PId}`,"_blank");
	}
	//#endregion
	return <InputGroup><InputGroup.Button onClick={btn} disabled={!!!CurRow?.PId}><Icon as={FaPlug} /></InputGroup.Button>{props.children}<InputPicker
		disabled={props.disabled}
		style={{ width: "100%", borderRadius: "0 6px 6px 0" }}
		creatable
		//defaultValue={row?.Code}
		value={CurRow?.Code}
		valueKey="Code"
		labelKey="Code"
		data={PNAMES}
		onClean={handleClean}
		onCreate={handleCreate}
		onSelect={handleSelect}
		searchBy={searchBy}
		renderMenuItem={renderMenuItem}
		virtualized
	/></InputGroup>;
}