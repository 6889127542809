
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Container, Content, Divider, Nav, Sidebar, Sidenav } from "rsuite"
import { Icon } from "@rsuite/icons";
import { FaBook, FaDollarSign, FaGlobe, FaMoneyBill, FaObjectGroup, FaPrint, FaSearchengin, FaSync, FaTachometerAlt, FaTags, FaToolbox } from 'react-icons/fa';
import { useQueryParam } from "../../c/UrlHelper";
import { _DbProgress } from "../Home/_DbProgress";
import { Db } from "../../c/Db";
import { Lib } from "../../c/Lib";
import { jm } from "../../m/JModel";
import { m } from "../../m/Model";
const RPP = 17;
interface IProps {
   title?: string;
   children?: any;
}
export function Layout(props: IProps) {
   //#region Const
   const [PageNo, setPageNo] = useQueryParam<number>("P");
   const [Q, setQ] = useQueryParam<string>("Q");
   const [MaxPage, setMaxPage] = useQueryParam<number | undefined>("MP");
   const location = useLocation();
   const nav = useNavigate();
   var activeKey = location.pathname.replace(/(\/\w*)(\/\w*)?.*/, "$1");
   const [searchKey, setSearchKey] = React.useState<string>(activeKey);
   const [searchEx, setSearchEx] = React.useState(false);
   //#endregion
   //#region Event
   const [loading, setLoading] = React.useState(true);
   const [loadingStr, setLoadingStr] = React.useState("");
   const [progress, setProgess] = React.useState(0);
   React.useEffect(() => {
      // (async () => {
      setProgess(0);
      //setLoadingStr(`update Version`);
      // const nCl = await Lib.Get(`/api/Tools/Changelogs`).then(rs => rs?.json() as unknown as m.Changelog[]);
      Db.instance(setLoadingStr, setProgess);
      // setProgess(10);
      // const changelog = await Db.All<m.Changelog>("Changelogs");
      // const af = [];
      // var bout = 0;
      // var max = '2000-01-01';
      // nCl.forEach(x => {
      //    if (x.Date > (changelog.find(y => y.Id == x.Id)?.Date ?? '2000-01-01')) {
      //       if (max < x.Date)
      //          max = x.Date;
      //       if (x.Id == m.TableNames.Tag) bout |= m.TableNames.Tag | m.TableNames.TagProduct;
      //       if (x.Id == m.TableNames.TagProduct) bout |= m.TableNames.TagProduct;
      //       if (x.Id == m.TableNames.Product) bout |= m.TableNames.Product | m.TableNames.TagProduct;
      //    }
      // });
      // if ((bout & m.TableNames.Tag) > 0) {
      //    setLoadingStr(`update Tag`);
      //    await Lib.Get("/API/Tags/All", undefined, async (rs) => {
      //       const tags = await rs.json() as m.Tag[];
      //       const ts = Db.db.transaction(["Changelogs", "Tags", "TagProducts"], "readwrite");
      //       const tb = ts.objectStore("Tags");
      //       tb.clear();
      //       tags.forEach(x => tb.put(x));
      //       const cl = ts.objectStore("Changelogs");
      //       cl.put({ Id: m.TableNames.Tag, Date: max } as m.Changelog);
      //       ts.commit();
      //    });
      // }
      // setProgess(50);
      // if ((bout & m.TableNames.Product) > 0) {
      //    setLoadingStr(`update Product`);
      //    // await Lib.Get("/API/Tags/TagProducts", undefined, async (rs) => {
      //    // const TPs = await rs.json() as jm.TagProduct[];
      //    const ts = Db.db.transaction(["Changelogs", "Tags", "TagProducts"], "readwrite");
      //    // const tb = ts.objectStore("TagProducts");
      //    // tb.clear();
      //    // TPs.forEach(x => tb.put(x));
      //    const cl = ts.objectStore("Changelogs");
      //    cl.put({ Id: m.TableNames.Product, Date: max } as m.Changelog);
      //    ts.commit();
      //    // });
      // }
      // if ((bout & m.TableNames.TagProduct) > 0) {
      //    setLoadingStr(`update ProductTag`);
      //    await Lib.Get("/API/Tags/TagProducts", undefined, async (rs) => {
      //       const TPs = await rs.json() as jm.TagProduct[];
      //       const ts = Db.db.transaction(["Changelogs", "Tags", "TagProducts"], "readwrite");
      //       const tb = ts.objectStore("TagProducts");
      //       tb.clear();
      //       TPs.forEach(x => tb.put(x));
      //       const cl = ts.objectStore("Changelogs");
      //       cl.put({ Id: m.TableNames.TagProduct, Date: max } as m.Changelog);
      //       ts.commit();
      //    });
      // }
      // setProgess(80);
      setProgess(100);
      setLoading(false);
      // })();
   }, []);
   React.useEffect(() => {
      console.info("Db Update", loadingStr);
   }, [loadingStr]);
   const handleNav = (eventKey?: string) => {
      nav(eventKey?.replaceAll('*', '') ?? "");
   }
   const OnSelect = (keyword?: string) => {
      if (!!keyword) {
         setMaxPage(undefined);
         setPageNo(1);
         setQ(keyword);
      }
   }
   //#endregion
   return (<Container>
      <Sidebar className="PrintNone"
         style={{ display: 'flex', flexDirection: 'column' }}
         width={56}
         collapsible>
         <Sidenav expanded={false} defaultOpenKeys={["/"]} appearance="default">
            <Sidenav.Header>
               <Nav>
                  <Nav.Item href="https://mng.lananhpt.com" icon={<Icon color="blue" as={FaSync} />}>Menu</Nav.Item>
                  {/* <Nav.Item onSelect={() => { setSearchEx(true) }} icon={<Icon as={FaSearch} />}>Tìm</Nav.Item> */}
               </Nav>
            </Sidenav.Header>
            <Sidenav.Body>
               <Nav activeKey={activeKey}>
                  <Nav.Item eventKey="/Orders" icon={<Icon as={FaBook} />} onSelect={() => { handleNav("/Orders/SuaChua"); }}>Hóa đơn</Nav.Item>
                  <Nav.Item eventKey="/Products" icon={<Icon as={FaDollarSign} />} onSelect={() => { handleNav("/Products/May"); }}>Bản giá</Nav.Item>
                  <Divider />
                  <Nav.Item eventKey="/Print" icon={<Icon as={FaPrint} />} onSelect={handleNav}>Print</Nav.Item>
                  <Nav.Item eventKey="/EcProducts" icon={<Icon as={FaGlobe} />} onSelect={handleNav}>Nhóm sản phẩm</Nav.Item>
                  <Nav.Item eventKey="/Customers/Balance" icon={<Icon as={FaMoneyBill} />} onSelect={handleNav}>Công nợ</Nav.Item>
                  <Nav.Menu icon={<Icon as={FaTachometerAlt} />} placement='topStart' title='Dev'>
                     <Nav.Item eventKey="/PNames" icon={<Icon as={FaSearchengin} />} onSelect={handleNav}>PNames</Nav.Item>
                     <Nav.Item eventKey="/Dev" icon={<Icon as={FaTachometerAlt} />} onSelect={handleNav}>Dev</Nav.Item>
                     <Nav.Item eventKey="/Dev/Parameters" icon={<Icon as={FaTachometerAlt} />} onSelect={handleNav}>Parameters</Nav.Item>
                     <Nav.Item eventKey="/Dev/SIBrowser" icon={<Icon as={FaObjectGroup} />} onSelect={handleNav}>SIBrowser</Nav.Item>
                     <Nav.Item eventKey="/Dev/Tools" icon={<Icon as={FaToolbox} />} onSelect={handleNav}>Tools</Nav.Item>
                     <Nav.Item eventKey="/Dev/Tags" icon={<Icon as={FaTags} />} onSelect={handleNav}>Tags</Nav.Item>
                  </Nav.Menu>
                  <Divider />
                  <Nav.Item eventKey="/NewOrders" icon={<Icon as={FaBook} />} onSelect={() => { handleNav("/NewOrders/SuaChua"); }}>Hóa đơn</Nav.Item>
               </Nav>
            </Sidenav.Body>
         </Sidenav>
      </Sidebar>
      <Content>
         {loading ? <_DbProgress header="Update Db" text={loadingStr} progress={progress} /> : <Outlet />}
      </Content>
   </Container>);
}