import { m } from '../m/Model';
export module c {
    export class Tag {
        static check(Dat: m.Tag, Prtype?: m.CATALOG): boolean {
            if (!!!Prtype) return Dat.IsMDisplay || Dat.IsSpDisplay || Dat.IsAsDisplay;
            // if (!!!Prtype) return true;
            if (Prtype == m.CATALOG.Machine)
                return Dat.IsMDisplay;
            if (Prtype == m.CATALOG.SparePart)
                return Dat.IsSpDisplay;
            return Dat.IsAsDisplay;
        }
    }
    export class Num {
        static money(num: number): string {
            return num.toFixed(0).toString().replace(/\d(?=(\d{3})+(\..*)?$)/g, '$&,');
        }
        static moneyTxt(m: number): string {
            var Out = "";
            var i = 1;
            const txtNum = ["không", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
            while (m >= i) {
                var mi = Math.floor((m / i) % 10);
                const l = Math.log10(i);
                if (l % 3 == 0 && i != 1)
                    switch (l % 9) {
                        case 3: Out = "ngàn " + Out; break;
                        case 6: Out = "triệu " + Out; break;
                        case 0: Out = "tỷ " + Out; break;
                    }
                if (l % 3 == 2)
                    Out = "trăm " + Out;
                if (l % 3 == 1)
                    Out = "mươi " + Out;
                Out = txtNum[mi] + " " + Out;
                i *= 10;
            }
            Out = Out + "đồng";
            const txtR = [["không mươi", "lẻ"]
                , ["mươi năm", "mươi lăm"], ["mươi không", "mươi"]
                , ["một mươi", "mười"]
                , ["mươi một", "mươi mốt"]
                , ["không trăm lẻ không ", ""]
                //, ["không trăm", "lẻ"]
                , ["lẻ không ", ""]
                //, ["ngàn không", "ngàn"]
                //, ["lẻ lẻ", "lẻ"]
                //,["ngàn đồng","ngàn đồng chẵn"]
                , ["tỷ triệu", "tỷ"]
                , ["triệu ngàn đồng", "triệu đồng chẵn"]
                , ["tỷ ngàn đồng", "tỷ đồng chẵn"]
            ];
            for (var i = 0; i < txtR.length; i++) {
                console.info(Out);
                Out = Out.replaceAll(txtR[i][0], txtR[i][1]);
            }
            return Out;
        }
        static Camelize(str: string): string {
            return str.trim().split(" ").map(x => x.substring(0, 1).toUpperCase() + x.substring(1)).join(" ");
        }
    }
}