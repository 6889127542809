import React from "react";
import { Col, Row } from "rsuite";

interface IProps {
    stores?: string;
    ref?: any;
    onSelect?: (str: string) => void;
}
export function StoreMap(props: IProps) {
    const regEx = /^\s*(((\d+)[^\d]+)\d*)\s*$/;
    const [Css, setCss] = React.useState("");
    React.useEffect(() => {
        const stores_str = props.stores?.split(",");
        var css = 'svg>g>g>rect{fill:none;stroke:gray}svg>g>g>g>rect{fill:none;stroke:#000}svg>g>g>g:hover rect{fill:#00F7}\
        svg>g>g>rect{fill:none;stroke:gray}svg>g>g>g>rect{fill:none;stroke:#000}svg>g>g>g:hover rect{fill:#00F7}.r{fill:#fff;stop-color:#0007;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:.264583}.rb,.rt{fill:#0004}.t{font-family:Sans;font-size:4.94px;font-variant-ligatures:none;line-height:1.25;stroke-width:.264583}.rt{stroke:none}.rb{stroke:#000;stroke-dasharray:none;stroke-miterlimit:4;stroke-opacity:1;stroke-width:.26458333}\
        ';
        var i = 0;
        stores_str?.forEach(str => {
            const m = str.match(regEx) ?? [];
            if (m.length < 2) return;
            var str = m[2];
            if (str == "1T") str = m[1];
            css += "#F" + str + ">rect{fill:#" + (i == 0 ? "f007}" : "ff07}");
            i++;
        });
        css += props.stores;
        setCss(css);
    });
    const onSelectG = (evt: React.MouseEvent<SVGGElement, MouseEvent>) => {
        var target: SVGGElement = evt.currentTarget as SVGGElement;
        // console.debug(target.id);
        props.onSelect?.(target.id.substring(1));
    }
    return <Row>
        <style>{Css}
        </style>
        <Col>
            <svg viewBox="0 0 210 76" height={140}>
                <g xmlns="http://www.w3.org/2000/svg" id="layer1">
                    <g xmlns="http://www.w3.org/2000/svg" id="F1">
                        <rect id="rect25200" width="203.81499" height="66.470749" x="2.4954886" y="3.3216362" className="r" />
                        <rect id="rect18064" height="44.785061" x="184.31297" y="25.007332" width="2.201" />
                        <g id="F1KA" onClick={onSelectG} transform="translate(48.042725,62.027216)">
                            <rect id="rect36262-3-8-7-8-6-3-2-6" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="17.643946" y="6.66257" id="text37676-7-06-1-9-3-7-74-7" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-6" x="17.643946" y="6.66257">KA</tspan></text>
                        </g>
                        <g id="F1KB" onClick={onSelectG} transform="translate(19.588671,62.027216)">
                            <rect id="rect36262-3-8-7-8-6-3-2-84" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="17.705441" y="6.66257" id="text37676-7-06-1-9-3-7-74-9" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-9" x="17.705441" y="6.66257">KB</tspan></text>
                        </g>
                        <g id="F1KC" onClick={onSelectG} transform="translate(-8.8653855,62.027216)">
                            <rect id="rect36262-3-8-7-8-6-3-2-87" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="17.672884" y="6.66257" id="text37676-7-06-1-9-3-7-74-48" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-01" x="17.672884" y="6.66257">KC</tspan></text>
                        </g>
                        <g id="F1KD" onClick={onSelectG} transform="translate(-8.8653855,1.3525002)">
                            <rect id="rect36262-3-8-7-8-6-3-2-87-9" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="17.54266" y="6.66257" id="text37676-7-06-1-9-3-7-74-48-9" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-01-2" x="17.54266" y="6.66257">KD</tspan></text>
                        </g>
                        <g id="F1KE" onClick={onSelectG} transform="translate(19.588671,1.3525002)">
                            <rect id="rect36262-3-8-7-8-6-3-2-87-8" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="17.876661" y="6.66257" id="text37676-7-06-1-9-3-7-74-48-96" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-01-7" x="17.876661" y="6.66257">KE</tspan></text>
                        </g>
                        <g id="F1KH" onClick={onSelectG} transform="translate(48.042725,1.3525002)">
                            <rect id="rect36262-3-8-7-8-6-3-2-87-4" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="17.686148" y="6.66257" id="text37676-7-06-1-9-3-7-74-48-2" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-01-0" x="17.686148" y="6.66257">KH</tspan></text>
                        </g>
                        <g id="F1KI" onClick={onSelectG} transform="translate(110.47245,1.3525002)">
                            <rect id="rect36262-3-8-7-8-6-3-2-4" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="18.4265" y="6.66257" id="text37676-7-06-1-9-3-7-74-5" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-2" x="18.4265" y="6.66257">KI</tspan></text>
                        </g>
                        <g id="F1CA" onClick={onSelectG} transform="translate(133.36002,62.027216)">
                            <rect id="rect36262-3-8-7-8-6" width="19.796036" height="5.7960372" x="11.360874" y="1.969136" className="rb" />
                            <text x="17.739202" y="6.66257" id="text37676-7-06-1-9-3" className="t" ><tspan id="tspan37674-9-9-6-0-2" x="17.739202" y="6.66257">CA</tspan></text>
                        </g>
                        <g id="F1A" onClick={onSelectG} transform="translate(133.36002,67.823253)">
                            <rect id="rect36262-3-8-7-8-6-3" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="19.602142" y="6.66257" id="text37676-7-06-1-9-3-7" className="t" ><tspan id="tspan37674-9-9-6-0-2-7" x="19.602142" y="6.66257">A</tspan></text>
                        </g>
                        <g id="F1B" onClick={onSelectG} transform="translate(103.01693,67.823253)">
                            <rect id="rect36262-3-8-7-8-6-3-9" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="19.602142" y="6.66257" id="text37676-7-06-1-9-3-7-7" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-7" x="19.602142" y="6.66257">B</tspan></text>
                        </g>
                        <g id="F1C" onClick={onSelectG} transform="translate(72.673866,67.823253)">
                            <rect id="rect36262-3-8-7-8-6-3-2" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="19.602142" y="6.66257" id="text37676-7-06-1-9-3-7-74" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5" x="19.602142" y="6.66257">C</tspan></text>
                        </g>
                        <g id="F1CB" onClick={onSelectG} transform="translate(103.01693,62.027216)">
                            <rect id="rect36262-3-8-7-8-6-3-2-8" width="19.796036" height="5.7960372" x="11.360874" y="1.969136" className="rb" />
                            <text x="17.800697" y="6.66257" id="text37676-7-06-1-9-3-7-74-4" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-0" x="17.800697" y="6.66257">CB</tspan></text>
                        </g>
                        <g id="F1CC" onClick={onSelectG} transform="translate(72.673866,62.027216)">
                            <rect id="rect36262-3-8-7-8-6-3-2-7" width="19.796036" height="5.7960372" x="11.360874" y="1.969136" className="rb" />
                            <text x="17.768141" y="6.66257" id="text37676-7-06-1-9-3-7-74-1" className="t" ><tspan id="tspan37674-9-9-6-0-2-7-5-8" x="17.768141" y="6.66257">CC</tspan></text>
                        </g>
                        <g id="F1D" onClick={onSelectG} transform="translate(145.89311,54.502925)">
                            <rect id="rect36262-3-8-7-7-8" width="6" height="20" className="r" x="18.623823" y="-10.506573" />
                            <text x="19.746414" y="1.2864313" id="text37676-7-06-1-2-6" className="t" ><tspan id="tspan37674-9-9-6-8-0" x="19.746414" y="1.2864313">D</tspan></text>
                        </g>
                        <g id="F1E" onClick={onSelectG} transform="translate(153.15606,62.027216)">
                            <rect id="rect36262-3-8-7-8" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="19.602142" y="6.66257" id="text37676-7-06-1-9" className="t" ><tspan id="tspan37674-9-9-6-0" x="19.602142" y="6.66257">E</tspan></text>
                        </g>
                        <g id="F1I" onClick={onSelectG} transform="translate(175.15356,62.027216)">
                            <rect id="rect36262-3-8-7" width="19.796036" height="5.7960372" className="r" x="11.360874" y="1.969136" />
                            <text x="19.602142" y="6.66257" id="text37676-7-06-1" className="t" ><tspan id="tspan37674-9-9-6" x="19.602142" y="6.66257">I</tspan></text>
                        </g>
                        <g id="F1G" onClick={onSelectG} transform="translate(159.68915,35.513905)">
                            <rect id="rect36262-3-8-7-7" width="6" height="20" className="r" x="18.623823" y="-10.506573" />
                            <text x="19.746414" y="1.2864313" id="text37676-7-06-1-2" className="t" ><tspan id="tspan37674-9-9-6-8" x="19.746414" y="1.2864313">G</tspan></text>
                        </g>
                        <g id="F1M" onClick={onSelectG} transform="translate(139.89311,54.502925)">
                            <rect id="rect36262-3-8-7-7-8-4" width="6" height="20" className="r" x="18.623823" y="-10.506573" />
                            <text x="19.746414" y="1.2864313" id="text37676-7-06-1-2-6-3" className="t" ><tspan id="tspan37674-9-9-6-8-0-5" x="19.746414" y="1.2864313">M</tspan></text>
                        </g>
                        <g id="F1T1" onClick={onSelectG} transform="translate(125.14997,24.757349)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9" width="4" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9" x="18.746414" y="1.2864313">1</tspan></text>
                        </g>
                        <rect id="rect2084" width="5.7362933" height="19.736294" x="138.03749" y="14.250776" />
                        <g id="F1T8" onClick={onSelectG} transform="translate(125.14997,47.580257)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-6" width="4" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-30" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-28" x="18.746414" y="1.2864313">8</tspan></text>
                        </g>
                        <rect id="rect2084-9" width="5.7362933" height="19.736294" x="138.03749" y="37.073685" />
                        <g id="F1T2" onClick={onSelectG} transform="translate(107.28129,24.757349)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-9" width="12" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="23.020128" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-4" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-5" x="23.020128" y="1.2864313">2</tspan></text>
                        </g>
                        <g id="F1T12" onClick={onSelectG} transform="translate(71.279159,47.580257)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-9-8" width="12" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="21.309116" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-4-0" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-5-7" x="21.309116" y="1.2864313">12</tspan></text>
                        </g>
                        <g id="F1T9" onClick={onSelectG} transform="translate(107.28129,47.580257)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-9-8-5" width="12" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="23.020128" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-4-0-6" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-5-7-3" x="23.020128" y="1.2864313">9</tspan></text>
                        </g>
                        <g id="F1T3" onClick={onSelectG} transform="translate(97.412607,24.757349)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-0-37" width="4" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-3-2" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-2-3" x="18.746414" y="1.2864313">3</tspan></text>
                        </g>
                        <rect id="rect2084-0" width="5.7362933" height="19.736294" x="120.03643" y="14.250776" />
                        <g id="F1T10" onClick={onSelectG} transform="translate(98.7446,47.580257)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-0-3-7" width="6" height="20" className="rt" x="15.29183" y="-10.506573" />
                            <text x="14.979535" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-3-6-2" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-2-8-7" x="14.979535" y="1.2864313">10</tspan></text>
                        </g>
                        <rect id="rect2084-9-3" width="5.7362933" height="19.736294" x="120.03643" y="37.073685" />
                        <g id="F1T4" onClick={onSelectG} transform="translate(89.147829,24.757349)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-2" width="4" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-1" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-8" x="18.746414" y="1.2864313">4</tspan></text>
                        </g>
                        <g id="F1T5" onClick={onSelectG} transform="translate(79.411539,24.757349)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-0-2" width="4" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-3-0" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-2-6" x="18.746414" y="1.2864313">5</tspan></text>
                        </g>
                        <rect id="rect2084-91" width="5.7362933" height="19.736294" x="102.03536" y="14.250776" />
                        <g id="F1T11" onClick={onSelectG} transform="translate(89.147829,47.580257)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-6-8" width="5" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-30-6" className="t" ><tspan x="18.746414" y="1.2864313" id="tspan29228">11</tspan></text>
                        </g>
                        <rect id="rect2084-9-1" width="5.7362933" height="19.736294" x="102.03536" y="37.073685" />
                        <g id="F1T6" onClick={onSelectG} transform="translate(71.146769,24.757349)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-68" width="4" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-8" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-9" x="18.746414" y="1.2864313">6</tspan></text>
                        </g>
                        <g id="F1T7" onClick={onSelectG} transform="translate(61.410479,24.757349)">
                            <rect id="rect36262-3-8-7-7-8-4-8-9-0-35" width="4" height="20" className="rt" x="18.623823" y="-10.506573" />
                            <text x="18.746414" y="1.2864313" id="text37676-7-06-1-2-6-3-7-1-3-1" className="t" ><tspan id="tspan37674-9-9-6-8-0-5-8-9-2-87" x="18.746414" y="1.2864313">7</tspan></text>
                        </g>
                        <rect id="rect2084-7" width="5.7362933" height="19.736294" x="84.034302" y="14.250776" />
                        <rect id="rect2084-9-6" width="5.7362933" height="19.736294" x="84.034302" y="37.073685" />
                    </g>
                </g>
            </svg>
        </Col>
        <Col>
            <svg viewBox="0 0 210 70" height={140}>
                <g xmlns="http://www.w3.org/2000/svg" id="layer1">
                    <g xmlns="http://www.w3.org/2000/svg" id="F2">
                        <rect id="rect25200" width="203.81499" height="66.470749" x="2.4954886" y="3.3216362" className="r" />
                        <rect id="rect18064" width="2.2014551" height="44.785061" x="168.41939" y="25.065697" />
                        <g id="F2H" onClick={onSelectG} transform="translate(170.62085,25.065697)">
                            <rect id="rect25340" width="10.661184" height="22.344673" className="r" x="0" y="0" />
                            <text id="text27016" className="t" y="-20.738192" x="-157.43501"><tspan id="tspan27014" x="3.6424916" y="12.967752" className="t">H</tspan></text>
                        </g>
                        <g id="F2B" onClick={onSelectG} transform="translate(104.4942,3.5050464)">
                            <rect id="rect36262-3" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7" className="t" ><tspan x="17.076235" y="5.6655712" id="tspan31635">B</tspan></text>
                        </g>
                        <g id="F2A" onClick={onSelectG} transform="matrix(0.99980644,0,0,0.99994048,65.105703,3.3218675)">
                            <rect id="rect36262-3-8-9" width="29.770699" height="7.7653685" className="r" x="0.00022053732" y="-0.024827305" />
                            <text x="13.197469" y="5.6532726" id="text37676-7-06-5" className="t" ><tspan id="tspan37674-9-9-0" x="13.197469" y="5.6532726">A</tspan></text>
                        </g>
                        <g id="F2I" onClick={onSelectG} transform="translate(175.15356,3.3464974)">
                            <rect id="rect36262-3-8-7" width="24.785139" height="7.7900338" className="r" x="6.3717661" y="-0.024861246" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-06-1" className="t" ><tspan id="tspan37674-9-9-6" x="17.076235" y="5.6655712">I</tspan></text>
                        </g>
                        <g id="F2G" onClick={onSelectG} transform="translate(26.360875,61.849471)">
                            <rect id="rect36262-3-3-9" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-0-7" className="t" ><tspan id="tspan37674-9-13-0" x="17.076235" y="5.6655712">G</tspan></text>
                        </g>
                    </g>
                </g>
            </svg>
        </Col>
        <Col>
            <svg viewBox="0 0 210 70" height={140}>
                <g xmlns="http://www.w3.org/2000/svg" id="layer1">
                    <g xmlns="http://www.w3.org/2000/svg" id="F4">
                        <rect id="rect25200" width="203.81499" height="66.470749" x="2.4954886" y="3.3216362" className="r" />
                        <rect id="rect18064" width="2.2014551" height="44.785061" x="168.41939" y="25.065697" />
                        <g id="F4A" onClick={onSelectG} transform="translate(157.80012,25.107605)">
                            <rect id="rect25340" width="10.661184" height="22.344673" className="r" x="0" y="0" />
                            <text id="text27016" className="t" y="-20.738192" x="-157.43501"><tspan id="tspan27014" x="3.6424916" y="12.967752" className="t">A</tspan></text>
                        </g>
                        <g id="F4B" onClick={onSelectG} transform="translate(101.71938,33.955141)">
                            <rect id="rect36262" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676" className="t" ><tspan id="tspan37674" x="17.076235" y="5.6655712">B</tspan></text>
                        </g>
                        <g id="F4C" onClick={onSelectG} transform="translate(104.4942,3.5050464)">
                            <rect id="rect36262-3" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7" className="t" ><tspan id="tspan37674-9" x="17.076235" y="5.6655712">C</tspan></text>
                        </g>
                        <g id="F4D" onClick={onSelectG} transform="translate(80.39701,26.799003)">
                            <rect id="rect25340-7" width="9.9526606" height="15.480623" className="r" x="4.9462892e-07" y="6.8640499" />
                            <text id="text27016-9" className="t" y="-17.306168" x="-158.10037"><tspan id="tspan27014-4" x="2.9771373" y="16.399776" className="t">D</tspan></text>
                        </g>
                        <g id="F4E" onClick={onSelectG} transform="translate(42.571716,33.663053)">
                            <rect id="rect36262-3-6" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-6" className="t" ><tspan id="tspan37674-9-6" x="17.076235" y="5.6655712">E</tspan></text>
                        </g>
                        <g id="F4G" onClick={onSelectG} transform="translate(35.99975,19.204734)">
                            <rect id="rect36262-3-0" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-7" className="t" ><tspan id="tspan37674-9-1" x="17.076235" y="5.6655712">G</tspan></text>
                        </g>
                        <g id="F4H" onClick={onSelectG} transform="translate(42.571716,41.403365)">
                            <rect id="rect36262-3-3" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-0" className="t" ><tspan id="tspan37674-9-13" x="17.076235" y="5.6655712">H</tspan></text>
                        </g>
                        <g id="F4K" onClick={onSelectG} transform="translate(73.825048,62.287602)">
                            <rect id="rect36262-3-3-5" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-0-4" className="t" ><tspan id="tspan37674-9-13-8" x="17.076235" y="5.6655712">K</tspan></text>
                        </g>
                        <g id="F4L" onClick={onSelectG} transform="translate(101.71938,41.695453)">
                            <rect id="rect36262-7" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-6" className="t" ><tspan id="tspan37674-8" x="17.076235" y="5.6655712">L</tspan></text>
                        </g>
                        <g id="F4M" onClick={onSelectG} transform="translate(111.65034,62.287602)">
                            <rect id="rect36262-3-3-5-6" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-0-4-5" className="t" ><tspan id="tspan37674-9-13-8-8" x="17.076235" y="5.6655712">M</tspan></text>
                        </g>
                        <g id="F4O" onClick={onSelectG} transform="matrix(0.99980644,0,0,0.99994048,65.105703,3.3218675)">
                            <rect id="rect36262-3-8-9" width="29.770699" height="7.7653685" className="r" x="0.00022053732" y="-0.024827305" />
                            <text x="13.197469" y="5.6532726" id="text37676-7-06-5" className="t" ><tspan id="tspan37674-9-9-0" x="13.197469" y="5.6532726">O</tspan></text>
                        </g>
                        <g id="F4P" onClick={onSelectG} transform="translate(33.949017,3.2967753)">
                            <rect id="rect36262-3-8-7" width="24.785139" height="7.7900338" className="r" x="6.3717661" y="-0.024861246" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-06-1" className="t" ><tspan id="tspan37674-9-9-6" x="17.076235" y="5.6655712">P</tspan></text>
                        </g>
                        <g id="F4Q" onClick={onSelectG} transform="translate(35.999754,62.287602)">
                            <rect id="rect36262-3-3-9" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-0-7" className="t" ><tspan id="tspan37674-9-13-0" x="17.076235" y="5.6655712">Q</tspan></text>
                        </g>
                        <g id="F4V" onClick={onSelectG} transform="translate(2.4954886,3.3216362)">
                            <rect id="rect36262-3-8" width="37.825294" height="7.7403116" className="r" x="0" y="0" />
                            <text x="17.076235" y="5.6655712" id="text37676-7-06" className="t" ><tspan id="tspan37674-9-9" x="17.076235" y="5.6655712">V</tspan></text>
                        </g>
                    </g>
                </g>
            </svg>
        </Col>
    </Row>
}