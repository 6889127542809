import { useState } from "react";
import { Content, DatePicker, IconButton } from "rsuite"
import { m } from "../../m/Model";
import { FaPrint } from "react-icons/fa";
import { Icon } from "@rsuite/icons";
import { IdRow } from "../../c/TableHelper";
interface IData extends m.Product, IdRow {
    Qty: number;
}
export function Default(props: any) {
    const [date, setDate] = useState<Date>(new Date());
    const changeDate = (v: Date | null) => {
        if (!!v)
            setDate(v);
    }
    const btnPrint = () => {
        window.open(`/Print/Summary?Date=${date.toISOString()}`, "_bank");
    }
    return <Content>
        <DatePicker oneTap value={date} onChange={changeDate} />
        <IconButton icon={<Icon as={FaPrint}/>} onClick={btnPrint}>Thu Chi</IconButton>
    </Content>
}