import { Icon } from "@rsuite/icons";
import React, { useState } from "react";
import { FaSave, FaTimes, FaUser } from "react-icons/fa";
import { ButtonToolbar, Form, IconButton, InputGroup, InputPicker, Modal } from "rsuite";
import FormControl from "rsuite/esm/FormControl";
import FormControlLabel from "rsuite/esm/FormControlLabel";
import FormGroup from "rsuite/esm/FormGroup";
import { Lib } from "../../c/Lib";
import { m } from "../../m/Model";
interface IProps {
    customer?: m.Customer;
    disabled?: boolean;
    onChange?: (customer?: m.Customer) => void;
}
export function _Customer(props: IProps) {
    //#region const
    const [Rows, setRows] = React.useState<m.Customer[]>([]);
    const [CurRow, setCurRow] = React.useState<m.Customer>();
    const [creatable, setCreatable] = React.useState<boolean>(true);
    //#endregion
    //#region function
    const searchBy = (keyword: string, label?: React.ReactNode, _item?: any) => {
        const item = _item as m.Customer;
        return `${item.ShortName} 0${item.Phone}`.toUpperCase().includes(keyword.toUpperCase());
    }
    //#endregion
    //#region effect
    React.useEffect(() => {
        if (!!!CurRow) return;
        if (!!!Rows.find(x => x.Phone == CurRow.Phone))
            setRows([...Rows, CurRow]);
    }, [CurRow])
    React.useEffect(() => {
        setCurRow(props.customer);
    }, [props.customer]);
    //#endregion effect
    //#region event
    const handleCreate = (keyword: string) => {
        const reg = keyword.match(/((.*\s)?(\w*))\s(\d*(\d{3}))$/);
        if (!!!reg || reg.length < 6) return;
        const cus = {
            ...m.DEFAULT.Customer,
            Phone: Number(reg?.[4]),
            ShortName: `${reg?.[3]} ${reg?.[5]}`,
            Name: `${reg?.[1]}`
        } as m.Customer;
        Lib.Post(`/API/Customers/Add`, JSON.stringify(cus), `add customer`, async (rs) => {
            const c = await rs.json() as m.Customer;
            const data = [...Rows, c];
            const keys = data.map(x => x.Phone);
            setRows(data.filter((x, i) => keys.indexOf(x.Phone) == i));
        });
    };
    const handleSelect = (keyword: string, _item: any) => {
        const item = _item as m.Customer;
        setCurRow(item);
        props.onChange?.(item);
    }
    const handleSearch = (keyword: string) => {
        //`/ backend / api / Customers / Search ? q = ${ value } `
        const c = Rows.filter(x => { return searchBy(keyword, undefined, x) }).length > 0;
        setCreatable(Rows.filter(x => x.Phone == Number(keyword.replace(/((.*\s)?(\w*))\s(\d*(\d{3}))$/, "$4"))).length == 0);
        if (c) return;
        Lib.Get(`/API/Customers/Search?q=${keyword}`, `search customer`, async (rs) => {
            const data = [...Rows, ...await rs.json() as m.Customer[]];
            const keys = data.map(x => x.Phone);
            setRows(data.filter((x, i) => keys.indexOf(x.Phone) == i));
        });
    }
    const [FRow, setFRow] = React.useState<any>();
    const save = (checkStatus: boolean) => {
        Lib.Post(`/API/Customers/Update`, JSON.stringify(FRow as m.Customer), 'Update m.Customer', async (rs) => {
            const item = await rs.json() as m.Customer;
            setCurRow(item);
            setEdit(false);
            props.onChange?.(item);
        });
    }
    //#endregion
    //#region btn
    const btnClean = () => {
        setCurRow(undefined);
        props.onChange?.(undefined);
    }
    const [edit, setEdit] = useState(false);
    const openEdit = () => {
        setFRow(CurRow);
        setEdit(true);
    }
    const closeEditor = () => {
        setEdit(false);
    }
    //#endregion
    return <InputGroup>
        <Modal open={edit} onClose={closeEditor}>
            <Form formDefaultValue={CurRow} onChange={formValue => setFRow(formValue)} onSubmit={save}>
                <FormGroup>
                    <FormControlLabel>Sđt</FormControlLabel>
                    <FormControl disabled name="Phone" />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel>Viết tắt</FormControlLabel>
                    <FormControl name="ShortName" />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel>Tên</FormControlLabel>
                    <FormControl name="Name" />
                </FormGroup>
                <ButtonToolbar>
                    <IconButton type="submit" icon={<Icon as={FaSave} />} appearance="primary">Save</IconButton>
                    <IconButton icon={<Icon as={FaTimes} />} appearance="default" onClick={closeEditor}>Cancel</IconButton>
                </ButtonToolbar>
            </Form>
        </Modal>
        <InputGroup.Button onClick={openEdit} disabled={!!!props.customer}><Icon as={FaUser} /></InputGroup.Button>
        <InputPicker disabled={props.disabled}
            value={CurRow?.Phone}
            valueKey="Phone"
            labelKey="ShortName"
            creatable={creatable}
            onCreate={handleCreate}
            onSelect={handleSelect}
            onSearch={handleSearch}
            onClean={btnClean}
            searchBy={searchBy}
            data={Rows} />
    </InputGroup >
}