import { Icon } from "@rsuite/icons";
import { useEffect, useState, DragEvent } from "react";
import { FaDolly, FaShoppingCart, FaTruck, FaUsersCog, FaWrench } from "react-icons/fa";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Content, Header, Loader, Nav, Panel, Progress, Row, Stack, Tag } from "rsuite";
import { Lib } from "../../c/Lib";
import { IdRow as IRow } from "../../c/TableHelper";
import { useQueryParam } from "../../c/UrlHelper";
import { m } from "../../m/Model";
import { _HNav } from "./_HNav";

type ACT = "tName" | "PrintSI" | undefined;

interface IData extends m.Order, IRow {
    loading: boolean | undefined;
    act: ACT;
    _tName: string | undefined;
}
const RPP = 17;
export function NIndex(props: any) {
    //#region const
    const params = useParams<{ otype: string, Id: string }>();
    const [PageNo, setPageNo] = useQueryParam<number>("P");
    const [MaxPage, setMaxPage] = useQueryParam<number>("MP");
    const [Q, setQ] = useQueryParam<string>("Q");
    const activeKey = location.pathname.replace(/(\/\w*)(\/\w*)?.*/, "$1$2");
    const nav = useNavigate();

    const [Loading, setLoading] = useState<boolean>(false);
    const [Objs, setObjs] = useState<IData[]>([]);
    const [CurObj, setCurObj] = useState<IData>();
    //#endregion
    //#region function
    //#endregion
    //#region effect
    useEffect(() => {
        // if (!!params.Id) {
        // 	return;
        // }
        if (!!!PageNo) {
            setPageNo(1);
            return;
        }
        setLoading(true);
        const otype = params.otype == 'Mua' ? 0 :
            params.otype == 'BanSi' ? 3 :
                params.otype == 'BanLe' ? 2 : 1;
        Lib.Get(`/API/Orders/Manager?s=0`, "load Orders", async (rs) => {
            const dat = await rs.json() as IData[];
            setLoading(false);
            setObjs(dat);
        });
    }, [PageNo, params.otype, Q]);//, Id
    useEffect(() => {
        if (!!!CurObj) return;
        if (!!!Objs.find(x => x.Id == CurObj.Id))
            setObjs([CurObj, ...(Objs.map(x => ({ ...x, Selected: undefined } as IData)))]);
        else
            setObjs(Objs.map(x => x.Id == CurObj.Id ? CurObj : { ...x, Selected: undefined }));
        // setId(CurRow.Id, { replace: true, state: "" });
        nav(`/Orders/${params.otype}/Details/${CurObj.Id}`);
        //nav(`/Orders/${params.otype}?P=${PageNo}&MP=${MaxPage}&Id=${CurRow.Id}`);
    }, [CurObj]);
    //#endregion
    //#region Event
    const [dragObj, setDragObj] = useState<IData>();
    const drag = (evt: DragEvent<HTMLDivElement>, r: IData) => {
        setDragObj(r);
    }
    const dragOver = (evt: DragEvent<HTMLDivElement>, act: ACT, tName?: string) => {
        if (!!!dragObj) return;
        setDragObj({ ...dragObj, act: act, _tName: tName });
        evt.dataTransfer.dropEffect = "link";
        evt.preventDefault();
    }
    const drop = (evt: DragEvent<HTMLDivElement>) => {
        if (!!!dragObj) return;
        var In = { ...dragObj };
        switch (dragObj.act) {
            case "tName":
                if (dragObj._tName == "X")
                    In.CompleteDate = new Date().toISOString();
                else {
                    In.TName = In._tName;
                    In.CompleteDate = undefined;
                }
                In.loading = true;
                setObjs(Objs.map(x => x.Id == dragObj?.Id ? In : x));
                Lib.Post('/API/Orders/Update', JSON.stringify(In), `Update order ${In?.Id}`, async (rp) => {
                    const Out = await rp.json() as IData;
                    setObjs(Objs.map(x => x.Id == Out.Id ? Out : x));
                });
                setDragObj(undefined);
                break;
        }
    }
    const handleNav = (eventKey?: string) => {
        nav(eventKey?.replaceAll('*', '') ?? "");
    }
    const onRowSelect = (_row: any) => {
        const row = _row as IData;
        setCurObj({ ..._row, Selected: true });
    }
    const renderItem = (item: IData) => {
        if (item.loading)
            return <Loader />
        //  onDoubleClick={() => onRowSelect(item)}
        return <Panel onDoubleClick={() => onRowSelect(item)} style={{ maxWidth: "150px" }} onDragStart={(evt: DragEvent<HTMLDivElement>) => { drag(evt, item) }} draggable shaded bordered>
            <Tag color={item.TName == "T" ? "violet" : item.TName == "V" ? "green" : item.TName == "P" ? "orange" : undefined}>{m.OIdStr(item)}</Tag><br />
            <small>
                <Tag size="sm" color="cyan">{item.PName}</Tag><br />
                <Tag size="sm">{item.Customer?.ShortName}</Tag><br />
                <p>{item.Summary}</p>
            </small>
            <p>Tổng: {item.Total}</p>
        </Panel>
    }
    // const renderMenu = (header: string, style: React.CSSProperties, act: ACT = undefined, tName: string | undefined = undefined, children: Element | undefined = undefined) => {
    //     return (
    //         <Panel bordered onDoubleClick={ } onDrop={drop} bodyFill onDragOver={(evt: DragEvent<HTMLDivElement>) => { dragOver(evt, act, tName) }} style={{ backgroundColor: "var(--rs-gray-400)", minHeight: "50px", ...style }}>
    //             <Header>{header}</Header>
    //             {children}
    //         </Panel>);
    // }
    //#endregion
    return <Container>
        <Header>
            <Outlet />
            <_HNav />
        </Header>
        <Content>
            {/* <Row>
                <Col md={3}>{renderMenu("In bản vẽ", { background: "var(--rs-green-900)", color: "white" })}</Col>
                <Col md={3}>{renderMenu("Bản vẽ", { background: "var(--rs-violet-900)", color: "white" })}</Col>
            </Row> */}
            <Row>
                <Col md={6}>
                    <Panel bordered onDrop={drop} bodyFill onDragOver={(evt: DragEvent<HTMLDivElement>) => { dragOver(evt, "tName", undefined) }} style={{ backgroundColor: "var(--rs-gray-400)", minHeight: "100px" }}>
                        <Header>Chưa làm</Header>
                        <Stack wrap>
                            {Objs.filter(x => !!!x.TName && !!!x.CompleteDate).map(renderItem)}
                        </Stack>
                    </Panel>
                </Col>
                <Col md={12}>
                    <Panel bodyFill onDrop={drop} onDragOver={(evt: DragEvent<HTMLDivElement>) => { dragOver(evt, "tName", "T") }} style={{ backgroundColor: "var(--rs-violet-100)", minHeight: "100px" }}>
                        <Header>Toàn</Header>
                        <Stack wrap>
                            {Objs.filter(x => x.TName?.toUpperCase() == "T" && !!!x.CompleteDate).map(renderItem)}
                        </Stack>
                    </Panel>
                    <Panel bodyFill onDrop={drop} onDragOver={(evt: DragEvent<HTMLDivElement>) => { dragOver(evt, "tName", "V") }} style={{ backgroundColor: "var(--rs-green-100)", minHeight: "100px" }}>
                        <Header>Viên</Header>
                        <Stack wrap>
                            {Objs.filter(x => x.TName?.toUpperCase() == "V" && !!!x.CompleteDate).map(renderItem)}
                        </Stack>
                    </Panel>
                    <Panel bodyFill onDrop={drop} onDragOver={(evt: DragEvent<HTMLDivElement>) => { dragOver(evt, "tName", "P") }} style={{ backgroundColor: "var(--rs-orange-100)", minHeight: "100px" }}>
                        <Header>Phong</Header>
                        <Stack wrap>
                            {Objs.filter(x => x.TName?.toUpperCase() == "P" && !!!x.CompleteDate).map(renderItem)}
                        </Stack>
                    </Panel>
                </Col>
                <Col md={6}>
                    <Panel bodyFill onDrop={drop} onDragOver={(evt: DragEvent<HTMLDivElement>) => { dragOver(evt, "tName", "X") }} style={{ backgroundColor: "var(--rs-primary-100)", minHeight: "100px" }}>
                        <Header>Xong</Header>
                        <Stack wrap>
                            {Objs.filter(x => !!x.CompleteDate).sort((b, a) => a.Id - b.Id).map(renderItem)}
                        </Stack>
                    </Panel>
                </Col>
            </Row>
        </Content>
    </Container>
}