import { Icon } from "@rsuite/icons";
import React from "react";
import { FaCogs, FaMagic, FaPlus, FaSave, FaSort } from "react-icons/fa";
import { ButtonGroup, Col, Container, Content, Grid, IconButton, Row } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { LinkBtn } from "../../c/Lib";
import { CustomCell } from "../../c/TableHelper";
import { useCache } from "../../c/UrlHelper";
import { m } from "../../m/Model";

export function Parameters_Index() {
	//#region const
	const [CRows, setCRows] = useCache<m.JParam[]>("Params");
	const [Rows, setRows] = React.useState<m.JParam[]>([]);
	const [CurRow, setCurRow] = React.useState<m.JParam>();
	//#endregion
	//#region function
	//#endregion
	//#region effect
	React.useEffect(() => {
		setRows((CRows ?? []).map((x, i, a) => ({ ...x, Id: i })));
	}, [CRows]);
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows) {
			setRows([]);
			return;
		}
		if (!!!Rows.find(x => x.Short == CurRow.Short))
			setRows([CurRow, ...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as m.JParam)))]);
		else
			setRows(Rows.map(x => x.Short == CurRow.Short ? CurRow : { ...x, Selected: undefined, Editing: undefined }));
	}, [CurRow]);
	//#endregion effect
	//#region event
	const OnRowSelect = (_row: any) => {
		const row = _row as m.JParam;
		setCurRow({ ..._row, Selected: true, Editing: true });
	}
	//#endregion
	//#region btn
	const btnAdd = () => {
		setRows([...Rows ?? [], m.DEFAULT.JParam] as m.JParam[]);
	}
	const btnSort = () => {
		setRows(Rows.sort((a: any, b: any) => a.Id - b.Id));
	}
	const btnSave = () => {
		setCRows(Rows.map(x => ({ ...x, Id: undefined })) as m.JParam[]);
	}
	const btnReset=()=>{
		fetch("/static/params.json").then(async (rs)=>{
			setCRows(await rs.json() as m.JParam[]);
		});
	}
	//#endregion
	document.title = "Home";
	return <Container>
		<Content>
			<Row>
				<ButtonGroup>
					<IconButton onClick={btnAdd} appearance="primary" color="green" icon={<Icon as={FaPlus} />}>Add</IconButton>
					<IconButton onClick={btnSort} appearance="primary" icon={<Icon as={FaSort} />}>Sort</IconButton>
					<IconButton onClick={btnSave} appearance="primary" color="red" icon={<Icon as={FaSave} />}>Save</IconButton>
				</ButtonGroup>
				<IconButton onClick={btnReset} appearance="primary" icon={<Icon as={FaCogs} />}>Reset</IconButton>
				<LinkBtn url="/API/PNames/UpdateCount" icon={<Icon as={FaMagic}/>}>Update</LinkBtn>
			</Row>
			<Row>
				<Col xs={12}>
					<Table autoHeight data={Rows} onRowClick={OnRowSelect} headerHeight={30} rowHeight={30} sortColumn='Id' sortType='asc'>
						<Column sortable>
							<HeaderCell>Stt</HeaderCell>
							<CustomCell dataKey="Id" />
						</Column>
						<Column>
							<HeaderCell>Viết tắt</HeaderCell>
							<CustomCell dataKey="Short" />
						</Column>
						<Column flexGrow={1}>
							<HeaderCell>Nội dung</HeaderCell>
							<CustomCell dataKey="Text" />
						</Column>
					</Table>
				</Col>
			</Row>
		</Content>
	</Container>
}