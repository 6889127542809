import { jm } from "../m/JModel";
export class CtrBarcode {
    static getCodeType(Code?: string): jm.CODE_TYPE {
        const _code = (Code ?? "").toUpperCase()
            .replace("HTTP://", "")
            .replace("CODE.LANANHPT.COM/", "")
            .replaceAll("@", "");
        const code = _code.replaceAll(" ", "");
        if (code.startsWith("+U") || code.startsWith("U"))
            return "+U";
        if (code.startsWith("+P") || code.startsWith("P"))
            return "+P";
        if (code.startsWith("+O") || code.startsWith("O"))
            return "+O";
        if (code.startsWith("+S") || code.startsWith("S"))
            return "+S";
        if ((/(\w+)\s+(\d{9})(\d{9}\w?)0(\d{12})VN\w$/).test(_code))
            return "Pdf_Serial";
        if ((/^\d{11,13}$/).test(_code))
            return "SKU";
        if ((/^\d{6}[-A]\d/).test(_code))
            return "ProductCode";
        if ((/^[ABCDEP]-\d{5}(-\d)?/).test(_code))
            return "ProductCode";
        return undefined;
    }
    static Process(Code?: string, data?: jm.BarCodeData, ctype?: jm.CODE_TYPE): jm.BarCodeData {
        const _code = (Code ?? "").toUpperCase()
            .replace("HTTP://", "")
            .replace("CODE.LANANHPT.COM/", "")
            .replaceAll("@", "");
        const code = _code.replaceAll(" ", "");
        var Out = data ?? {} as jm.BarCodeData;
        Out.CodeType = ctype
        if (!!!ctype) Out.CodeType = CtrBarcode.getCodeType(Code);
        var str: string[] = [];
        switch (Out.CodeType) {
            case "ProductCode":
                if ((/^\d{6}[-A]\d/).test(_code))
                    str = _code.replace(/^(\d{6}[-A]\d)(.*)/, "$1|$2").split("|");
                if ((/^[ABCDEP]-\d{5}(-\d)?/).test(_code))
                    str = _code.replace(/^([ABCDEP]-\d{5}(-\d)?)(.*)/, "$1|$2").split("|");
                Out.Code = str[0];
                if (str.length > 1 && (/\s\d+/).test(str[1]))
                    Out.Quantity = Number.parseInt(str[1].replace(/.*\s(\d+).*/, "$1"));
                break;
            case "+U":
                Out.Code = undefined;
                Out.UId = Number.parseInt(code.replace(/[^\d]*(\d+)[^\d]*/, "$1"));
                break;
            case "+P":
                Out.Code = undefined;
                Out.PId = Number.parseInt(code.replace(/[^\d]*(\d+)[^\d]*/, "$1"));
                break;
            case "+O":
                Out.Code = undefined;
                Out.OId = Number.parseInt(code.replace(/[^\d]*(\d+)[^\d]*/, "$1"));
                break;
            case "+S":
                Out.Code = undefined;
                Out.Serial = code.replace(/[^\d]*(.*)/, "$1");
                break;
            default:
                Out.Code = _code;
                break;
        }
        return Out;
    }
    static LaziLoad(data: jm.BarCodeData, callback?: () => Promise<void>) {
        switch (data.CodeType) {
            case "ProductCode":
                break;
            case "+P":
                break;
        }
    }
}