import { Icon } from "@rsuite/icons";
import React from "react";
import { useEffect, useState } from "react";
import { FaCogs, FaEraser, FaPlug, FaSitemap, FaSlash, FaSort, FaToolbox } from "react-icons/fa";
import { Button, ButtonGroup, Checkbox, Col, Container, Form, Grid, IconButton, Row, Tag, Toggle, Tree } from "rsuite";
import { Lib } from "../../c/Lib";
import { jm } from "../../m/JModel";
import { m } from "../../m/Model";
import { Db } from "../../c/Db";
interface INode extends m.Tag {
    parent?: INode;
    FullLable?: string;
    children: INode[],
    NextId?: number,
    NextPId?: number,
    NewId?: number,
    NewPId?: number,
}
export function Tags(props: any) {
    const [treeData, setTreeData] = useState<INode[]>([]);
    const [newTag, setNewTag] = useState<INode>(m.DEFAULT.Tag as INode);
    const [curTag, setCurTag] = useState<INode>(m.DEFAULT.Tag as INode);
    const refresh = () => {
        Lib.Get("/api/Tags/All", undefined, async (rs) => {
            const data = await rs.json() as INode[];
            setTreeData([_toTree(data)]);
            setLoadReTree(false);
        });
    }

    useEffect(() => {
        refresh();
    }, [props]);
    const onSubmit = () => {
        const dat = { ...newTag, children: undefined, parent: undefined } as m.Tag;
        Lib.Post("/api/Tags/Update", JSON.stringify(dat), undefined, async (rs) => {
            setNewTag(m.DEFAULT.Tag as INode);
            refresh();
        });
    }
    const handleTreeSelect = (node: any) => {
        if (node.ID == curTag.ID) {
            setNewTag(curTag);
        } else {
            const nNode = { ...newTag, PID: node.ID } as INode;
            setNewTag(nNode as INode);
            setCurTag(node);
        }
    }
    const handleChangeDat = (formValue: Record<string, any>) => {
        var nDat = { ...newTag } as any;
        for (var k in formValue) {
            nDat[k] = formValue[k];
        }
        setNewTag(nDat);
    };
    const [loadReTree, setLoadReTree] = React.useState(false);
    const btnReTree = () => {
        var lNode = [] as INode[];
        _toArray({ ...treeData[0] }, lNode);
        setLoadReTree(true);
        Lib.Post(`/api/Tags/ReTree`, JSON.stringify(lNode
            .map(x => ({ ID: x.ID, PID: x.PID, NewID: x.NewId, NewPID: x.NewPId ?? x.PID } as jm.SwapTag))
            .sort((a, b) => ((a.NewID ?? 0) - (b.NewID ?? 0)))
            //.sort((a,b)=>((a.NewPID??0)-(b.NewPID??0))<<8+((a.NewID??0)-(b.NewID??0)))
        ), undefined, async (rs) => {
            //history.go(0);
            refresh();
        });
    }
    const [loadResetTreekeys, setLoadResetTreekeys] = React.useState(false);
    const _setTreeKeys = (node: INode, pTreeKey: string, pId?: number, fGenTreeKey?: (pTreeKey: string, node: INode) => string) => {
        if (!!!fGenTreeKey)
            node.TreeKeys = pTreeKey + node.ID;
        else
            node.TreeKeys = fGenTreeKey(pTreeKey, node);
        node.PID = pId;
        node.children?.forEach(x => {
            _setTreeKeys(x, (node.TreeKeys ?? "") + ".", node.ID);
        });
    }
    const _toArray = (node: INode, lNode: INode[]) => {
        lNode.push(node);
        node.children?.forEach(x => {
            _toArray(x, lNode);
        });
        node.children = [];
        node.parent = undefined;
    }
    const _toTree = (data: INode[], fPID?: (node: INode) => number | undefined, fID?: (node: INode) => number) => {
        var node: any = {};
        if (!!!fID)
            data.forEach(x => {
                node[x.ID] = { ...x, children: [], FullLable: `${x.ID}[${x.PID ?? 0}]${x.NextId ?? ""}[${x.NextPId ?? ""}]${x.NewId ?? ""}[${x.NewPId ?? ""}] ${x.TreeKeys}` } as INode;
            });
        else
            data.forEach(x => {
                node[fID(x)] = { ...x, children: [], FullLable: `${x.ID}[${x.PID ?? 0}]${x.NextId ?? ""}[${x.NextPId ?? ""}]${x.NewId ?? ""}[${x.NewPId ?? ""}] ${x.TreeKeys}` } as INode;
            });
        for (var k in node) {
            var x = node[k] as INode;
            if (!!!fPID && !!x.PID) {
                node[x.PID].children.push(x);
            } else if (!!fPID) {
                const pid = fPID(x);
                if (!!pid) {
                    node[pid].children.push(x);
                }
            }
        }
        var root = node[1] as INode;
        return root;
    }
    const btnResetTreekeys = () => {
        setLoadResetTreekeys(true);
        Lib.Get(`/API/Tags/ResetTreeKeys`, "Rs Tags tree key", async (rs) => {
            setLoadResetTreekeys(false);
            localStorage.removeItem("Tags");
        });
    }
    const btnCleanCache = () => {
        localStorage.removeItem("Tags");
        
    }
    const dragDrop = (dragNode: INode, dropNode: INode, posistion: number) => {
        switch (posistion) {
            case 0://Childrend
                dragNode.NextPId = dropNode.ID;
                break;
            case 1:
                dragNode.NextId = dropNode.ID - 0.5;
                dragNode.NextPId = dropNode.PID;
                break;
            case 2:
                dragNode.NextId = dropNode.ID + 0.5;
                dragNode.NextPId = dropNode.PID;
                break;
        }
        //dragNode.FullLable = `${dragNode.PID ?? 0} ${dragNode.Name} ${dragNode.ID}`;
        var lNode = [] as INode[];
        var tNode = { ...treeData[0] };
        _toArray(tNode, lNode);
        lNode = lNode.map(x => x.TreeKeys == dragNode.TreeKeys ? { ...dragNode, NextId: (dragNode.NextId ?? dragNode.ID) * 2 } : { ...x, NextId: (x.NextId ?? x.ID) * 2 });
        tNode = _toTree(lNode, (x) => (x.NextPId ?? x.PID));
        _setTreeKeys(tNode, "", undefined, (ptk, x) => `${ptk}${x.NextId}`);
        lNode = [] as INode[];
        _toArray(tNode, lNode);
        lNode.sort((a, b) => { return ((a.TreeKeys?.split(".")?.length ?? 0) - (b.TreeKeys?.split(".")?.length ?? 0)) * 10000 + ((a.NextId ?? 0) - (b.NextId ?? 0)) });
        var idx = 1;
        lNode = lNode.map(x => {
            const nNode = lNode.find(y => y.ID == (x.NextPId ?? x.PID));
            x.NewId = idx++;
            x.NewPId = (nNode?.NewId) ?? (nNode?.ID);
            return x;
        });
        setTreeData([_toTree(lNode, x => x.NewPId, x => x.NewId ?? x.ID)]);
        // _toArray({ ...treeData[0] }, lNode);
        // Lib.Post(`/api/Tags/ReTree`, JSON.stringify(lNode.map(x => (
        //     { ID: x.ID, PID: x.PID, NewID: x.NewId, NewPID: x.NewPId ?? x.PID } as jm.SwapTag))), undefined, async (rs) => {
        //         //history.go(0);
        //         refresh();
        //     });
    }

    const renderTreeNode = (_node: any) => {
        const node = _node as INode;
        return <>
            {node.Name}{node.IsMDisplay&&<Tag color="blue"><Icon as={FaPlug}/></Tag>}
            {node.IsSpDisplay&&<Tag color="orange"><Icon as={FaCogs}/></Tag>}
            {node.IsAsDisplay&&<Tag color="violet"><Icon as={FaToolbox}/></Tag>} {node.FullLable}
        </>
    }
    return <Container>
        {/* <Grid fluid> */}
        <Row>
            <Col xs={12}>
                <Tree style={{ maxHeight: "unset", height: "unset" }}
                    height={800}
                    // labelKey="FullLable"
                    renderTreeNode={renderTreeNode}
                    valueKey="ID"
                    data={treeData}
                    onSelect={handleTreeSelect}
                    draggable
                    onDrop={(dat: any) => dragDrop(dat.dragNode, dat.dropNode, dat.dropNodePosition)}
                    virtualized
                />
            </Col>
            <Col xs={12}>
                <Form formValue={newTag} onChange={handleChangeDat} onSubmit={onSubmit}>
                    <Form.ControlLabel>Id</Form.ControlLabel>
                    <Form.Control name="ID" />
                    <Form.ControlLabel>Tên</Form.ControlLabel>
                    <Form.Control name="Name" />
                    <Form.ControlLabel>PID</Form.ControlLabel>
                    <Form.Control name="PID" />
                    <Form.Control name="DlStyle" />
                    <Toggle checkedChildren={<Icon as={FaPlug} />} unCheckedChildren={<><Icon className='bg' color='red' as={FaSlash} />
                        <Icon className='fg' as={FaPlug} /></>}
                        checked={newTag.IsMDisplay} onChange={() => { setNewTag({ ...newTag, IsMDisplay: !newTag.IsMDisplay }) }} />

                    <Toggle color="orange" checkedChildren={<Icon as={FaCogs} />} unCheckedChildren={<><Icon className='bg' color='red' as={FaSlash} />
                        <Icon className='fg' as={FaCogs} /></>}
                        checked={newTag.IsSpDisplay} onChange={() => { setNewTag({ ...newTag, IsSpDisplay: !newTag.IsSpDisplay }) }} />

                    <Toggle color="violet" checkedChildren={<Icon as={FaToolbox} />} unCheckedChildren={<><Icon className='bg' color='red' as={FaSlash} />
                        <Icon className='fg' as={FaToolbox} /></>}
                        checked={newTag.IsAsDisplay} onChange={() => { setNewTag({ ...newTag, IsAsDisplay: !newTag.IsAsDisplay }) }} />
                    <Button type="submit">Update</Button>
                </Form>
                <ButtonGroup>
                    <IconButton icon={<Icon as={FaSort} />} loading={loadReTree} onClick={btnReTree}>ReTree</IconButton>
                    <IconButton icon={<Icon as={FaSitemap} />} loading={loadResetTreekeys} onClick={btnResetTreekeys}>Reset Treekeys</IconButton>
                    <IconButton icon={<Icon as={FaEraser} />} onClick={btnCleanCache} />
                </ButtonGroup>
            </Col>
        </Row>
        {/* </Grid> */}
    </Container>;
}