import { Icon } from "@rsuite/icons";
import { FaMagic } from "react-icons/fa";
import { ButtonGroup, Col, Container, Content, Row } from "rsuite";
import { LinkBtn } from "../../c/Lib";

export function SIBrowser() {
	//#region const
	//#endregion
	//#region function
	//#endregion
	//#region effect
	//#endregion effect
	//#region event
	//#endregion
	//#region btn
	//#endregion
	document.title = "Home";
	return <Container>
		<Content>
			<Row>
				<ButtonGroup>
				</ButtonGroup>
				<LinkBtn url="/API/PNames/UpdateCount" icon={<Icon as={FaMagic}/>}>Update</LinkBtn>
			</Row>
			<Row>
				<Col xs={12}>
				</Col>
			</Row>
		</Content>
	</Container>
}