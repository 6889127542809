import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, ButtonToolbar, Col, DatePicker, Drawer, Dropdown, Grid, IconButton, Input, InputGroup, Popover, Row, SelectPicker, Tag, Tooltip, Uploader, Whisper } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { AutoCell, CompactSpeakerReadonlyCell, CustomCell, ExpandCell, IdRow, NumCell, ReadonlyCell, shortDate as shortDateTime } from "../../c/TableHelper";
import { useCache } from "../../c/UrlHelper";
import bwipjs from 'bwip-js';
import { A4 } from "../Print/A4";
import { Lib } from "../../c/Lib";
import { m } from "../../m/Model";
import { Icon } from "@rsuite/icons";
import { FaBoxes, FaCalendar, FaCaretLeft, FaCheck, FaCheckDouble, FaCheckSquare, FaClipboardCheck, FaEdit, FaFileDownload, FaFileExcel, FaImage, FaLock, FaMoneyBill, FaPercent, FaPlus, FaPrint, FaQuestionCircle, FaRegObjectGroup, FaSave, FaSearchengin, FaShoppingBag, FaSuperscript, FaSync, FaTimes, FaWrench } from "react-icons/fa";
import { Barcode } from "../Shared/_Barcode";
import { _PName } from "../Shared/_PName";
import { _Customer } from "../Shared/_Customer";
import { _Confirm } from "../Shared/_Confirm";
import { _MSI } from "../Products/_MSI";
import { _FindProduct } from "./_FindProduct";
import { c } from "../../c/ModelHelper";
interface IData extends m.OrderDetail, IdRow {
	Total: number;
}
export function Details() {
	//#region const
	const [CjParam, setCJParam] = useCache<m.JParam[]>("Params");
	const [jParam, setJParam] = React.useState<m.JParam[]>();
	const params = useParams<{ otype: string, Id: string }>();
	const [Title, setTitle] = useState<string | undefined>();
	const [fDate, setfDate] = useState<Date | undefined | null>();
	const [order, setOrder] = useState<m.Order>();
	const [Rows, setRows] = useState<IData[]>([]);
	const [CurRow, setCurRow] = useState<IData>();
	const [A5, setA5] = useState<boolean>(true);
	const [Loading, setLoading] = useState<boolean>(false);
	const [Changed, setChanged] = useState<boolean>(false);
	const [Confirming, setConfirming] = useState<boolean>(false);
	const [IsOpenFindProduct, setIsOpenFindProduct] = useState<boolean>(false);
	const [SIOpen, setSIOpen] = useState<boolean>(false);
	const [Disabled, setDisabled] = useState<boolean>(false);
	const [update, setUpdate] = useState<string>("");
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const nav = useNavigate();
	//#endregion
	//#region function
	const submit = (dat?: m.Order, callback?: (Out: m.Order) => void) => {
		if (!!!dat) {
			dat = { ...order } as m.Order;
		}
		dat.OrderDetails =
			Rows.map(x => ({
				...x,
				BuyUnits: x.BuyUnits?.map(y => ({ ...y, BuyODId: x.Id, Product: undefined })),
				Units: x.Units?.map(y => ({ ...y, ODId: x.Id, Product: undefined }))
			} as m.OrderDetail));
		Lib.Post('/API/Orders/Update', JSON.stringify(dat), `Update order ${order?.Id}`, async (rp) => {
			const Out = await rp.json() as m.Order;
			setOrder(Out);
			setRows(Out.OrderDetails.map(x => ({ ...x, Total: (x.Quantity > 0 ? x.Quantity : x.OrderQuantity ?? 0) * x.Price })));
			setChanged(false);
			callback?.(Out);
			Lib.Get(`/API/PNames/GetParams?PName=${Out.PName}`, `Load params`, async (rs) => {
				const param = await rs.json() as m.Parameter[];
				const lst = CjParam?.map(c => {
					const p = param.find(x => x.Short == c.Short);
					if (p) {
						return { ...c, label: `${c.Short} ${c.Text} :${p.Price}`, Price: p.Price, PId: p.PId };
					} else {
						return { ...c, label: `${c.Short} ${c.Text}` };
					}
				});
				setJParam(lst);
			});
		});
		//history.
	};
	const setDate = (i: number, b: boolean, callback?: () => void) => {
		if (!order) return;
		const o = { ...order, OrderDetails: Rows } as m.Order;
		switch (i) {
			case 2: o.PayDate = b ? new Date().toISOString() : undefined;
				if (!b) break;
				o.ShipedDate = o.ShipedDate ?? new Date().toISOString();
				o.CompleteDate = o.CompleteDate ?? new Date().toISOString();
				break;
			case 1:
				if (!!!o.CompleteDate && b) {
					o.CompleteDate = o.CompleteDate ?? new Date().toISOString();
					// break;
				}
				o.ShipedDate = b ? new Date().toISOString() : undefined;
				break;
			case 0: o.CompleteDate = b ? new Date().toISOString() : undefined;
				// if (b) break;
				// o.ShipedDate = undefined;
				break;
		}
		submit(o, (Out) => {
			setOrder(Out);
			setRows(Out.OrderDetails.map(x => ({ ...x, Total: (x.Quantity > 0 ? x.Quantity : x.OrderQuantity ?? 0) * x.Price })));
			setChanged(false);
			callback?.();
		});
	};
	const ShortFilterBy = (value: string, _item: any) => {
		return (_item as m.JParam)?.Short?.toUpperCase()?.startsWith(value?.toUpperCase());
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows.find(x => x.Id == CurRow.Id))
			setRows([{ ...CurRow, Total: (CurRow.Quantity > 0 ? CurRow.Quantity : CurRow.OrderQuantity ?? 0) * CurRow.Price },
			...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))]);
		else
			setRows(Rows.map(x => x.Id == CurRow.Id ?
				{ ...CurRow, Selected: true, Total: (CurRow.Quantity > 0 ? CurRow.Quantity : CurRow.OrderQuantity ?? 0) * CurRow.Price }
				: { ...x, Selected: undefined, Editing: undefined }));
		//setId(CurRow.Id, { replace: true, state: "" });
	}, [CurRow]);
	React.useEffect(() => {
		const otype = params.otype == 'Mua' ? 0 :
			params.otype == 'BanSi' ? 3 :
				params.otype == 'BanLe' ? 2 : 1;
		setLoading(true);
		Lib.Get(`/API/Orders/Get?otype=${otype}&Id=${params.Id}`, `load Orders ${params.Id}`, async (rs) => {
			const order = await rs.json() as m.Order;
			setRows(order.OrderDetails.map(x => ({ ...x, Total: (x.Quantity > 0 ? x.Quantity : x.OrderQuantity ?? 0) * x.Price })) as IData[]);
			setOrder(order);
			setLoading(false);
			Lib.Get(`/API/PNames/GetParams?PName=${order.PName}`, `Load params`, async (rs) => {
				const param = await rs.json() as m.Parameter[];
				const lst = CjParam?.map(c => {
					const p = param.find(x => x.Short == c.Short);
					if (p) {
						return { ...c, label: `${c.Short} ${c.Text} :${p.Price}`, Price: p.Price, PId: p.PId };
					} else {
						return { ...c, label: `${c.Short} ${c.Text}` };
					}
				});
				setJParam(lst);
			});
		});
		document.title = (params.otype == "Mua" ? "Mua " : params.otype == "BanSi" ? "Bán sỉ " : params.otype == "BanLe" ? "Bán lẻ " : "Sửa chữa ")
			+ params.Id;
	}, [params.otype, params.Id]);
	React.useEffect(() => {
		// if (Rows.length > 12)
		// 	setA5(false);
		if (!!!order) return;
		const ps = jParam?.map(x => x.Short) ?? [];
		setOrder({
			...order, Summary: Rows.sort((a, b) => (ps.includes(a.Short) ? ps.indexOf(a.Short) : 9999) - (ps.includes(b.Short) ? ps.indexOf(b.Short) : 9999))
				.map(x => (x.Short ? `${x.Short}` : x.Product ? `${x.Product.Code}` : `-${(x.Text ?? "").replaceAll(/\s?(\w{1})(\w+)/g, "$1").toUpperCase()}`) + `${x.Quantity > 1 ? " x" + x.Quantity : ""}: ${x.Price * x.Quantity}`).join(', ')
		} as m.Order);
		setExpandedRowKeys(Rows.filter(x => x.Selected).map(x => x.Id));
	}, [Rows]);
	useEffect(() => {
		setDisabled(!!order?.ShipedDate);
	}, [order]);
	React.useEffect(() => {
		//if (!!!printing) return;
		try {
			const els = document.querySelectorAll("canvas.aztec") as unknown as HTMLCanvasElement[];
			els.forEach(e => {
				bwipjs.toCanvas(e, {
					bcid: 'azteccodecompact',       // Barcode type
					text: e.getAttribute("about") ?? "",    // Text to encode
					scale: 3
				});
			})
			//window.print();
			//setPrintting(undefined);
		} catch (error) {
		}
	});
	//#endregion effect
	//#region event
	const OnClose = () => {
		if (!!!Changed)
			btnClose();
		else {
			setConfirming(true);
		}
	}
	const onRowSelect = (_row: any, evt: React.MouseEvent) => {
		const row = _row as IData;
		if (evt.altKey) {
			setCurRow({ ..._row, Quantity: _row.OrderQuantity, Selected: true, Editing: undefined });
		} else {
			if (row.Editing) return;
			setCurRow({ ..._row, Selected: true, Editing: true });
		}
	}
	const onRowChange = (dat: IData) => {
		setCurRow(dat);
		// console.debug("Row change");
		setChanged(true);
	}
	const onAutoSelect = (value: string, _item: any) => {
		const item = _item as m.JParam;
		// console.debug("Auto Select", value, _item, CurRow);
		if (!!!CurRow) return;
		const nRow = {
			...CurRow, Short: item.Short, Text: CurRow.Product ? CurRow.Text : item.Text, Quantity: 1, Price: (item.Price ?? (CurRow?.Price)) ?? 0, PId: item.PId ?? (CurRow?.PId),
			Selected: true, Editing: undefined
		};
		setCurRow(nRow);
		setChanged(true);
	}
	const onPNameChange = (PName?: string, PId?: number) => {
		if (!!!order) return;
		setChanged((order.PId != PId) || (order.PName != PName) || Changed);//TODO: Checking
		setOrder({ ...order ?? m.DEFAULT.Order, PName: PName, PId: PId, Product: !!!PId ? undefined : order.Product });
	}
	const onCustomerChange = (customer?: m.Customer) => {
		if (!!!order) return;
		setOrder({ ...order ?? m.DEFAULT.Order, CId: customer?.Phone, Customer: customer });
		setChanged(true);
	}
	const onProduct = (product?: m.Product) => {
		if (!!!product) return;
		setChanged(true);
		var r = Rows.find(x => x.PId == product.Id);
		if (!!!r && CurRow?.PId == null) r = CurRow;
		if (!!!r) {
			Lib.Get(`/API/OrderDetails/Add?OId=${order?.Id}&Otype=${order?.Otype}`, undefined, async (rs) => {
				if (!!!product) return;
				r = await rs.json() as IData;
				r = {
					...r,
					Price: (order?.Otype == m.OTYPE.buy ? product.BuyPrice :
						order?.Otype == m.OTYPE.sell2 ? product.Price2 :
							order?.Otype == m.OTYPE.sell ? product.Price : product.FPrice) ?? 1,
					PId: product?.Id, Product: product
				}
				setCurRow({ ...r, Quantity: 1, Selected: true, Editing: undefined });
			});
		} else {
			setCurRow({
				...r,
				Price: (order?.Otype == m.OTYPE.buy ? product.BuyPrice :
					order?.Otype == m.OTYPE.sell2 ? product.Price2 :
						order?.Otype == m.OTYPE.sell ? product.Price : product.FPrice) ?? 1,
				PId: product.Id, Product: product,
				Quantity: r.Quantity + 1
				, Selected: true, Editing: undefined
			});
		}
	}
	const onManualProduct = (product?: m.Product) => {
		if (!!!product) return;
		setChanged(true);
		var r = Rows.find(x => x.PId == product.Id);
		if (!!!r && CurRow?.PId == null) r = CurRow;
		if (!!!r) {
			Lib.Get(`/API/OrderDetails/Add?OId=${order?.Id}&Otype=${order?.Otype}`, undefined, async (rs) => {
				if (!!!product) return;
				r = await rs.json() as IData;
				r = {
					...r,
					Price: (order?.Otype == m.OTYPE.buy ? product.BuyPrice :
						order?.Otype == m.OTYPE.sell2 ? product.Price2 :
							order?.Otype == m.OTYPE.sell ? product.Price : product.FPrice) ?? 1,
					PId: product?.Id, Product: product
				}
				setCurRow({
					...r,
					Quantity: (order?.Otype == m.OTYPE.sell2 || order?.Otype == m.OTYPE.buy) ? 0 : (r.Quantity + 1),
					OrderQuantity: (r.OrderQuantity ?? 0) + 1,
					Selected: true, Editing: undefined
				});
			});
		} else {
			setCurRow({
				...r,
				Price: (order?.Otype == m.OTYPE.buy ? product.BuyPrice :
					order?.Otype == m.OTYPE.sell2 ? product.Price2 :
						order?.Otype == m.OTYPE.sell ? product.Price : product.FPrice) ?? 1,
				PId: product.Id, Product: product,
				Quantity: (order?.Otype == m.OTYPE.sell2 || order?.Otype == m.OTYPE.buy) ? 0 : 1,
				OrderQuantity: (r.OrderQuantity ?? 0) + 1,
				Selected: true, Editing: undefined
			});
		}
	}
	const onUnit = (unit?: m.Unit) => {
		if (!!!unit || !!!unit.Product) return;
		setChanged(true);
		var r = Rows.find(x => x.PId == unit.Product?.Id);
		if (!!!r && CurRow?.PId == null) r = CurRow;
		if (!!!r)
			Lib.Get(`/API/OrderDetails/Add?OId=${order?.Id}&Otype=${order?.Otype}`, undefined, async (rs) => {
				if (!!!unit || !!!unit.Product) return;
				r = await rs.json() as IData;
				r = {
					...r,
					Price: (order?.Otype == m.OTYPE.buy ? unit.Product.BuyPrice :
						order?.Otype == m.OTYPE.sell2 ? unit.Product.Price2 :
							order?.Otype == m.OTYPE.sell ? unit.Product.Price : unit.Product.FPrice) ?? 1,
					PId: unit.Product?.Id, Product: unit.Product, Units: [unit, ...r.Units]
				}
				setCurRow({ ...r, Quantity: 1, Selected: true, Editing: undefined });
			});
		else {
			if (r.Units.find(x => x.Id == unit.Id)) {
				setCurRow({ ...r, Quantity: r.Units.length, Selected: true, Editing: undefined });
				return;
			}
			r = {
				...r,
				Price: (order?.Otype == m.OTYPE.buy ? unit.Product.BuyPrice :
					order?.Otype == m.OTYPE.sell2 ? unit.Product.Price2 :
						order?.Otype == m.OTYPE.sell ? unit.Product.Price : unit.Product.FPrice) ?? 1,
				PId: unit.Product?.Id, Product: unit.Product, Units: [unit, ...r.Units]
				, Quantity: [unit, ...r.Units].length, Selected: true, Editing: undefined
			}
			setCurRow(r);
		}
	}
	const onUploaded = () => {
		setUpdate(new Date().toISOString());
	}
	//#endregion
	//#region btn
	const btnAdd = () => {
		Lib.Get(`/API/OrderDetails/Add?OId=${order?.Id}&Otype=${order?.Otype}`, undefined, async (rs) => {
			const nr = await rs.json() as IData;
			setCurRow({ ...nr, Quantity: order?.Otype == m.OTYPE.fix ? 1 : 0, OrderQuantity: order?.Otype == m.OTYPE.fix ? 0 : 1, Selected: true });
		});
		setChanged(true);
	}
	const btnDelete = () => {
		setCurRow({ ...CurRow, Quantity: 0, OrderQuantity: 0 } as IData);
		setChanged(true);
	}
	const btnSync = () => {
		submit();
	}
	const btnPrint = () => {
		if (order?.ShipedDate) {
			window.print();
		}
		else
			setDate(1, true, () => {
				window.print();
			});
	}
	const btnA4Print = () => {
		setA5(!A5);
	}
	const btnSave = () => {
		submit(undefined, () => {
			btnClose();
		});
	}
	const btnClose = () => {
		nav(-1);
		// nav('../..');
	}
	const btnDismiss = () => {
		setConfirming(false);
	}
	const btnKhoPatse = () => {
		(async () => {
			var clipText = await navigator.clipboard.readText();
			if (clipText.split("\n")[0].trim() === "")
				return;
			var cells = clipText.split("\n")[0].split("\t");
			const jc = {
				store: cells[11],
				pname: cells[7],
				code: cells[8],
				price: cells[9]
			};
			var od = {
				...m.DEFAULT.OrderDetail, id: 0,
				Text: `${jc.code}~${jc.pname}~${jc.store}`,
				Quantity: 1, Price: Number(jc.price),
				OId: order?.Id ?? 0,
				Otype: order?.Otype,
			};
			await Lib.Get(`/api/Products/Scan?code=${jc.code}`, `Try get product`, async (rs) => {
				od.Product = await rs.json() as m.Product;
			});
			Lib.Post(`/api/OrderDetails/Update`, JSON.stringify(od), undefined, async (rs) => {
				const nod = await rs.json() as IData;
				setChanged(true);
				setCurRow({ ...nod });
			});
			//setRows();
		})();
	}
	const btnPatse = () => {
		(async () => {
			const clipText = await navigator.clipboard.readText();
			const ps = JSON.parse(clipText) as m.Product[];
			var rs = Rows;
			ps.forEach(p => {
				const odn = rs.find(x => x.PId == p.Id);
				if (!!odn) {
					odn.OrderQuantity = (odn.OrderQuantity ?? 0) + 1;
				} else {
					const od = {
						...m.DEFAULT.OrderDetail,
						Id: -rs.length,
						PId: p.Id,
						Product: p,
						Text: `${p.Name}`,
						Price: order?.Otype == m.OTYPE.buy ? p.BuyPrice :
							order?.Otype == m.OTYPE.sell2 ? p.Price2 : p.Price,
						Quantity: 0,
						OrderQuantity: 1,
						OId: order?.Id ?? 0,
						OType: order?.Otype ?? 0,
					} as IData;
					rs.push(od);
				}
			});
			submit({ ...order, OrderDetails: rs } as m.Order);
		})();
	}
	const btnCeil = () => {
		setRows(Rows.map(x => ({
			...x,
			Editing: undefined,
			Price: Math.ceil(x.Price),
			Total: (x.Quantity > 0 ? x.Quantity : x.OrderQuantity ?? 0) * Math.ceil(x.Price)
		})));
		setChanged(true);
	}
	const btnSi = () => {
		setSIOpen(true);
	}
	const btnSiDismiss = () => {
		setSIOpen(false);
	}
	const btnSiSave = (lsp: m.SparePart[]) => {
		Lib.Post(`/api/Orders/UpdateBySI?OId=${order?.Id}&Otype=${order?.Otype}`, JSON.stringify(lsp), undefined, async (rs) => {
			const dat = await rs.json() as m.Order;
			setOrder(dat);
			setRows(dat.OrderDetails.map(x => ({ ...x, Total: (x.Quantity > 0 ? x.Quantity : x.OrderQuantity ?? 0) * x.Price })));
			setChanged(false);
			setSIOpen(false);
			//props.onSave?.(dat);
		});
	}
	const btnQuantityCheck = () => {
		setRows(
			Rows.map(r => {
				var nr = { ...r };
				if (!!!nr.Product || nr.Quantity > 0)
					nr = { ...nr, OrderQuantity: nr.OrderQuantity ?? 0 == 0 ? nr.Quantity : nr.OrderQuantity ?? 0 };
				if ((nr.OrderQuantity ?? 0) > (nr.Product?.Amount ?? 0)) return { ...nr, Quantity: nr.Product?.Amount ?? 0 };
				return { ...nr, Quantity: nr.OrderQuantity ?? 0 };
			})
		);
	}
	const btnQuantityNonCheck = () => {
		setRows(
			Rows.map(r => {
				return { ...r, Quantity: r.OrderQuantity ?? 0 };
			})
		);
	}
	const btnProduct = (evt: React.MouseEvent, r: IData) => {
		if (evt.ctrlKey)
			window.open(`/Products/${r.Product?.Catalog == m.CATALOG.Machine ? "Units" : "Stores"}/${r.PId}`, "_bank");
		else if (evt.altKey)
			window.open(`/Products/ODetails/${r.PId}`, "_bank");
		else {
			setCurRow({ ...r, Expanded: !r.Expanded });
		}
	}
	const [LoadingbtnUpdatePrice, setLoadingbtnUpdatePrice] = useState<boolean>(false);
	const btnUpdatePrice = () => {
		const o = { ...order, OrderDetails: Rows } as m.Order;
		setLoadingbtnUpdatePrice(true);
		Lib.Post('/API/Orders/UpdatePrice', JSON.stringify(o), `Update order ${order?.Id}`, async (rp) => {
			setLoadingbtnUpdatePrice(false);
		});
	}
	const btnExport = () => {
		window.open(`/Orders/Csv?OId=${order?.Id}&OType=${order?.Otype}`, "_bank");
	}
	const btnAddPc = (pc: number) => {
		setRows(Rows.map(x => ({ ...x, Price: x.Price * pc })));
	}
	const btnSubPc = (pc: number) => {
		setRows(Rows.map(x => ({ ...x, Price: x.Price / pc })));
	}
	const btnRAddPc = (pc: number) => {
		if (!!!CurRow) return;
		setCurRow({ ...CurRow, Price: CurRow.Price * pc });
	}
	const btnRSubPc = (pc: number) => {
		if (!!!CurRow) return;
		setCurRow({ ...CurRow, Price: CurRow.Price / pc });
	}

	const btnNewPrice = (rowData: IData) => {
		const p = rowData.Product as m.Product;
		if (!!!p) return;
		setCurRow({ ...rowData, Price: (Math.ceil((p.KMPrice * 1.05 * 1.1) / 5) * 5 + 5) });
	}
	const btnNewPrice2 = (rowData: IData) => {
		const p = rowData.Product as m.Product;
		if (!!!p) return;
		setCurRow({ ...rowData, Price: p.KMPrice * 1.05 });
	}
	//#endregion
	//document.title = `${Id}`;
	const renderRowExpanded = (rowData: any) => {
		const dat = rowData as IData;
		const p = dat.Product as m.Product;
		if (!!!p)
			return <div></div>;
		return (
			<div>
				<Col style={{
					width: 150,
					maxHeight: 150,
					float: 'left',
					marginRight: 10,
					background: '#eee'
				}}>
					<img src={Lib.MapPath(`/Content/imgs/thumbnails/r150/${p.Code}.jpg`)} style={{ maxHeight: 150, width: 150 }} />
				</Col>
				<Col>
					{p.Name}<br />
					Từ khóa: {p.PNames}
				</Col>
				<Col>
					<b>Giá mới</b> Mua:{p.KMPrice.toFixed(2).replace(/\d(?=(\d{3})+$)/g, "$&,")} <Tag color={p.KMPrice > p.BuyPrice ? "green" : "orange"}>{(p.KMPrice * 100 / p.BuyPrice - 100).toFixed(0)}%</Tag><br />
					Sĩ:<Button onClick={() => btnNewPrice2(dat)} appearance="primary" color="violet" size="xs">{(p.KMPrice * 1.05).toFixed(2).replace(/\d(?=(\d{3})+$)/g, "$&,")}</Button><br />
					Lẽ:<Button onClick={() => btnNewPrice(dat)} appearance="primary" color="blue" size="xs">{(Math.ceil((p.KMPrice * 1.05 * 1.1) / 5) * 5 + 5).toFixed(2).replace(/\d(?=(\d{3})+$)/g, "$&,")}</Button>
				</Col>
				<Col>
					<b>Giá</b> Mua:{p.BuyPrice.toFixed(2).replace(/\d(?=(\d{3})+$)/g, "$&,")}<br />
					Sĩ:<Button onClick={() => btnNewPrice2(dat)} appearance="primary" color="violet" size="xs">{(p.Price2).toFixed(2).replace(/\d(?=(\d{3})+$)/g, "$&,")}</Button><br />
					Lẽ:<Button onClick={() => btnNewPrice(dat)} appearance="primary" color="blue" size="xs">{(p.Price).toFixed(2).replace(/\d(?=(\d{3})+$)/g, "$&,")}</Button>
				</Col>
			</div>
		);
	}
	const print = () => {
		var ods = [];
		var rows = Rows.sort((a, b) => b.Total - a.Total);
		var p = 0;
		if (A5) {
			while (rows.length > p * 12) {
				ods.push(rows.slice(p * 12, (p + 1) * 12));
				p++;
			}
		} else {
			while (rows.length > p * 40) {
				ods.push(rows.slice(p * 40, (p + 1) * 40));
				p++;
			}
		}
		if (rows.length == 0) ods.push([] as IData[]);
		return ods;
	}
	const printSI = () => {
		var pages = [];
		for (var i = 0; i <= ((order?.Product?.SiCount ?? 0) / (A5 ? 1 : 2)); i++) {
			pages.push(i);
		}
		return pages;
	}

	return <div>
		{SIOpen ? printSI().map(pn => <SIPage Order={order} Page={pn} A5={A5} />) : print().map((x, i, a) => {
			if ((i + 1) == a.length)
				return <PrintPage title={Title} date={fDate?.toISOString()} key={`PrintPage${i}`} Page={i} Order={order} Rows={x} A5={A5}>
					<tr key="tc"><td className="c" colSpan={5}><b>Tổng cộng</b></td><td>{((Rows.map(x => x.Quantity * x.Price).reduce((x, s) => x + s, 0) * 1000) ?? 0).toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")}</td></tr>
					<tr key="strM"><td className="c" colSpan={6}><b>Số tiền bằng chữ: </b>{c.Num.Camelize(c.Num.moneyTxt(Rows.map(x => x.Quantity * x.Price).reduce((x, s) => x + s, 0) * 1000))}</td></tr>
				</PrintPage>
			return <PrintPage title={Title} date={fDate?.toISOString()} key={`PrintPage${i}`} Page={i} Order={order} Rows={x} A5={A5} />
		})}
		<Drawer size="full" open onClose={OnClose}>
			<Grid fluid>
				<Row>
					<Col xs={4}>
						{params.otype == "SuaChua" ?
							<Whisper placement="bottom" trigger={"click"} speaker={<Tooltip><img style={{ width: "100%" }} src={Lib.MapPath(`/Content/imgs/Ords/${order?.Id}.jpg?update=${update}`)} />
								<Uploader draggable fileListVisible={false} onSuccess={onUploaded} action={Lib.MapPath(`/API/Orders/Upload?id=${order?.Id}`)}>
									<IconButton icon={<Icon as={FaImage} />}>Hình</IconButton>
								</Uploader>
							</Tooltip>}><h4>Số: {m.OIdStr(order)}</h4></Whisper>
							: <h4>Số: {m.OIdStr(order)}</h4>}
					</Col>
					<Col xs={5}><InputGroup title="Nhận">
						<InputGroup.Addon><Icon as={FaCalendar} /></InputGroup.Addon>
						<Input readOnly value={shortDateTime(order?.CreateDate)} />
					</InputGroup></Col>
					<Col xs={5}><InputGroup title="Xong">
						<InputGroup.Button onClick={() => { setDate(0, true) }}><Icon color={!!!order?.CompleteDate ? "#c0c" : "#666"} as={FaCheckSquare} /></InputGroup.Button>
						<Input readOnly value={shortDateTime(order?.CompleteDate)} />
						<InputGroup.Button onClick={() => { setDate(0, false) }}><Icon color={!!order?.CompleteDate ? "#c00" : "#ccc"} as={FaTimes} /></InputGroup.Button>
					</InputGroup></Col>
					<Col xs={5}><InputGroup title="Giao">
						<InputGroup.Button onClick={() => setDate(1, true)}><Icon color={!!!order?.ShipedDate ? "#00f" : "#666"} as={FaShoppingBag} /></InputGroup.Button>
						<Input readOnly value={shortDateTime(order?.ShipedDate)} />
						<InputGroup.Button onClick={() => setDate(1, false)}><Icon color={!!order?.ShipedDate ? "#c00" : "#ccc"} as={FaTimes} /></InputGroup.Button>
					</InputGroup></Col>
					<Col xs={5}><InputGroup title="Thu">
						<InputGroup.Button onClick={() => setDate(2, true)}><Icon color={!!!order?.PayDate ? "#c00" : "#666"} as={FaMoneyBill} /></InputGroup.Button>
						<Input readOnly value={shortDateTime(order?.PayDate)} />
						<InputGroup.Button onClick={() => setDate(2, false)}><Icon color={!!order?.PayDate ? "#c00" : "#ccc"} as={FaTimes} /></InputGroup.Button>
					</InputGroup></Col>
				</Row>
				<Row>
					<Col xs={params.otype == "SuaChua" ? 8 : 12}><Barcode catalog={params.otype == "SuaChua" ? '2,3' : '1,3,2'} onManualProduct={onManualProduct} onProduct={onProduct} onUnit={onUnit} disabled={Disabled} /></Col>
					{params.otype == "SuaChua" && <Col xs={8}><_PName disabled={Disabled} PName={order?.PName} PId={order?.PId} onChange={onPNameChange} /></Col>}
					<Col xs={params.otype == "SuaChua" ? 8 : 12}><_Customer disabled={Disabled} customer={order?.Customer} onChange={onCustomerChange} /></Col>
				</Row>
				<Row>
					<Col xs={9}>
						<ButtonToolbar>
							<ButtonGroup><IconButton icon={<Icon as={FaSearchengin} />} onClick={() => setIsOpenFindProduct(true)} /></ButtonGroup>
							<ButtonGroup>
								<IconButton disabled={Disabled} onClick={btnAdd} icon={<Icon as={FaPlus} />} appearance="primary" color="blue" title="Thêm" />
								<IconButton disabled={Disabled} onClick={btnKhoPatse} icon={<Icon as={FaFileExcel} />} appearance="primary" color="green" title="Dán từ kho" />
								<IconButton disabled={Disabled} onClick={btnPatse} icon={<Icon as={FaClipboardCheck} />}>Dán</IconButton>
								<IconButton disabled={Disabled} onClick={btnExport} icon={<Icon as={FaFileDownload} />} title="Download" />
								<IconButton disabled={Disabled} onClick={btnCeil} icon={<Icon as={FaSuperscript} />} appearance="primary" color="orange" title="Làm tròn" />
								<IconButton disabled={Disabled} onClick={btnDelete} icon={<Icon as={FaTimes} />} appearance="primary" color="red" title="Xóa" />
							</ButtonGroup>
							<Dropdown icon={<Icon as={FaPercent} />}>
								<Dropdown.Item onClick={() => btnAddPc(1.10)}>+10</Dropdown.Item>
								<Dropdown.Item onClick={() => btnSubPc(1.10)}>-10</Dropdown.Item>
								<Dropdown.Item onClick={() => btnAddPc(1.08)}>+8</Dropdown.Item>
								<Dropdown.Item onClick={() => btnSubPc(1.08)}>-8</Dropdown.Item>
							</Dropdown>

							<Dropdown disabled={!!!CurRow} appearance="ghost" icon={<Icon as={FaPercent} />}>
								<Dropdown.Item onClick={() => btnRAddPc(1.10)}>+10</Dropdown.Item>
								<Dropdown.Item onClick={() => btnRSubPc(1.10)}>-10</Dropdown.Item>
								<Dropdown.Item onClick={() => btnRAddPc(1.08)}>+8</Dropdown.Item>
								<Dropdown.Item onClick={() => btnRSubPc(1.08)}>-8</Dropdown.Item>
							</Dropdown>
							<ButtonGroup>
								<IconButton disabled={Disabled || (((order?.Product?.State ?? 0) & m.PROD_STATE.SI) == 0)} onClick={btnSi} icon={<Icon as={FaRegObjectGroup} />} appearance="primary" color="blue" title="SI" />
								<Button onClick={btnA4Print}>{A5 ? "A5" : "A4"}</Button>
								<IconButton disabled={!Disabled} onClick={btnPrint} icon={<Icon as={FaPrint} />}>HĐ</IconButton>
								<Dropdown color="violet" appearance="ghost" icon={<Icon as={FaEdit} />} trigger={['click', 'hover']}>
									<Dropdown.Item><SelectPicker data={["Biên nhận","Hóa đơn","Bản báo giá"].map(x=>({lable:x,value:x}))} value={Title} onChange={(v)=>setTitle(v??undefined)} /></Dropdown.Item>
									<Dropdown.Item><DatePicker value={fDate} onChange={(v) => setfDate(v)} /></Dropdown.Item>
								</Dropdown>
								<IconButton onClick={btnSync} icon={
									<><Icon className='bg' color='red' as={FaSave} /><Icon className='fg' as={FaSync} /></>} title="Lưu & Làm mới" />
								<IconButton disabled={Disabled} onClick={btnQuantityCheck} icon={
									<><Icon className='bg' color='violet' as={FaCheck} /><Icon className='fg' as={FaBoxes} /></>} appearance="primary" color="green" title="Kiểm tra tồn" />
								<IconButton disabled={Disabled} onClick={btnQuantityNonCheck} icon={
									<><Icon className='bg' color='violet' as={FaCheckDouble} /><Icon className='fg' as={FaLock} /></>} appearance="primary" color="blue" title="1" />
								<IconButton loading={LoadingbtnUpdatePrice} onClick={btnUpdatePrice} icon={
									<><Icon className='bg' color='orange' as={FaSave} /><Icon className='fg' as={FaMoneyBill} /></>} title="Cập nhật giá" />
							</ButtonGroup>
							<Whisper placement="bottom" speaker={<Popover>
								Alt+Click Lấy
							</Popover>} trigger="click">
								<Icon as={FaQuestionCircle} />
							</Whisper>
						</ButtonToolbar>
					</Col>
					<Col xs={2}>
						<InputGroup title="Thợ">
							<InputGroup.Addon><Icon as={FaWrench} /></InputGroup.Addon>
							<Input value={order?.TName ?? ""} onChange={(value: any) => { setOrder({ ...order, TName: value } as m.Order); setChanged(true) }} />
						</InputGroup>
					</Col>
					<Col xs={10}>Nội dung: {order?.Summary}</Col>
					<Col xs={3}>Tổng: {Rows.reduce((s, r) => r.Total + s, 0)}</Col>
				</Row>
				<Table loading={Loading} data={Rows} height={30 * 18} headerHeight={30} rowHeight={30} onRowClick={onRowSelect} rowKey="Id" rowExpandedHeight={150} expandedRowKeys={expandedRowKeys} renderRowExpanded={renderRowExpanded} >
					<Column flexGrow={1}>
						<HeaderCell>Mã</HeaderCell>
						<ReadonlyCell dataKey='Product.Code'>
							{(r: IData, v: string) => <Tag className="pointer" onClick={(evt: React.MouseEvent) => btnProduct(evt, r)}>{v}</Tag>}
						</ReadonlyCell>
					</Column>
					<Column flexGrow={2}>
						<HeaderCell>Vật tư</HeaderCell>
						<CompactSpeakerReadonlyCell speaker={(r: IData) => {
							var Out = "";
							r.Units?.forEach((x: m.Unit) => {
								Out += ` ${x?.Id}`;
							});
							r.BuyUnits?.forEach((x: m.Unit) => {
								Out += ` ${x?.Id}`;
							}); return Out;
						}}>{(r: IData) => {
							var Out = r.Product?.Name;
							return Out;
						}}</CompactSpeakerReadonlyCell>
					</Column>
					{params.otype == "SuaChua" &&
						<Column flexGrow={1}>
							<HeaderCell>Viết tắt</HeaderCell>
							<AutoCell disabled={Disabled} dataKey='Short' onSelect={onAutoSelect} data={jParam?.map(x => ({ ...x }))} labelKey='Text' filterBy={ShortFilterBy} />
						</Column>
					}
					<Column flexGrow={3}>
						<HeaderCell>Nội dung</HeaderCell>
						<CustomCell disabled={Disabled} dataKey='Text' onChange={onRowChange} />
					</Column>
					{params.otype == "Mua" &&
						<Column flexGrow={1}>
							<HeaderCell>Vt</HeaderCell>
							<CustomCell disabled={Disabled} dataKey='Store' onChange={onRowChange} />
						</Column>
					}
					<Column flexGrow={1}>
						<HeaderCell>Vị trí</HeaderCell>
						<CustomCell disabled={Disabled} dataKey='Product.Store' onChange={onRowChange} />
					</Column>
					<Column align="right">
						<HeaderCell>Đơn giá</HeaderCell>
						<NumCell disabled={Disabled} dataKey='Price' onChange={onRowChange} />
					</Column>
					<Column align="right">
						<HeaderCell>SL</HeaderCell>
						<NumCell disabled={Disabled} dataKey='Quantity' onChange={onRowChange} />
					</Column>
					<Column align="right">
						<HeaderCell>Đặt SL</HeaderCell>
						<NumCell disabled={Disabled} dataKey='OrderQuantity' onChange={onRowChange} />
					</Column>
					<Column align="right" >
						<HeaderCell>Thành tiền</HeaderCell>
						<ReadonlyCell dataKey='Total' />
					</Column>
				</Table>
			</Grid>
		</Drawer>
		<_MSI open={SIOpen} order={order} onClose={btnSiDismiss} onSave={btnSiSave} />
		<_Confirm open={Confirming} onSave={btnSave} onClose={btnClose} onDismiss={btnDismiss} />
		<_FindProduct open={IsOpenFindProduct} onClose={() => setIsOpenFindProduct(false)} />
	</div >
}
interface IPrint {
	Order?: m.Order;
	Rows?: m.OrderDetail[];
	A5?: boolean;
	Page?: number;
	children?: React.ReactNode;
	title?: string;
	date?: string;
}
export function SIPage(props: IPrint) {
	return <A4 A5={props.A5} className={`siOrder p${props.Page}`}>
		<img style={{ position: "absolute", height: "100vw", transformOrigin: "top left", transform: "rotate(90deg) translateY(-100%)" }} src={Lib.MapPath(`/Content/si/${props.Order?.Product?.Code}/${(props.Page ?? 0) * (props.A5 ? 1 : 2)}.png`)} />
		{!props.A5 && ((props.Order?.Product?.SiCount ?? 0) > (props.Page ?? 0) * 2) &&
			<img style={{ position: "absolute", top: "148mm", height: "100vw", transformOrigin: "top left", transform: "rotate(90deg) translateY(-100%)" }} src={Lib.MapPath(`/Content/si/${props.Order?.Product?.Code}/${(props.Page ?? 0) * 2 + 1}.png`)} />
		}
		<canvas className="aztec" about={`code.LanAnhPT.com/+${m.OTYPE2Char(props.Order?.Otype)}/${props.Order?.Id}`} />
		<span className="Id"><b>Số: </b>{m.OIdStr(props.Order)}</span>
	</A4>
}
export function PrintPage(props: IPrint) {
	const fillRow = () => {
		var rows = [];
		for (var i = (props.Rows?.length ?? 0) + 1; i < ((props.Rows?.length ?? 0) < 13 ? 13 : 41); i++) {
			rows.push(<tr key={`od${i}`}><td>{i}</td><td colSpan={4}></td><td></td></tr>);
		}
		return rows;
	}
	var cdate = props.Order?.CreateDate;
	var pdate = props.Order?.PayDate;
	var sdate = props.Order?.ShipedDate;
	var title = props.Order?.Otype == m.OTYPE.buy ? "ĐƠN ĐẶT HÀNG" : props.Order?.Otype == m.OTYPE.fix ? "BIÊN NHẬN" : "HÓA ĐƠN" ;
	if (!!props.title) title = props.title.toUpperCase();
	if (!!props.date) {
		cdate = props.date;
		if (!!pdate)
			pdate = props.date;
		if (!!sdate)
			sdate = props.date;
	}
	return <A4 A5={props.A5} className={`order p${props.Page}`}>
		<span className="cus"><b>Khách: </b>{props.Order?.Customer?.Name ?? props.Order?.Customer?.ShortName ?? props.Order?.CId}</span>
		<span className="phone"><b>SĐT: </b>{props.Order?.CId}</span>
		<span className="date"><small><b>Ngày: </b>{cdate?.replace(/^\d{2}(\d{2})-(\d{2})-(\d{2}).*/, "$3/$2/$1")}</small></span>
		{props.Order?.Otype == m.OTYPE.fix && <span className="pname"><b>Máy: </b>{props.Order?.PName}</span>}
		<canvas className="aztec" about={`code.LanAnhPT.com/+${m.OTYPE2Char(props.Order?.Otype)}/${props.Order?.Id}`} />
		<div className="info">
			Cty TNHH TM DV SX<br />
			<span><b>LAN ANH</b></span><br />
			<span>ĐC: 70 Quang Trung P.10<br />Q.Gò Vấp Tp.HCM</span><br />
			<span>ĐT: 39843984 - 0909655566</span><br />
			<span>Email: sale@lananhpt.com</span><br />
			<span>websile: lananhpt.com</span>
		</div>
		<h3>{title}</h3>
		<span className="Id"><b>Số: </b>{m.OIdStr(props.Order)}</span>
		<table>
			<thead>
				<tr>
					<th>STT</th>
					<th>Mã</th>
					<th className="text">Nội dung</th>
					<th>Đơn giá</th>
					<th>SL</th>
					<th>Thành tiền</th>
				</tr>
			</thead>
			<tbody>
				{props.Rows?.map((x, i) => {
					if (x.Quantity == 0) return (<tr key={`od${i}`}><td>{i}</td><td colSpan={4}></td><td></td></tr>);
					//total += x.Quantity * x.Price;
					return (<tr key={`od${i}`}>
						<td>{i + 1}</td>
						{x.Product && <td>{x?.Product?.Code}</td>}
						{x.Product ? <td className="text" colSpan={1}>{x.Product.Name}[{x.Product.Store}]</td> :
							<td className="text" colSpan={2}>{x.Text}</td>}
						<td>{(x.Price * 1000).toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")}</td>
						<td>{x.Quantity}</td>
						<td>{(x.Quantity * x.Price * 1000).toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")}</td>
					</tr>)
				})}
				{fillRow()}
				{props.children}
				{/* <tr><td className="c" colSpan={5}><b>Tổng cộng</b></td><td>{(total * 1000).toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,")}</td></tr>
				<tr><td className="c" colSpan={6}><b>Số tiền bằng chữ: </b></td></tr> */}
			</tbody>
		</table>
		{!!props.Order?.PayDate && <span className={!!props.children ? "PayDate D" : "PayDate"}>Đã Thanh toán: {pdate?.replace(/^\d{2}(\d{2})-(\d{2})-(\d{2}).*/, "$3/$2/$1")}</span>}
		{!!props.Order?.ShipedDate && <span className={!!props.children ? "ShipedDate D" : "ShipedDate"}>Đã Giao: {sdate?.replace(/^\d{2}(\d{2})-(\d{2})-(\d{2}).*/, "$3/$2/$1")}</span>}
		<span className={!!props.children ? "SNgN D" : "SNgN"}>Người nhận</span>
		<span className={!!props.children ? "SNgLp D" : "SNgLp"}>Người lập phiếu</span>
	</A4 >
}