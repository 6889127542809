import { ButtonToolbar } from "rsuite";
import { jm } from "../m/JModel";
import { m } from "../m/Model";
import { Lib } from "./Lib";
type TableNames = "Tags" | "TagProducts" | "Products" | "Changelogs";
type Idx = "by_UName" | "by_TId" | "by_PId" | "by_SKU" | "by_Id";
export class Db {
    static db: IDBDatabase;
    static install(ver?: number): Promise<IDBDatabase> {
        if (!!!ver && !!Db.db) return new Promise<IDBDatabase>((resolve, reject) => resolve(Db.db));
        console.info("db install", ver);
        return new Promise<IDBDatabase>((resolve, reject) => {
            const rq = indexedDB.open("lananhpt", ver);
            rq.onupgradeneeded = function () {
                console.info("db install onupgradeneeded");
                Db.ReNew(rq.result, true).then(() => {
                    Db.db = rq.result;
                    resolve(Db.db);
                    console.info("db install onupgradeneeded Finist");
                });
                // Db.loadData((log) => console.info(log));
            };
            rq.onsuccess = function () {
                console.info("db install onsuccess");
                //Db.ReNew(rq.result).then(() => {
                Db.db = rq.result;
                resolve(Db.db);
                //});
            };
        });
    }
    static instance(setLoadingStr: (str: string) => void, callback?: (per: number) => void) {
        console.info("db instance");
        Db.install(Math.ceil(Date.now() / 864e5))
            //.then()
            .then(async () => {
                console.info("db instance process");
                var bout = 0;
                Db.All<m.Changelog>("Changelogs").then(cl => {
                    cl.forEach(x => {
                        if (!!!x.ODate || x.ODate == "undefined") {
                            if (x.Id == m.TableNames.Tag) bout |= m.TableNames.Tag | m.TableNames.TagProduct;
                            if (x.Id == m.TableNames.TagProduct) bout |= m.TableNames.TagProduct;
                            // if (x.Id == m.TableNames.Product) bout |= m.TableNames.Product | m.TableNames.TagProduct;
                        }
                    })
                }).catch(() => {
                }).finally(() => {
                    console.info("Db Create table", bout);
                    if ((bout & m.TableNames.Tag) > 0) {
                        setLoadingStr(`update Tag`);
                        Db.RefreshData("Tags");
                        callback?.(25);
                    }
                    if ((bout & m.TableNames.TagProduct) > 0) {
                        setLoadingStr(`update ProductTag`);
                        Db.RefreshData("TagProducts");
                        callback?.(50);
                    }
                    if ((bout & m.TableNames.Product) > 0) {
                        setLoadingStr(`update Product`);
                        //Db.RefreshData("Tags");
                        callback?.(75);
                    }
                    Db.RefreshData("Changelogs");
                });
            });
    }
    static ReNew(db: IDBDatabase, clear?: boolean) {
        console.info("db renew", db, clear);
        return new Promise<void>((resolve, reject) => {
            try {
                if (!!clear) {
                    if (!!db.objectStoreNames.contains("Tags"))
                        db.deleteObjectStore("Tags");
                    if (!!db.objectStoreNames.contains("TagProducts"))
                        db.deleteObjectStore("TagProducts");
                    if (!!db.objectStoreNames.contains("Products"))
                        db.deleteObjectStore("Products");
                    if (!!db.objectStoreNames.contains("Changelogs"))
                        db.deleteObjectStore("Changelogs");
                }

                if (!!!db.objectStoreNames.contains("Tags")) {
                    const tags = db.createObjectStore("Tags", { keyPath: "ID" });
                    tags.createIndex("by_UName", "UName");
                }
                if (!!!db.objectStoreNames.contains("TagProducts")) {
                    const tagProducts = db.createObjectStore("TagProducts", { keyPath: ["T", "P"] });
                    tagProducts.createIndex("by_TId", "T");
                    tagProducts.createIndex("by_PId", "P");
                }
                if (!!!db.objectStoreNames.contains("Products")) {
                    const products = db.createObjectStore("Products", { keyPath: ["Id"] });
                    products.createIndex("by_SKU", "SKU");
                }
                if (!!!db.objectStoreNames.contains("Changelogs")) {
                    const ChangeLog = db.createObjectStore("Changelogs", { keyPath: "Id" });
                    ChangeLog.createIndex("by_Id", "Id");
                    ChangeLog.put(undefined, m.TableNames.Tag);
                    ChangeLog.put(undefined, m.TableNames.TagProduct);
                    ChangeLog.put(undefined, m.TableNames.Product);
                }
            } catch (error) {
                console.debug(error);
            } finally {
                resolve();
            }
        });
    }
    static Reset(tableName?: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Db.db.close();
            const rq = indexedDB.deleteDatabase("lananhpt");
            rq.onsuccess = _ => resolve();
        });
    }
    static RefreshData(tbName: TableNames, clear?: boolean) {
        console.info("db RefreshData", tbName, clear);
        return new Promise<void>((resolve, reject) => {
            switch (tbName) {
                case "Changelogs":
                    Lib.Get("/api/Tools/Changelogs", undefined, async (rs) => {
                        const cl = await rs.json() as m.Changelog[];
                        const ys = await Db.All<m.Changelog>("Changelogs");
                        const ts = Db.db.transaction(["Changelogs"], "readwrite");
                        const tb = ts.objectStore("Changelogs");
                        if (!!!clear)
                            tb.clear();
                        cl.forEach(x => {
                            var y = ys?.find(z => z.Id);
                            if (!!!y || !!!y.Date) {
                                tb.put(x);
                            }
                            else if (!!!x.Date || x.Date > y.Date) {
                                console.info("Db update needed", x);
                                tb.put({ ...x, Date: undefined });
                            }
                        });
                        ts.commit();
                        ts.oncomplete = _ => resolve;
                    }); break;
                case "Tags":
                    Lib.Get("/API/Tags/All", undefined, async (rs) => {
                        var cls = (await Db.FindAll<m.Changelog>("Changelogs", "by_Id", m.TableNames.Tag)).pop();
                        const tags = await rs.json() as m.Tag[];
                        const ts = Db.db.transaction(["Tags", "Changelogs"], "readwrite");
                        const tb = ts.objectStore("Tags");
                        if (!!!clear)
                            tb.clear();
                        tags.forEach(x => { const r = tb.put(x); });
                        const cl = ts.objectStore("Changelogs");
                        if (!!cls) {
                            cl.delete(m.TableNames.Tag);
                            cls = { ...cls, ODate: cls?.Date };
                            cl.put(cls);
                        }
                        ts.commit();
                        ts.oncomplete = _ => resolve;
                    }); break;
                case "TagProducts":
                    Lib.Get("/API/Tags/TagProducts", undefined, async (rs) => {
                        var cls = (await Db.FindAll<m.Changelog>("Changelogs", "by_Id", m.TableNames.TagProduct)).pop();
                        const TPs = await rs.json() as jm.TagProduct[];
                        const ts = Db.db.transaction(["TagProducts","Changelogs"], "readwrite");
                        const tb = ts.objectStore("TagProducts");
                        if (!!!clear)
                            tb.clear();
                        TPs.forEach(x => { const r = tb.put(x); });
                        const cl = ts.objectStore("Changelogs");
                        if (!!cls) {
                            cl.delete(m.TableNames.TagProduct);
                            cls = { ...cls, ODate: cls?.Date };
                            cl.put(cls);
                        }
                        ts.commit();
                        ts.oncomplete = _ => resolve;
                    }); break;
            }
        });
    }
    static async loadData(onlog?: (log: string) => void) {
        console.info("db loadData", Db.db);
        await Lib.Get("/API/Tags/All", undefined, async (rs) => {
            const tags = await rs.json() as m.Tag[];
            const ts = Db.db.transaction(["Tags"], "readwrite");
            const tb = ts.objectStore("Tags");
            tb.clear();
            tags.forEach(x => { const r = tb.add(x); r.onsuccess = () => { }; });
            onlog?.("Loaded Tags");
        });
        await Lib.Get("/API/Tags/TagProducts", undefined, async (rs) => {
            const TPs = await rs.json() as jm.TagProduct[];
            const ts = Db.db.transaction(["TagProducts"], "readwrite");
            const tb = ts.objectStore("TagProducts");
            tb.clear();
            TPs.forEach(x => { const r = tb.add(x); r.onsuccess = () => { }; });
            onlog?.("Loaded TagProducts");
        });
        var mp = 0;
    }
    static All<T>(tableName: TableNames): Promise<T[]> {
        const p = new Promise<T[]>(async (resolve, reject) => {
            Db.install().then((db) => {
                var rs: any;
                const tx = db.transaction(tableName);
                tx.oncomplete = _ => resolve(rs);
                const table = tx.objectStore(tableName);
                const request = table.getAll();
                request.onsuccess = _ => resolve(request.result);
            });
        });
        return p;
    }
    static FindAll<T>(tableName: TableNames, index: Idx, value: any): Promise<T[]> {
        const p = new Promise<T[]>((resolve, reject) => {
            Db.install().then((db) => {
                var rs: any;
                const tx = db.transaction(tableName);
                tx.oncomplete = _ => resolve(rs);
                const table = tx.objectStore(tableName);
                const request = table.index(index).getAll(value);
                request.onsuccess = _ => rs = request.result;
            });
        });
        return p;
    }
    static Where<T>(tableName: TableNames, filter: (x: T) => boolean): Promise<T[]> {
        const p = new Promise<T[]>((resolve, reject) => {
            Db.install().then((db) => {
                var rs: any;
                const tx = db.transaction(tableName);
                tx.oncomplete = _ => resolve(rs);
                const table = tx.objectStore(tableName);
                const request = table.openCursor();
                request.onsuccess = _ => {
                    if (!!!rs) rs = [];
                    var cursor = request.result;
                    if (cursor) {
                        if (filter(cursor.value))
                            rs.push(cursor.value);
                        cursor.continue();
                    }
                }
            });
        });
        return p;
    }
}