import { Icon } from "@rsuite/icons";
import React from "react";
import { FaBox, FaBoxes, FaBoxOpen, FaClipboard, FaCopy, FaImage, FaMagic, FaMinus, FaQrcode, FaSave, FaStore, FaStoreAlt, FaSync } from "react-icons/fa";
import { Badge, ButtonGroup, ButtonToolbar, Col, Container, Content, Header, IconButton, Input, InputGroup, Row } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { Lib } from "../../c/Lib";
import { CompactSpeakerReadonlyCell, IdRow, ReadonlyCell } from "../../c/TableHelper";
import { useQueryParam } from "../../c/UrlHelper";
import { m } from "../../m/Model";
import { Barcode } from "../Shared/_Barcode";
import { _HNav } from "../Products/_HNav";
import { StoreMap } from "./StoreMap";
const RPP = 17;
interface IUData extends m.Unit, IdRow {
	LastCheck?: string;
	LastSell?: string;
}
export function Units(props: any) {
	//#region const
	//const [Id, setId] = useQueryParam<number>("Id");
	const [store, setStore] = useQueryParam<string>("Store");
	const [cproduct, setCProduct] = React.useState<m.Product | undefined>();
	const [URows, setURows] = React.useState<IUData[]>([]);
	const [CurRow, setCurRow] = React.useState<IUData>();
	const [SortType, setSortType] = React.useState<'desc' | 'asc'>('desc');
	const [SortColumn, setSortColumn] = React.useState<string>("CheckDate");
	//#endregion
	//#region function
	const submit = () => {
		Lib.Post(`/API/Units/UpdateAll`, JSON.stringify(URows.map(x => ({ ...x, store: store }))), "Update All m.Unit", async (rs) => {
			reload();
		});
	}
	const reload = () => {
		if (!!!store) {
			return;
		}
		setlBtnLoad(true);
		Lib.Get(`/API/Units/GetByStore?store=${store}`, "load Orders", async (rs) => {
			const dat = await rs.json() as IUData[];
			setURows((dat ?? []).map(x => ({ ...x, LastCheck: x.CheckDate, LastSell: x.SellDate })));
			setlBtnLoad(false);
		});
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!URows.find(x => x.Id == CurRow.Id))
			setURows([CurRow, ...(URows.map(x => ({ ...x, Selected: undefined } as IUData)))]);
		else
			setURows(URows.map(x => x.Id == CurRow.Id ? CurRow : { ...x, Selected: undefined, Warning: !!(x.Serial) && !!URows.find(a => a.Serial == x.Serial && a.Id != x.Id) }));
		//setId(CurRow.Id, { replace: true, state: "" });
		//nav(`/Products/Store?P=${PageNo}&MP=${MaxPage}&Id=${CurRow.Id}`);
	}, [CurRow]);
	React.useEffect(() => {
		document.title = `Kiểm vị trí ${store}`;
		if (!!!store) return;
		//set_Store(store);
		reload();
	}, [store]);
	const getData = () => {
		if (SortColumn && SortType) {
			return URows.sort((a, b) => {
				// switch(SortColumn){
				// 	case "nCheck":
				// 		let x=(a.nCheck??a.checkDate??"").replace;
				// 		return (SortType === 'asc') ? .localeCompare(y) : y.localeCompare(x);
				// }
				let x = (a as any)[SortColumn];
				let y = (b as any)[SortColumn];
				// if(SortColumn=="nCheck"){
				// 	x=a.checkDate??a.buyDate;
				// 	y=b.checkDate??b.buyDate;
				// }
				if (typeof x === 'number') {
					if (SortType === 'asc') {
						return x - y;
					} else {
						return y - x;
					}
				} else {
					return (SortType === 'asc') ? (x ?? "").localeCompare(y) : (y ?? "").localeCompare(x);
				}
			});
		}
		return URows;
	};
	//#endregion effect
	//#region event
	const onRowSelect = (_row: any) => {
		const row = _row as IUData;
		setCurRow({ ..._row, Selected: true });
	}
	const OnUnit = (unit: m.Unit, manual?: boolean) => {
		const strNow = new Date().toISOString();
		setCurRow({
			...unit, Selected: true, CheckDate: strNow, BuyDate: unit.BuyDate ?? strNow, SellDate: undefined,
			State: ((unit?.State ?? 0) & ~m.STATE.AztecPrint)
				| (manual ? m.STATE.AztecPrint : 0)
		} as IUData);
	};
	const OnProduct = (product?: m.Product) => {
		const strNow = new Date().toISOString();
		setCurRow({ ...CurRow, Store: product?.Store?.replace(/(\w+, )*(\w+)$/g, "$2") ?? "", Product: product, PId: product?.Id, Selected: true, CheckDate: strNow, BuyDate: CurRow?.BuyDate ?? strNow } as IUData);
	};
	const OnSerial = (serial?: string, SKU?: string) => {
		const strNow = new Date().toISOString();
		if (!!!CurRow?.Product && !!SKU)
			Lib.Get(`/API/Products/Scan?code=${SKU}`, "Load m.Product", async (rs) => {
				const product = await rs.json() as m.Product;
				setCurRow({ ...CurRow, Product: product, PId: product?.Id, Selected: true, CheckDate: strNow, BuyDate: CurRow?.BuyDate ?? strNow } as IUData);
			});
		else
			setCurRow({ ...CurRow, Serial: serial, Selected: true, CheckDate: strNow } as IUData);
	};
	const handleSortColumn = (sortColumn: any, sortType: any) => {
		setSortColumn(sortColumn);
		setSortType(sortType);
	}
	//#endregion
	//#region btn
	const [lBtnLoad, setlBtnLoad] = React.useState(false);
	const [lBtnMagic, setlBtnMagic] = React.useState(false);
	const btnDelete = () => {
		setURows(URows.filter(x => x.Id != CurRow?.Id));
	}
	const btnMagic = () => {
		setlBtnMagic(true);
		Lib.Get(`/API/Units/ReCalStore`, "ReCalStore Orders", async () => {
			setlBtnMagic(false);
		});
	}
	const btnQrcode = () => {
		const strNow = new Date().toISOString();

		setCurRow({
			...CurRow, State: ((CurRow?.State ?? 0) & m.STATE.AztecPrint) ? ((CurRow?.State ?? 0) & ~m.STATE.AztecPrint) : ((CurRow?.State ?? 0) | m.STATE.AztecPrint)
			, Selected: true, CheckDate: strNow, BuyDate: CurRow?.BuyDate ?? strNow, SellDate: undefined
		} as IUData);
	}
	const btnVoid = () => {
		const strNow = new Date().toISOString();
		setCurRow({
			...CurRow, State: ((CurRow?.State ?? 0) & m.STATE.Void) ? ((CurRow?.State ?? 0) & ~m.STATE.Void) : ((CurRow?.State ?? 0) | m.STATE.Void)
			, Selected: true, CheckDate: strNow, BuyDate: CurRow?.BuyDate ?? strNow, SellDate: undefined
		} as IUData);
	}
	const btnShowing = () => {
		const strNow = new Date().toISOString();
		setCurRow({
			...CurRow, State: ((CurRow?.State ?? 0) & m.STATE.Showing) ? ((CurRow?.State ?? 0) & ~m.STATE.Showing) : ((CurRow?.State ?? 0) | m.STATE.Showing)
			, Selected: true, CheckDate: strNow, BuyDate: CurRow?.BuyDate ?? strNow, SellDate: undefined
		} as IUData);
	}
	const btnStore = () => {
		if (!!!CurRow) return;
		setCurRow({ ...CurRow, Store: ((store == CurRow?.Store) ? CurRow?.Product?.Store?.replace(/(\w+, )*(\w+)$/g, "$2") : store) ?? "" });
	}
	const btnCopy = () => {
		setCProduct(CurRow?.Product);
	}
	const btnPatse = () => {
		const strNow = new Date().toISOString();
		setCurRow({ ...CurRow, Store: cproduct?.Store?.replace(/(\w+, )*(\w+)$/g, "$2") ?? "", Product: cproduct, PId: cproduct?.Id, Selected: true, CheckDate: strNow, BuyDate: CurRow?.BuyDate ?? strNow } as IUData);
	}
	//#endregion
	console.info("render");
	return <Container>
		<Header>
			<_HNav />
		</Header>
		<Content>
			<Row>
				<Col xs={10}>
					<form action="" method="GET">
						<InputGroup>
							<InputGroup.Addon><Icon as={FaBoxes} /></InputGroup.Addon>
							<Input name="Store" defaultValue={store ?? ""} />
							{/* <Input onEnded={} value={_store ?? ""} onChange={set_Store} /> */}
						</InputGroup>
					</form>
				</Col>
				<Col xs={10}>
					<Barcode disabled={(store ?? "") == ""} onManualProduct={OnProduct} onUnit={OnUnit} onProduct={OnProduct} onSerial={OnSerial} />
				</Col>
				<Col xs={4}>
					<ButtonToolbar className="f-right">
						<IconButton icon={<Icon as={FaSave} />} appearance="primary" onClick={submit}>Save</IconButton>
					</ButtonToolbar>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<ButtonToolbar>
						<ButtonGroup>
							<IconButton icon={<Icon as={FaBoxOpen} />} onClick={btnVoid} appearance="primary"></IconButton>
							<IconButton icon={<Icon as={FaQrcode} />} disabled onClick={btnQrcode} color="orange" appearance="primary"></IconButton>
							<IconButton icon={<Icon as={FaMinus} />} onClick={btnDelete} color="red" appearance="primary"></IconButton>
							<IconButton icon={<Icon as={FaStoreAlt} />} onClick={btnShowing} appearance="primary"></IconButton>
						</ButtonGroup>
						<ButtonGroup>
							<IconButton icon={<Icon as={FaStore} />} onClick={btnStore}></IconButton>
							<IconButton icon={<Icon as={FaCopy} />} onClick={btnCopy}></IconButton>
							<IconButton icon={<Icon as={FaClipboard} />} onClick={btnPatse}></IconButton>
						</ButtonGroup>
					</ButtonToolbar>
				</Col>
				<Col xs={4}>
				</Col>
				<Col xs={8}>
					<ButtonToolbar className="f-right">
						<Badge content="#" color="violet">
							<IconButton loading={lBtnMagic} icon={<Icon as={FaMagic} />} onClick={btnMagic}>ReCalStore</IconButton>
						</Badge>
						<IconButton loading={lBtnLoad} icon={<Icon as={FaSync} />} onClick={reload}>Load</IconButton>
					</ButtonToolbar>
				</Col>
			</Row>
			<Table sortColumn={SortColumn} sortType={SortType} onSortColumn={handleSortColumn} data={getData()} onRowClick={onRowSelect} height={30 * 18} headerHeight={30} rowHeight={30} >
				<Column sortable>
					<HeaderCell>Id</HeaderCell>
					<ReadonlyCell dataKey='Id' />
				</Column>
				<Column flexGrow={3} sortable>
					<HeaderCell>Máy</HeaderCell>
					<CompactSpeakerReadonlyCell speaker={(r: IUData) => <img src={Lib.MapPath(`/Content/imgs/thumbnails/250/${r.Product?.Code}.jpg`)} />}>{(r: IUData) =>
						(<span>{(((r?.Product?.State ?? 0) & m.PROD_STATE.ProImg) > 0) && <Icon as={FaImage} />}{(((r?.Product?.State ?? 0) & m.PROD_STATE.StoImg) > 0) && <Icon as={FaBox} />} {r.Product?.Code}</span>)}
					</CompactSpeakerReadonlyCell>
				</Column>
				<Column flexGrow={3}>
					<HeaderCell>Serial</HeaderCell>
					<ReadonlyCell dataKey='Serial' />
				</Column>
				<Column sortable>
					<HeaderCell>Ngày kiểm</HeaderCell>
					<ReadonlyCell dataKey='LastCheck'>{(r: any, v: string) => (v?.replace(/\d{2}(\d{2})-(\d{2})-(\d{2}).*/g, "$3/$2/$1"))}</ReadonlyCell>
				</Column>
				<Column sortable>
					<HeaderCell>Ngày mua</HeaderCell>
					<ReadonlyCell dataKey='BuyDate'>{(r: any, v: string) => (v.replace(/\d{2}(\d{2})-(\d{2})-(\d{2}).*/g, "$3/$2/$1"))}</ReadonlyCell>
				</Column>
				<Column>
					<HeaderCell>Vị trí</HeaderCell>
					<ReadonlyCell dataKey='Store' />
				</Column>
				<Column flexGrow={1}>
					<HeaderCell>TT</HeaderCell>
					<ReadonlyCell dataKey='State'>{(r: any, v: number) => <span>
						{!!(v & m.STATE.Void) && <Icon title="Thùng không" as={FaBoxOpen} />}
						{!!(v & m.STATE.Showing) && <Icon title="Trưng bày" as={FaStoreAlt} />}
						{!(v & m.STATE.AztecPrint) && <Icon title="In lại mã vạch" as={FaQrcode} />}
					</span>}</ReadonlyCell>
				</Column>
				<Column flexGrow={1}>
					<HeaderCell>SKU</HeaderCell>
					<ReadonlyCell dataKey='Product.SKU' />
				</Column>
				<Column>
					<HeaderCell>Ngày bán</HeaderCell>
					<ReadonlyCell dataKey='LastSell'>{(r: any, v: string) => (v?.replace(/\d{2}(\d{2})-(\d{2})-(\d{2}).*/g, "$3/$2/$1"))}</ReadonlyCell>
				</Column>
			</Table>
			<StoreMap stores={store ?? ""} onSelect={(str) => { location.href = "?Store=" + str }} />
		</Content>
		{/* <Footer><Pagination onChangePage={setStore} layout={['pager', 'skip']} activePage={store} total={(MaxPage ?? 1) * RPP} maxButtons={5} limit={RPP} boundaryLinks first last next prev ellipsis /></Footer> */}
	</Container>;
}