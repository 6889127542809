import { Outlet, useNavigate } from "react-router-dom";
import { Container, Content, Header, Nav } from "rsuite";
import { Icon } from "@rsuite/icons";
import { FaDollarSign, FaPrint, FaQrcode, FaTags } from "react-icons/fa";

export function Index() {
    const activeKey = location.pathname.replace(/(\/\w*)(\/\w*)?.*/, "$1$2");
    const nav = useNavigate();
    const handleNav = (eventKey?: string) => {
        nav(eventKey?.replaceAll('*', '') ?? "");
    }
    return <Container>
        <Header>
            <Nav activeKey={activeKey} appearance="tabs" color="#ccc">
                <Nav.Item eventKey="/Print" icon={<Icon as={FaPrint} />} onSelect={handleNav}>Index</Nav.Item>
                <Nav.Item eventKey="/Print/P145" icon={<Icon as={FaQrcode} />} onSelect={handleNav}>Số thùng</Nav.Item>
                <Nav.Item eventKey="/Print/Price" icon={<Icon as={FaDollarSign} />} onSelect={handleNav}>Giá</Nav.Item>
                <Nav.Item eventKey="/Print/SubLable" icon={<Icon as={FaTags} />} onSelect={handleNav}>Nhãn phụ</Nav.Item>
            </Nav>
        </Header>
        <Outlet />
    </Container>
}