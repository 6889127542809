import { Icon } from "@rsuite/icons";
import React, { useState } from "react";
import { FaArrowDown, FaBarcode, FaFileExcel, FaPlus } from "react-icons/fa";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ButtonGroup, ButtonToolbar, Col, Container, Content, Header, IconButton, Input, InputGroup, Pagination, Row, Uploader } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { Db } from "../../c/Db";
import { Lib } from "../../c/Lib";
import { IdRow, ReadonlyCell, setProps } from "../../c/TableHelper";
import { useNumberQueryParam } from "../../c/UrlHelper";
import { m } from "../../m/Model";
import { _SearchTags } from "../Shared/_SearchTags";
import { _TagsPicker } from "../Shared/_TagsPicker";
import { FileType } from "rsuite/esm/Uploader";
const RPP = 17;
interface IData extends m.EcProduct, IdRow { }
export function Index(props: any) {
	//#region const
	const params = useParams<{ ptype: string, Id: string }>();
	const [Loading, setLoading] = useState<boolean>(false);
	const [PageNo, setPageNo] = useNumberQueryParam("P");
	const [MaxPage, setMaxPage] = useNumberQueryParam("MP");
	const [searchParams, setSearchParams] = useSearchParams();
	const [SearchForm, setSearchForm] = useState({
		Query: searchParams.get("Query") ?? "All",
		Q: searchParams.get("Q") ?? "",
		TIds: searchParams.get("TIds")?.split(",").map(x => Number(x)),
		ExTIds: searchParams.get("ExTIds")?.split(",").map(x => Number(x)),
	});
	const [Rows, setRows] = React.useState<IData[]>([]);
	const [CurRow, setCurRow] = React.useState<IData>();
	const [ExpandedRowKeys, setExpandedRowKeys] = React.useState<number[]>();
	const [Tags, setTags] = useState<m.Tag[]>([]);
	const [TIds, setTIds] = useState<number[]>([]);
	const [PriceLog, setPriceLog] = useState<[string, number, number, number, number, number][]>();
	const [MinPrice, setMinPrice] = useState<number>(0);
	const [FileList, setFileList] = useState([]);
	const [LoadingCode, setLoadingCode] = useState<boolean>(false);
	const nav = useNavigate();
	//#endregion
	//#region function
	const loadTags = () => {
		Db.All<m.Tag>("Tags").then(rs => setTags(rs.sort((a, b) => a.TreeKeys.localeCompare(b.TreeKeys))));
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows.find(x => x.Id == CurRow.Id))
			setRows([CurRow, ...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))]);
		else
			setRows(Rows.map(x => x.Id == CurRow.Id ? CurRow : { ...x, Selected: undefined, Editing: undefined }));
	}, [CurRow]);
	React.useEffect(() => {
		console.debug("select PricesLog", Rows.filter(x => x.Selected).map(x => x.Id));
		setExpandedRowKeys(Rows.filter(x => x.Selected).map(x => x.Id));
	}, [Rows]);
	React.useEffect(() => {
		setLoading(true);
		Lib.Get(`/API/EcProducts/All`, "load EcProducts", async (rs) => {
			const dat = await rs.json() as IData[];
			setLoading(false);
			setRows(dat.map(x => ({ ...x })));
			// loadTags();
			// if (!!!MaxPage) {
			// 	Lib.Get(`/API/Products/All?ptype=${ptype}&p=0${searchStr}`, "load Max Page Orders", async (rs) => {
			// 		setMaxPage(Math.ceil((await rs.json() as number) / RPP));
			// 	});
			// }
		});
	}, [PageNo, searchParams]);
	//#endregion effect
	//#region event
	const onRowSelect = (_row: any, evt: React.MouseEvent) => {
		const row = _row as IData;
		if (evt.ctrlKey) {
			nav(`/EcProducts/Detail/${row.Id}`);
			// } else if (evt.ctrlKey) {
			// 	nav(`/Products/${row.Catalog == m.CATALOG.Machine ? "Units" : "Stores"}/${row.Id}`);
			// } else if (evt.altKey) {
			// 	nav(`/Products/${row.Id}`);
		} else {
			setCurRow({ ..._row, Selected: true, Editing: true });
		}
	}
	const onCusRowChange = (rowData: any, dataKey: string, value: string) => {
		setProps(dataKey, rowData, value);
		setCurRow(rowData as IData);
	}
	//#endregion
	//#region btn
	const btnAdd = () => {
		Lib.Post(`/API/EcProducts/Add`, undefined, undefined, async (rs) => {
			const p = await rs.json() as IData;
			setRows([{ ...p, Selected: true }, ...Rows]);
		});
	}
	const btnOpenUnits = () => {
		if (!!!CurRow) return;
		nav(`/Products/Units/${CurRow.Id}`);
	}
	const btnOpenStore = () => {
		if (!!!CurRow) return;
		nav(`/Products/Stores/${CurRow.Id}`);
	}
	const btnOpenDetails = () => {
		if (!!!CurRow) return;
		nav(`/Products/ODetails/${CurRow.Id}`);
	}
	// const btnExport = () => {
	// 	window.open(`/Products/ExportShopee`, "_bank");
	// }
	const btnExport = (str: string) => {
		window.open(str, "_bank");
	}
	const onShopeeUploadChange = (fileList: FileType[]) => {
		var file = fileList[0];
		console.info(file);
		var bOut = false;
		var url = `/Products/ImportShopee`;
		var fileName = ``;
		if (file.name?.startsWith("mass_update_basic_info_")) {
			bOut = true;
			url = `/Products/ImportShopee_BasicInfo`;
			fileName = `Shopee_BasicInfo.xlsx`;
		} else if (file.name?.startsWith("mass_update_media_info_")) {
			bOut = true;
			url = `/Products/ImportShopee_MediaInfo`;
			fileName = `Shopee_MediaInfo.xlsx`;
		} else if (file.name?.startsWith("mass_update_sales_info_")) {
			bOut = true;
			url = `/Products/ImportShopee_SalesInfo`;
			fileName = `Shopee_SalesInfo.xlsx`;
		} else if (file.name?.startsWith("mass_update_shipping_info_")) {
			bOut = true;
			url = `/Products/ImportShopee_ShippingInfo`;
			fileName = `Shopee_ShippingInfo.xlsx`;
		}
		if (bOut) {
			var fd = new FormData();
			fd.append("File", file.blobFile as File, file.name);
			Lib.Upload(url, fd, "Uploading", async (rs) => {
				var blob = await rs.blob();
				var file = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				// window.open(file,"_bank",'download=ImportShopee_BasicInfo.xlsx');
				a.href = file;
				a.download = fileName;
				a.click();
				document.removeChild(a);
				// window.location.assign(file);
			});
		}
		setFileList([]);
		return bOut;
	}
	const onLazadaUploadChange = (fileList: FileType[]) => {
		var file = fileList[0];
		console.info(file);
		var bOut = false;
		var url = `/Products/ImportLazada`;
		var fileName = ``;
		if (file.name?.startsWith("skuimg")) {
			bOut = true;
			url = `/Products/ImportLazada_Img`;
			fileName = `Lazada_Img.xlsx`;
		}
		// else if (file.name?.startsWith("mass_update_media_info_")) {
		// 	bOut = true;
		// 	url = `/Products/ImportShopee_MediaInfo`;
		// 	fileName = `Shopee_MediaInfo.xlsx`;
		// } else if (file.name?.startsWith("mass_update_sales_info_")) {
		// 	bOut = true;
		// 	url = `/Products/ImportShopee_SalesInfo`;
		// 	fileName = `Shopee_SalesInfo.xlsx`;
		// } else if (file.name?.startsWith("mass_update_shipping_info_")) {
		// 	bOut = true;
		// 	url = `/Products/ImportShopee_ShippingInfo`;
		// 	fileName = `Shopee_ShippingInfo.xlsx`;
		// }
		if (bOut) {
			var fd = new FormData();
			fd.append("File", file.blobFile as File, file.name);
			Lib.Upload(url, fd, "Uploading", async (rs) => {
				var blob = await rs.blob();
				var file = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				// window.open(file,"_bank",'download=ImportShopee_BasicInfo.xlsx');
				a.href = file;
				a.download = fileName;
				a.click();
				document.removeChild(a);
				// window.location.assign(file);
			});
		}
		setFileList([]);
		return bOut;
	}
	//#region Hotkeys
	// useHotkeys("alt+r", reload);
	// useHotkeys("alt+t", btnAddTag);
	// useHotkeys("alt+r", btnReTags);
	//#endregion
	//#region Query dropdown
	const miAll = () => {
		setSearchForm({ ...SearchForm, Query: "" });
	}
	const miP = () => {
		setSearchForm({ ...SearchForm, Query: "P" });
	}
	const EnterCode = (code: string) => {
		setLoadingCode(true);
		var b = !!!code;
		b ||= !/\d{12}/.test(code);
		if (b) {
			setLoadingCode(false); return;
		}
		var _CODE = code.toUpperCase()
			.replace("HTTP://", "")
			.replace("CODE.LANANHPT.COM/", "")
			.replaceAll("@", "");///barcode witch space
		Lib.Get(`/API/Products/Scan?code=${_CODE}`, "Load m.Product", async (rs) => {
			setLoadingCode(false);
			const p = await rs.json() as m.Product;
			nav(`/Products/Stores/${p.Id}`);
		});
	}
	//#endregion
	console.info("render", PageNo);
	return <Container>
		<Header>
			<Outlet />
		</Header>
		<Content>
			<Row>
				<Col sm={10} xl={8} xxl={5}>
					<ButtonToolbar aria-expanded={false}>
						<IconButton onClick={btnAdd} icon={<Icon as={FaPlus} />} appearance="primary" color="blue" title="Thêm mới"></IconButton>
						{/* <IconButton onClick={btnCopy} icon={<Icon as={FaCopy} />} title="Copy">Copy</IconButton> */}
						{/* <ButtonGroup>
							{CurRow?.Catalog == m.CATALOG.Machine
								? <IconButton onClick={btnOpenUnits} disabled={!!!CurRow} icon={<Icon as={FaBox} />} appearance="primary" color="orange" title="Ctrl+click">Số thùng</IconButton>
								: <IconButton onClick={btnOpenStore} disabled={!!!CurRow} icon={<Icon as={FaArchive} />} appearance="primary" color="blue" title="Ctrl+click">Vị trí</IconButton>}
							<IconButton onClick={btnOpenDetails} disabled={!!!CurRow} icon={<Icon as={FaHistory} />} title="Alt+click">Lịch sử</IconButton>
						</ButtonGroup> */}
					</ButtonToolbar>
				</Col>
				<Col sm={14} xl={16} xxl={19}>
					{/* <Form onSubmit={onSubmit}>
						<Col sm={3} xl={2}>
							<Dropdown title={SearchForm.Query ?? "All"}>
								<Dropdown.Item onClick={miAll}>Tất cả</Dropdown.Item>
								<Dropdown.Item onClick={miP}>Cần mua</Dropdown.Item>
							</Dropdown>
						</Col>
						<Col sm={20} xl={14}>
							<_SearchTags onChange={(val, ids) => setSearchForm({ ...SearchForm, Q: val ?? "", TIds: ids })} onSubmit={onSubmit}>
							</_SearchTags>
						</Col>
						<Col sm={20} xl={7}>
							<_SearchTags lastOnly icon={<><Icon className='bg' color='red' as={FaSlash} /><Icon className='fg' as={FaTags} /></>} onChange={(val, ids) => setSearchForm({ ...SearchForm, Q: val ?? "", ExTIds: ids })} onSubmit={onSubmit}>
							</_SearchTags>
						</Col>
						<InputGroupButton type="submit"><Icon as={FaSearch} /></InputGroupButton>
					</Form> */}
				</Col>
				<Col smHidden mdHidden xlHidden xxl={8}>
					<ButtonToolbar aria-expanded={false}>
						{/* <ButtonGroup>
							<IconButton onClick={btnAddTag} title="alt+t" icon={<Icon as={FaPlus} />}></IconButton>
							<IconButton onClick={btnGetTags} icon={<Icon as={FaTags} />}>Tags</IconButton>
							<IconButton onClick={btnReTags} title="alt+r" icon={<Icon as={FaUserTag} />}></IconButton>
							<IconButton onClick={btnRemveTag} icon={<Icon as={FaMinus} />}></IconButton>
						</ButtonGroup> */}
						<ButtonGroup>
							<Uploader fileList={FileList} draggable onChange={onShopeeUploadChange} fileListVisible={false} action="/" autoUpload={false}>
								<IconButton icon={<Icon as={FaFileExcel} />}>Shopee</IconButton>
							</Uploader>
							<IconButton onClick={() => btnExport(`/ProductEc/ExportShopee`)} icon={<><Icon className='bg' color='violet' as={FaArrowDown} /><Icon className='fg' as={FaPlus} /></>}></IconButton>
						</ButtonGroup>
						<ButtonGroup>
							<Uploader fileList={FileList} draggable onChange={onLazadaUploadChange} fileListVisible={false} action="/" autoUpload={false}>
								<IconButton icon={<Icon as={FaFileExcel} />}>Lazada</IconButton>
							</Uploader>
							<IconButton onClick={() => btnExport(`/Products/ExportLazada`)} icon={<><Icon className='bg' color='red' as={FaArrowDown} /><Icon className='fg' as={FaPlus} /></>}></IconButton>
						</ButtonGroup>
					</ButtonToolbar>
				</Col>
				{/* <Col smHidden mdHidden xlHidden xxl={16}>
					<Col xxl={12}>
						<_TagsPicker value={SearchForm.TIds} onClean={() => { setTIds([]); setSearchForm({ ...SearchForm, TIds: [] }) }} onSelect={val => { setTIds(val); setSearchForm({ ...SearchForm, TIds: val }) }} />
					</Col>
					<Col xxl={12}>
						<InputGroup>
							<InputGroup.Button loading={LoadingCode}>
								<Icon as={FaBarcode} />
							</InputGroup.Button>
							<Input onChange={EnterCode}></Input>
						</InputGroup>
					</Col>

				</Col> */}
			</Row>
			<Pagination onChangePage={setPageNo} layout={['pager', 'skip']} activePage={PageNo ?? 1} total={(MaxPage ?? 1) * RPP} maxButtons={5} limit={RPP} boundaryLinks first last next prev ellipsis />
			<Table className="Products" loading={Loading} data={Rows ?? []} onRowClick={onRowSelect} height={30 * 18 + 270} headerHeight={30} rowHeight={30} rowExpandedHeight={300} rowKey={"Id"} expandedRowKeys={ExpandedRowKeys} >
				{/* <Column align="right">
					<HeaderCell>Mã</HeaderCell>
					<CompactSpeakerReadonlyCell speaker={(r: IData) => <img src={Lib.MapPath(`/Content/imgs/thumbnails/250/${r.Code}.jpg`)} />}>{(r: IData) => r.Code}
					</CompactSpeakerReadonlyCell>
				</Column> */}
				<Column flexGrow={4}>
					<HeaderCell>Sản phẩm</HeaderCell>
					<ReadonlyCell dataKey='OnlineName' />
				</Column>
				<Column align="right" flexGrow={2}>
					<HeaderCell>SL</HeaderCell>
					<ReadonlyCell dataKey='Products'>{(r: IData, v: m.Product[]) => { v?.length ?? 0 }}</ReadonlyCell>
				</Column>
				<Column flexGrow={4}>
					<HeaderCell>Shopee</HeaderCell>
					<ReadonlyCell dataKey='ShopeeId' />
				</Column>
				<Column flexGrow={4}>
					<HeaderCell>Lazada</HeaderCell>
					<ReadonlyCell dataKey='LazadaId' />
				</Column>
			</Table>
		</Content>
	</Container>
}