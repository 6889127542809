import { Icon, Speaker } from "@rsuite/icons";
import React, { useState } from "react";
import { FaBoxes, FaImage, FaMinus, FaSave, FaSync } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, ButtonToolbar, Col, Container, Content, Header, IconButton, Input, InputGroup, Row, Tag, Whisper } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { Lib } from "../../c/Lib";
import { NumCell, ReadonlyCell, shortDateY } from "../../c/TableHelper";
import { useQueryParam } from "../../c/UrlHelper";
import { m } from "../../m/Model";
import { Barcode } from "../Shared/_Barcode";
import { _HNav } from "../Products/_HNav";
import { StoreMap } from "./StoreMap";
import { isValid } from "rsuite/esm/utils/dateUtils";
const RPP = 17;
interface IData extends m.Store {
	Editing?: boolean;
	Selected?: boolean;
	Expanded?: boolean;
}
export function Products(props: any) {
	//#region const
	//const [Id, setId] = useQueryParam<number>("Id");
	const [SId, setSId] = useQueryParam<string>("SId");
	const [Product, setProduct] = React.useState<m.Product | undefined>();
	const [Rows, setRows] = React.useState<IData[]>([]);
	const [CurRow, setCurRow] = React.useState<IData>();
	const [ExpandedRowKeys, setExpandedRowKeys] = React.useState<number[]>();
	const [SortType, setSortType] = React.useState<'desc' | 'asc'>('desc');
	const [SortColumn, setSortColumn] = React.useState<string>("CheckDate");
	const nav = useNavigate();
	//#endregion
	//#region function
	const submit = () => {
		Lib.Post(`/API/Stores/UpdateAll?force=true`, JSON.stringify(Rows.map(x => ({ ...x, Product: undefined }))), "Update All Stores", async (rs) => {
			reload();
		});
	}
	const reload = () => {
		if (!!!SId) {
			return;
		}
		setlBtnLoad(true);
		Lib.Get(`/API/Stores/BySId?SId=${SId}`, "load Stores", async (rs) => {
			const dat = await rs.json() as IData[];
			setRows((dat ?? []).map(x => ({ ...x })));
			setlBtnLoad(false);
		});
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows.find(x => x.PId == CurRow.PId))
			setRows([CurRow, ...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))]);
		else
			setRows(Rows.map(x => x.PId == CurRow.PId ? CurRow : { ...x, Selected: undefined, Editing: undefined }));
		//setId(CurRow.Id, { replace: true, state: "" });
		//nav(`/Products/Store?P=${PageNo}&MP=${MaxPage}&Id=${CurRow.Id}`);
		setExpandedRowKeys([CurRow.PId]);
	}, [CurRow]);
	React.useEffect(() => {
		document.title = `Kiểm phụ tùng ${SId}`;
		if (!!!SId) return;
		//set_Store(store);
		reload();
	}, [SId]);
	const getData = () => {
		if (SortColumn && SortType) {
			return Rows.sort((a, b) => {
				let x = (a as any)[SortColumn];
				let y = (b as any)[SortColumn];
				if (typeof x === 'number') {
					if (SortType === 'asc') {
						return x - y;
					} else {
						return y - x;
					}
				} else {
					return (SortType === 'asc') ? (x ?? "").localeCompare(y) : (y ?? "").localeCompare(x);
				}
			});
		}
		return Rows;
	};
	//#endregion effect
	//#region event
	const [lastClick, setLastClick] = useState(0);
	const onRowSelect = (_row: any, evt: React.MouseEvent) => {
		const row = _row as IData;
		if (Date.now() - lastClick < 400) {
			btnProduct(evt, row);
			return;
		}
		if (row.Editing) return;
		setCurRow({ ...row, Selected: true, Editing: true });
		setLastClick(Date.now());
	}
	const OnProduct = (product?: m.Product) => {
		if (!!!product) return;
		const strNow = new Date().toISOString();
		var r = CurRow;
		if (r?.PId != product.Id)
			r = Rows.find(x => x.PId == product.Id);
		if (!!!r)
			r = { ...m.DEFAULT.Store };
		setCurRow({ ...r, Product: product, PId: product.Id, SId: SId, Amount: Number(r.Amount) + 1, Selected: true, CheckDate: strNow } as IData);
	};
	const handleSortColumn = (sortColumn: any, sortType: any) => {
		setSortColumn(sortColumn);
		setSortType(sortType);
	};
	//#endregion
	//#region btn
	const [lBtnLoad, setlBtnLoad] = React.useState(false);
	const [lBtnMagic, setlBtnMagic] = React.useState(false);
	const btnDelete = () => {
		setRows(Rows.filter(x => x.SId != CurRow?.SId));
	}
	const btnProduct = (evt: React.MouseEvent, r: IData) => {
		if (evt.altKey && evt.ctrlKey)
			btnSetSId(r);
		else if (evt.altKey)
			window.open(`/Products/ODetails/${r.PId}`, "_obank");
		else {
			window.open(`/Products/${r.Product?.Catalog == m.CATALOG.Machine ? "Units" : "Stores"}/${r.PId}`, "_bank");
		}
		//
	}
	// const [NSId, setNSId] = useState("");
	const [NSId, setNSId] = useQueryParam<string>("NSId");
	const btnSetSId = (r: IData) => {
		if ((NSId ?? "") == "") return;
		if (confirm(`Chuyển ${r.Amount} x [${r.Product.Code}] ${r.Product.Name} tới ${NSId} Và Xóa ${r.SId}`))
			Lib.Post(`/API/Stores/SetSId?NSId=${NSId}&del=1`, JSON.stringify(r), 'Set SID');
	}
	const onRowChange = (dat: IData) => {
		setCurRow(dat);
	}
	// const btnCopy = () => {
	// 	setProduct(CurRow?.Product);
	// }
	// const btnPatse = () => {
	// 	const strNow = new Date().toISOString();
	// 	setCurRow({ ...CurRow, Product: m.Product, PId: m.Product?.Id, Selected: true, CheckDate: strNow } as IData);
	// }
	//#endregion
	console.info("render");
	const renderRowExpanded = (rowData: any) => {
		const dat = rowData as IData;
		const s = dat as m.Store;
		const p = s.Product;
		console.debug("load PricesLog", p.Id);
		if (!!!p)
			return <div></div>;
		return (
			<Row>
				<img src={Lib.MapPath(`/Content/imgs/thumbnails/250/${p.Code}.jpg`)} />
				{((p.State & m.PROD_STATE.ProImg) > 0 && <Icon as={FaImage} />)}{p.ImgCount}<br />
				{p.Name}
			</Row>
		);
	}
	const IsValid = (store: IData) => {
		const dat = store.Product;
		var Out = true;
		Out &&= (dat.Weight > 0);
		Out &&= (dat.W > 0);
		Out &&= (dat.H > 0);
		Out &&= (dat.L > 0);
		Out &&= ((dat.OnlinePrice ?? 0) > 0);
		Out &&= (dat.ImgCount > 0);
		Out &&= dat.Description.length > 300;
		Out ||= (dat.ShopeeId ?? 0) > 0
		return Out;
	}
	return <Container className="Stores_Products">
		<Header>
			<_HNav />
		</Header>
		<Content>
			<Row>
				<Col xs={10}>
					<form action="" method="GET">
						<InputGroup>
							<InputGroup.Addon><Icon as={FaBoxes} /></InputGroup.Addon>
							<Input name="SId" defaultValue={SId ?? ""} />
							{/* <Input onEnded={} value={_store ?? ""} onChange={set_Store} /> */}
						</InputGroup>
					</form>
				</Col>
				<Col xs={10}>
					<Barcode disabled={(SId ?? "") == ""} onManualProduct={OnProduct} onProduct={OnProduct} />
				</Col>
				<Col xs={4}>
					<ButtonToolbar className="f-right">
						<IconButton icon={<Icon as={FaSave} />} appearance="primary" onClick={submit}>Save</IconButton>
					</ButtonToolbar>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<ButtonToolbar>
						<ButtonGroup>
							<IconButton icon={<Icon as={FaMinus} />} onClick={btnDelete} color="red" appearance="primary"></IconButton>
						</ButtonGroup>
						{/* <ButtonGroup>
							<IconButton icon={<Icon as={FaCopy} />} onClick={btnCopy}></IconButton>
							<IconButton icon={<Icon as={FaClipboard} />} onClick={btnPatse}></IconButton>
						</ButtonGroup> */}
					</ButtonToolbar>
				</Col>
				<Col xs={4}>
					{/* <Input value={NSId} onChange={setNSId} /> */}
				</Col>
				<Col xs={8}>
					<ButtonToolbar className="f-right">
						<IconButton loading={lBtnLoad} icon={<Icon as={FaSync} />} onClick={reload}>Load</IconButton>
					</ButtonToolbar>
				</Col>
			</Row>
			<Table sortColumn={SortColumn} sortType={SortType} onSortColumn={handleSortColumn} data={getData()} onRowClick={onRowSelect} height={30 * 18} headerHeight={30} rowHeight={30} rowKey={"PId"} expandedRowKeys={ExpandedRowKeys} renderRowExpanded={renderRowExpanded}>
				{SId ?? <Column sortable>
					<HeaderCell>Id</HeaderCell>
					<ReadonlyCell dataKey='Id' />
				</Column>}
				<Column>
					<HeaderCell>Mã</HeaderCell>
					<ReadonlyCell dataKey='Product.Code'>
						{(r: IData, v: string) => <Tag color={IsValid(r) ? undefined : "red"} onClick={(evt: React.MouseEvent) => btnProduct(evt, r)}>{v}</Tag>}
					</ReadonlyCell>
				</Column>
				<Column flexGrow={1}>
					<HeaderCell>Tên</HeaderCell>
					<ReadonlyCell dataKey='Product.Name' />
				</Column>
				<Column align="right" flexGrow={1}>
					<HeaderCell>Thay đổi</HeaderCell>
					<ReadonlyCell dataKey='Product.Amount'>{(r: IData, v: number) => <Tag color={(r.CheckDate ?? "").localeCompare(r.Product.CheckDate ?? "") < 0 ? undefined : r.Amount - v > 0 ? "blue" : r.Amount - v == 0 ? "violet" : "red"}>{r.Amount - v}</Tag>}</ReadonlyCell>
				</Column>
				<Column align="right" flexGrow={1}>
					<HeaderCell>Số lượng</HeaderCell>
					<NumCell dataKey='Amount' onRowChange={onRowChange} />
				</Column>
				<Column align="right" flexGrow={2}>
					<HeaderCell>Ngày kiểm</HeaderCell>
					<ReadonlyCell>{(r: IData) => shortDateY(r.CheckDate)}</ReadonlyCell>
				</Column>
			</Table>
			<StoreMap stores={SId ?? ""} onSelect={(str) => { location.href = "?SId=" + str }} />
		</Content>
		{/* <Footer><Pagination onChangePage={setStore} layout={['pager', 'skip']} activePage={store} total={(MaxPage ?? 1) * RPP} maxButtons={5} limit={RPP} boundaryLinks first last next prev ellipsis /></Footer> */}
	</Container >;
}