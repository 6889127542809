import { stringToObject } from "rsuite/esm/utils";
export namespace m {
	export interface Customer {
		Phone: number;
		ShortName: string;
		Name: string;
		TaxId?: string;
		TaxName?: string;
		TaxPhone?: string;
		Address?: string;
		Balance: number;
		LastTransfer: number;
		BalanceUpdate?: string;
		Si: boolean;
		Ck: number;
		ceil: boolean;
	}
	export interface Product {
		Id: number;
		NId?: number;
		Name: string;
		PNames: string;
		OnlineName: string;
		Code: string;
		SKU: string;
		PCSinPKG: number;
		Store: string;
		Description: string;
		TagsCache: string;
		TagsId: number[];
		Weight: number;
		W: number;
		L: number;
		H: number;
		OrderAmount: number;
		Amount: number;
		CheckDate: string;
		CheckAmount: number;
		BuyPrice: number;
		Price2: number;
		OnlinePrice?: number;
		FPrice?: number;
		Price: number;
		KMPrice: number;
		SellRate?: number;
		ShopeeId?: number;
		LazadaId?: number;
		EcId?: number;
		EcSubNo?: number;
		EcSubName: string;
		Keywords: string;
		Catalog: CATALOG;
		Priority: number;
		State: PROD_STATE;
		ImgCount: number;
		LocalImgCount: number;
		SiCount: number;
		//#region js
		PreAmount?: number;
		PreCheckDate?: string;
		//#endregion
		PCS_Id?: number;
	}

	export enum CATALOG {
		Prouduct = 0,
		Machine = 1,
		SparePart = 2,
		Accessory = 3,
		SparePart_Accessory = 4
	}

	export enum PROD_STATE {
		None = 0,
		SpareParts = 1,
		SKU = 1 << 1,
		PKG_SKU = 1 << 2,
		Imgs = 1 << 3,
		Web = 1 << 4,
		SI = 1 << 6,
		StoImg = 1 << 7,
		ProImg = 1 << 8,
		Deprecated = 1 << 9,
		DeprecatedSp = 1 << 10
	}
	export interface EcProduct {
		Id: number;
		OnlineName: string;
		ShopeeId?: number;
		LazadaId?: number;
		TikiId: string;
		TikiCatalog: string;
		TagsCache: string;
		TagsId: number[];
		Products: Product[];
	}
	/**
	 * 
	 */
	export interface Tag {
		ID: number;
		UName: string;
		Name: string;
		DlStyle: STYLE;
		IsMDisplay: boolean;
		IsSpDisplay: boolean;
		IsAsDisplay: boolean;
		TreeKeys: string;
		PID?: number;
	}

	export interface Store {
		PId: number;
		Product: Product;
		SId: string;
		Amount: number;
		CheckDate?: string;
		//#region js
		Change?: number;
		PreAmount?: number;
		//#endregion
	}

	export enum STYLE {
		DEFAULT = 0,
		PRIMARY = 1,
		SECONDARY = 2,
		BG_DEFAULT = 3,
		BG_PRIMARY = 4,
		BG_SECONDARY = 5,
		HIDDEN = 6
	}
	export interface SparePart {
		PId: number;
		SPId: number;
		Part: Product;
		PartNo: number;
		illCode: string;
		Quantity: number;
	}
	export interface Order {
		Id: number;
		Otype: OTYPE;
		PName?: string;
		TName?: string;
		CId?: number;
		Customer?: Customer;
		PId?: number;
		Product?: Product;
		OrderDetails: OrderDetail[];
		Summary?: string;
		Total?: number;
		CreateDate?: string;
		CompleteDate?: string;
		ShipedDate?: string;
		PayDate?: string;
		UpdatePrice?: boolean;
		isTransfer?: boolean;
		TId?: number;
	}

	export enum OTYPE {
		none = -1,
		buy = 0,
		fix = 1,
		sell = 2,
		sell2 = 3
	}
	export interface OrderDetail {
		Id: number;
		OId: number;
		OType: OTYPE;
		PId?: number;
		Store: string;
		Product?: Product;
		Units: Unit[];
		BuyUnits: Unit[];
		Short: string;
		Text: string;
		Quantity: number;
		Price: number;
		OrderQuantity?: number;
		State: OD_STATE;
		Order?: Order;
	}

	export enum OD_STATE {
		None = 0,
		Geted = 1 << 0
	}

	export interface Unit {
		Id: number;
		PId?: number;
		BuyODId?: number;
		ODId?: number;
		Product?: Product;
		Price: number;
		BuyPrice: number;
		Serial: string;
		Store: string;
		Description: string;
		Note?: string;
		Provider: string;
		CheckDate?: string;
		SellDate?: string;
		BuyDate?: string;
		State: STATE;
	}

	export enum STATE {
		None = 0,
		AztecPrint = 1 << 0,
		WCPrint = 1 << 1,
		Delete = 1 << 2,
		MissingInfo = 1 << 3,
		Void = 1 << 4,
		Showing = 1 << 5,
	}

	export interface PName {
		Code: string;
		Count: number;
		PId?: number;
		BSI: string;
		State?: PROD_STATE;
	}
	export interface Parameter {
		Code: string;
		Short: string;
		Price: number;
		PId?: number;
	}
	export interface JParam {
		Code?: string;
		Short: string;
		Text: string;
		label?: string;
		Price?: number;
		PId?: number;
	}
	export const DEFAULT = {
		Customer: {
			Phone: 0, ShortName: "", Name: "",
			Balance: 0,
			Si: false,
			Ck: 0,
			ceil: false
		} as Customer,
		Order: {} as Order,
		OrderDetail: { OrderQuantity: 1, Units: [] as Unit[] } as OrderDetail,
		Product: {} as Product,
		Unit: { Id: 0, Price: 0, BuyPrice: 0, Serial: "", Store: "", State: m.STATE.None, Description: "", Provider: "" } as Unit,
		JParam: { Short: "", Text: "" } as JParam,
		Tag: {} as Tag,
		Store: { Amount: 0 } as Store
	}
	export function OTYPE2Char(otype?: OTYPE): string {
		switch (otype) {
			case OTYPE.buy: return "M";
			case OTYPE.fix: return "C";
			case OTYPE.sell: return "L";
			case OTYPE.sell2: return "S";
		}
		return "";
	}
	export function Char2OTYPE(otype?: string): OTYPE {
		switch (otype) {
			case "M": return OTYPE.buy;
			case "C": return OTYPE.fix;
			case "L": return OTYPE.sell;
			case "S": return OTYPE.sell2;
		}
		return OTYPE.none;
	}
	export function OIdStr(order?: Order): string {
		return `${order?.Id ?? 0}`.replace(/\d(?=(\d{4})+$)/g, `$&${OTYPE2Char(order?.Otype)} `);
	}
	export function GetOId(str?: string): number {
		str = (str ?? "").replace(/(\d*)\w? ?(\d*)/, "$1$2");
		return new Number(str) as number;
	}
	export function GetOType(str?: string): OTYPE {
		str = (str ?? "").replace(/\d*(\w) ?\d*/, "$1");
		return Char2OTYPE(str);
	}

	export interface Changelog {
		Id: TableNames;
		Date?: string;
		ODate?: string;
	}

	export enum TableNames {
		Db = 1 << 0,
		Tag = 1 << 1,
		TagProduct = 1 << 2,
		Product = 1 << 3
	}
	export interface PriceLog {
		PId: number;
		Update: string;
		BuyPrice: number;
		Price2: number;
		Price: number;
		OnlinePrice: number;
		FPrice?: number;
	}
}