import { Icon } from "@rsuite/icons";
import { FaArchive, FaBoxes, FaCogs, FaDollarSign, FaPlug, FaRegObjectGroup, FaToolbox } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Nav } from "rsuite";

export function _HNav() {
    const activeKey = location.pathname.replace(/(\/\w*)(\/\w*)?.*/, "$1$2");
    const nav = useNavigate();
    const handleNav = (eventKey?: string) => {
        nav(eventKey?.replaceAll('*', '') ?? "");
    }
    return <Nav activeKey={activeKey} appearance="tabs">
        <Nav.Item eventKey="/Products/May" icon={<Icon as={FaPlug} />} onSelect={handleNav}>Máy</Nav.Item>
        <Nav.Item eventKey="/Products/PtPk" icon={<Icon as={FaDollarSign} />} onSelect={handleNav}>Bản giá</Nav.Item>
        <Nav.Item eventKey="/Products/SI" icon={<Icon as={FaRegObjectGroup} />} onSelect={handleNav}>SI</Nav.Item>
        <Nav.Item eventKey="/Stores/Units" icon={<Icon as={FaBoxes} />} onSelect={handleNav}>Kiểm kho Máy</Nav.Item>
        <Nav.Item eventKey="/Stores/BySId" icon={<Icon as={FaArchive} />} onSelect={handleNav}>Kiểm kho pt/pk</Nav.Item>
    </Nav>
}