import { Icon } from "@rsuite/icons";
import React, { useState } from "react";
import { FaCalendar, FaCheckSquare, FaDolly, FaMoneyBill, FaPlus, FaSearch, FaShoppingBag, FaShoppingCart, FaTruck, FaUsersCog, FaWrench } from "react-icons/fa";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Affix, AutoComplete, ButtonGroup, ButtonToolbar, Col, Container, Content, Footer, Grid, Header, IconButton, Input, InputGroup, Nav, Navbar, Pagination, Row } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { Lib } from "../../c/Lib";
import { IdRow, ReadonlyCell, shortDate } from "../../c/TableHelper";
import { useQueryParam } from "../../c/UrlHelper";
import { m } from "../../m/Model";
import { Barcode } from "../Shared/_Barcode";
import { loadPNames } from "../Shared/_PName";
import { _HNav } from "./_HNav";
const RPP = 17;
interface IData extends m.Order, IdRow { }
export function Index(props: any) {
	//#region const
	const params = useParams<{ otype: string, Id: string }>();
	const [PageNo, setPageNo] = useQueryParam<number>("P");
	const [MaxPage, setMaxPage] = useQueryParam<number>("MP");
	const [Q, setQ] = useQueryParam<string>("Q");
	const [StateQ, setStateQ] = useState("");
	const [Rows, setRows] = React.useState<IData[]>([]);
	const [CurRow, setCurRow] = React.useState<IData>();
	const [Loading, setLoading] = React.useState<boolean>(false);
	const activeKey = location.pathname.replace(/(\/\w*)(\/\w*)?.*/, "$1$2");
	const nav = useNavigate();
	//#endregion
	//#region function
	//#endregion
	//#region effect
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows.find(x => x.Id == CurRow.Id))
			setRows([CurRow, ...(Rows.map(x => ({ ...x, Selected: undefined } as IData)))]);
		else
			setRows(Rows.map(x => x.Id == CurRow.Id ? CurRow : { ...x, Selected: undefined }));
		// setId(CurRow.Id, { replace: true, state: "" });
		//nav(`./Details/${CurRow.Id}`);
		nav(`/Orders/${CurRow.Otype == m.OTYPE.buy ? "Mua" :
			CurRow.Otype == m.OTYPE.sell ? "BanLe" :
				CurRow.Otype == m.OTYPE.sell2 ? "BanSi" : "SuaChua"}/Details/${CurRow.Id}`);
	}, [CurRow]);
	React.useEffect(() => {
		if (!!params.Id) {
			return;
		}
		if (!!!PageNo) {
			setPageNo(1);
			return;
		}
		setLoading(true);
		const otype = params.otype == 'Mua' ? 0 :
			params.otype == 'BanSi' ? 3 :
				params.otype == 'BanLe' ? 2 : 1;
		Lib.Get(`/API/Orders/GetAll?otype=${otype}&p=${PageNo ?? 1}${Q ? "&q=" + Q : ""}`, "load Orders", async (rs) => {
			const dat = await rs.json() as IData[];
			setLoading(false);
			setRows(dat);
			if (!!!MaxPage) {
				Lib.Get(`/API/Orders/GetAll?otype=${otype}&p=0${Q ? "&q=" + Q : ""}`, "load Max Page Orders", async (rs) => {
					setMaxPage(Math.ceil((await rs.json() as number) / RPP), { replace: true });
					loadPNames(true);
				});
			}
		});
	}, [PageNo, params.otype, Q]);//, Id
	React.useEffect(() => {
		document.title = params.otype == "Mua" ? "Mua" :
			params.otype == "BanSi" ? "Bán sỉ" :
				params.otype == "BanLe" ? "Bán lẻ" : "Sửa chữa";
	}, [params.otype]);
	//#endregion effect
	//#region event
	const onRowSelect = (_row: any) => {
		const row = _row as IData;
		setCurRow({ ..._row, Selected: true });
	}
	const handleNav = (eventKey?: string) => {
		nav(eventKey?.replaceAll('*', '') ?? "");
	}
	const OnSearchKeyPress = (event: React.KeyboardEvent<Element>) => {
		if (event.key == "Enter") {
			OnSearchClick();
		}
	}
	const OnSearchClick = () => {
		setQ(StateQ);
	}
	//#endregion
	//#region btn
	const btnAdd = () => {
		const otype = params.otype == 'Mua' ? 0 :
			params.otype == 'BanSi' ? 3 :
				params.otype == 'BanLe' ? 2 : 1;
		Lib.Get(`/api/Orders/Add?otype=${otype}`, "Add m.Order", async (rs) => {
			nav(0);
		});
	}
	//#endregion
	// console.info("render");
	return <Container>
		<Header>
			<Outlet />
			<_HNav />
		</Header>
		<Content className="PrintNone">
			<Row>
				<Col xs={6}>
					<ButtonToolbar aria-expanded={false}>
						<IconButton onClick={btnAdd} icon={<Icon as={FaPlus} />} appearance="primary" color="blue">Thêm mới</IconButton>
					</ButtonToolbar></Col>
				<Col xs={6}>
					<Barcode disableSelect onOrder={onRowSelect} defaultValue={Q} onText={(kwd) => setStateQ(kwd ?? "")} onSubmit={OnSearchClick}>
						<InputGroup.Button onClick={OnSearchClick}><Icon as={FaSearch} /></InputGroup.Button>
					</Barcode>
				</Col>
			</Row>
			<Table data={Rows} onRowClick={onRowSelect} loading={Loading} height={30 * 18} headerHeight={30} rowHeight={30} >
				<Column>
					<HeaderCell>Id</HeaderCell>
					<ReadonlyCell dataKey='Id'>{(r: IData, v: any) => m.OIdStr(r)}</ReadonlyCell>
				</Column>
				<Column flexGrow={2}>
					<HeaderCell>Tên Khách</HeaderCell>
					<ReadonlyCell dataKey='Customer.ShortName' className='a-right d-rtl' />
				</Column>
				{params.otype == "SuaChua" && <Column flexGrow={2}>
					<HeaderCell>Máy</HeaderCell>
					<ReadonlyCell dataKey='PName' className='a-right d-rtl' />
				</Column>}
				{params.otype == "SuaChua" && <Column>
					<HeaderCell>T</HeaderCell>
					<ReadonlyCell dataKey='TName' />
				</Column>}
				<Column flexGrow={5}>
					<HeaderCell>Nội dung</HeaderCell>
					<ReadonlyCell dataKey='Summary' />
				</Column>
				{/* <Column>
					<HeaderCell>Cập nhật</HeaderCell>
					<ReadonlyCell dataKey='CreateDate'/>
				</Column> */}
				<Column align="right">
					<HeaderCell>Tổng</HeaderCell>
					<ReadonlyCell dataKey='Total' />
				</Column>
				<Column>
					<HeaderCell>Tình trạng</HeaderCell>
					<ReadonlyCell>
						{(r: IData) => <span title={`Nhận:${shortDate(r.CreateDate)}\nXong:${shortDate(r.CompleteDate)}\nGiao:${shortDate(r.ShipedDate)}\nThu:${shortDate(r.PayDate)}`}>
							<Icon as={FaCalendar} color="#ccc" />
							<Icon as={FaCheckSquare} color={!!!r.CompleteDate ? "#c0c" : "#ccc"} />
							<Icon as={FaShoppingBag} color={!!!r.ShipedDate ? "#00c" : "#ccc"} />
							<Icon as={FaMoneyBill} color={!!!r.PayDate ? "#c00" : "#ccc"} />
						</span>}
					</ReadonlyCell>
				</Column>
			</Table>
		</Content>
		<Footer><Pagination onChangePage={setPageNo} layout={['pager', 'skip']} activePage={(PageNo ?? 1) * 1} total={(MaxPage ?? 1) * RPP} maxButtons={5} limit={RPP} boundaryLinks first last next prev ellipsis /></Footer>
	</Container>;
}