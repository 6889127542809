import React, { useEffect, useState } from "react";
import { Button, Content, Drawer, Input, InputGroup, Loader, Modal, Row, Slider } from "rsuite"
import bwipjs from 'bwip-js';
import { A4 } from "./A4"
import { Lib } from "../../c/Lib";
//interface 
export function P145(props: any) {
    const [skipNo, setSkipNo] = useState<number>(-1);
    const [Id, setId] = useState<number>(0);
    const [isPending, procRender] = React.useTransition();
    React.useEffect(() => {
        Lib.Get(`/API/Units/MaxId`, undefined, async (rs) => {
            const MId = await rs.text() as unknown as number;
            console.info("", MId);
            setId(MId);
            setSkipNo(0);
        })
    }, [props]);
    const [Ids, setIds] = useState<number[][]>([]);
    useEffect(() => {
        var ids: number[][] = [];
        var i = 0;
        for (var y = 0; y < 13; y++) {
            ids.push([]);
            for (var x = 0; x < 5; x++) {
                if (skipNo <= (y * 5 + x)) {
                    ids[y].push(Number(Id) + i);
                    i++;
                } else {
                    ids[y].push(y * 5 + x);
                }
            }
        }
        setIds(ids);
    }, [Id, skipNo]);
    const btnFill = () => {
        Lib.Get(`/API/Units/FillIds?mId=${Id}`, undefined, async (rs) => {
            const Ids = await rs.json() as number[];
            var ids: number[][] = [];
            var i = 0;
            for (var y = 0; y < 13; y++) {
                ids.push([]);
                for (var x = 0; x < 5; x++) {
                    if (skipNo <= (y * 5 + x)) {
                        ids[y].push(Number(Ids[i]));
                        i++;
                    } else {
                        ids[y].push(y * 5 + x);
                    }
                }
            }
            setIds(ids);
        })
    }
    const btnPrint = () => {
        //setSkipNo(skipNo);
        // window.print();
        btnReRender(() => {
            window.print();
        })
    }
    const btnReRender = (callback?: () => void) => {
        procRender(() => {
            try {
                const els = document.querySelectorAll("canvas.aztec") as unknown as HTMLCanvasElement[];
                els.forEach(e => {
                    bwipjs.toCanvas(e, {
                        bcid: 'azteccodecompact',       // Barcode type
                        text: e.getAttribute("about") ?? "",    // Text to encode
                        scale: 3
                    });
                })
                callback?.();
            } catch (error) {
            }
        });
    }
    // React.useEffect(() => {
    //     btnReRender();
    // }, [Ids]);
    return <Content>
        <Row className="PrintNone">
            <InputGroup>
                <InputGroup.Button onClick={btnFill}>Fill</InputGroup.Button>
                <InputGroup.Button onClick={() => btnReRender()}>Render</InputGroup.Button>
                <Input type="number" value={Id} onChange={(val: any) => setId(Number(val))} />
                <InputGroup.Button onClick={btnPrint}>Print</InputGroup.Button>
            </InputGroup>
            <Slider max={64} onChange={setSkipNo} />
        </Row>
        <A4 className="p145labels visible UnitCode">
            <table>
                <tbody>
                    {Ids.map((r, ri) => <tr key={`r${ri}`}>
                        {r.map((c, ci) => skipNo <= (ri * 5 + ci) ? <td key={`r${ri}c${ci}`}>
                            <canvas className="aztec" about={`Code.LanAnhPT.com/+U/${c}`} /><span>{c}</span>
                        </td> : <td key={`r${ri}c${ci}`}></td>)}
                    </tr>)}
                </tbody>
            </table>
        </A4>
        {isPending ?? <Loader center />}
    </Content>
}