import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, Col, Drawer, Grid, IconButton, Input, InputGroup, Row } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { IdRow, InputCell, NumCell, ReadonlyCell } from "../../c/TableHelper";
import { InputGroupIcon, Lib } from "../../c/Lib";
import { m } from "../../m/Model";
import { Icon } from "@rsuite/icons";
import { FaHeading, FaRegSave } from "react-icons/fa";
import { _PName } from "../Shared/_PName";
import { _Customer } from "../Shared/_Customer";
import { _Confirm } from "../Shared/_Confirm";
import { _TagsPicker } from "../Shared/_TagsPicker";
import { useNumberQueryParam } from "../../c/UrlHelper";
import { useHotkeys } from "react-hotkeys-hook";
import { Barcode } from "../Shared/_Barcode";
interface IData extends m.Product, IdRow {
	//Total: number;
}
export function Detail() {
	//#region const
	const params = useParams<{ Id: string }>();
	const [Dat, setDat] = useState<m.EcProduct>({} as m.EcProduct);
	const [Rows, setRows] = useState<IData[]>([]);
	const [CurRow, setCurRow] = useState<IData>();
	const [Loading, setLoading] = useState<boolean>(false);
	const [Changed, setChanged] = useState<boolean>(false);
	const [Confirming, setConfirming] = useState<boolean>(false);
	const [UId, setUId] = useNumberQueryParam("UId");
	const nav = useNavigate();
	//#endregion
	//#region function
	const submit = (callback?: (Out: m.EcProduct) => void, dat?: m.EcProduct) => {
		if (dat == null) {
			dat = { ...Dat } as m.EcProduct;
		}
		dat.Products = Rows.map((x, i, a) => ({ ...x, EcSubNo: i + 1 }));
		Lib.Post('/API/EcProducts/Update', JSON.stringify(dat), `Update product ${Dat?.Id}`, async (rp) => {
			const Out = await rp.json() as m.EcProduct;
			setDat(Out);
			setChanged(false);
			callback?.(Out);
		});
	};
	const ShortFilterBy = (value: string, _item: any) => {
		return (_item as m.JParam)?.Short?.toUpperCase()?.startsWith(value?.toUpperCase());
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		setLoading(true);
		Lib.Get(`/API/EcProducts/Get?Id=${params.Id}`, `load product ${params.Id}`, async (rs) => {
			const Ecp = await rs.json() as m.EcProduct;
			setDat({ ...Ecp } as m.EcProduct);
			setRows(Ecp.Products.sort((a, b) => (a.EcSubNo ?? -1) - (b.EcSubNo ?? -1)));
			setLoading(false);
			setChanged(false);
		});
		document.title = "Phụ tùng"
			+ params.Id;
	}, [params.Id]);
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows.find(x => x.Id == CurRow.Id))
			setRows([{ ...CurRow },
			...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))].sort((a, b) => (a.EcSubNo ?? -1) - (b.EcSubNo ?? -1)));
		else
			setRows(Rows.map(x => x.Id == CurRow.Id ? { ...CurRow }
				: { ...x, Selected: undefined, Editing: undefined }).sort((a, b) => (a.EcSubNo ?? -1) - (b.EcSubNo ?? -1)));
	}, [CurRow]);
	// React.useEffect(() => {
	// 	try {
	// 		const els = document.querySelectorAll("canvas.aztec") as unknown as HTMLCanvasElement[];
	// 		els.forEach(e => {
	// 			bwipjs.toCanvas(e, {
	// 				bcid: 'azteccodecompact',       // Barcode type
	// 				text: e.getAttribute("about") ?? "",    // Text to encode
	// 				scale: 3
	// 			});
	// 		})
	// 	} catch (error) {
	// 	}
	// });
	//#endregion effect
	//#region event
	const OnClose = () => {
		if (!!!Changed)
			btnClose();
		else {
			setConfirming(true);
		}
	}
	const onRowSelect = (_row: any) => {
		const row = _row as IData;
		if (row.Editing) return;
		setCurRow({ ..._row, Selected: true, Editing: true });
	}
	const onRowChange = (dat: IData) => {
		setCurRow(dat);
		setChanged(true);
	}
	const onDetailChange = (dat: m.EcProduct) => {
		setChanged(true);
		setDat(dat);
	}
	const onChange = (value: any, key: string) => {
		var gDat = Dat as any;
		gDat[key] = value;
		setDat(gDat);
		setChanged(true);
	}
	const onProduct = (product?: m.Product) => {
		if (!!!product) return;
		setCurRow({ ...product, EcId: Dat.Id, Selected: true, EcSubNo: product.EcSubNo ?? (Rows.length + 1), EcSubName: product.EcSubName ?? product.Code });
	}
	//#endregion
	//#region btn
	const btnSave = () => {
		submit(() => {
			btnClose();
		});
	}
	const btnClose = () => {
		if (window.history.length > 1)
			nav(-1);
		else
			nav("..");
	}
	const btnDismiss = () => {
		setConfirming(false);
	}
	const btnSubmitUnits = () => {
		Lib.Post(`/API/Units/UpdateAll`, JSON.stringify(Rows), "Update All m.Unit", async (rs) => {
			OnClose();
		});
	}
	//#region Hotkeys
	// useHotkeys("alt+r", reload);
	useHotkeys("alt+s", btnSave);
	//#endregion
	//#endregion
	return <div>
		<Drawer full open onClose={OnClose} style={{ overflow: "auto" }}>
			<Grid fluid>
				<Row>
					<Col>
						<InputGroup>
							<InputGroupIcon icon={FaHeading}>Tên</InputGroupIcon>
							<Input value={Dat?.OnlineName ?? ""} onChange={(val) => setDat({ ...Dat, OnlineName: val })} />
						</InputGroup>
					</Col>
					<Col>
						<Barcode Ec catalog={'3,2'} onManualProduct={onProduct} onProduct={onProduct} />
					</Col>
					<Col>
						<ButtonGroup>
							<IconButton color="red" appearance="primary" icon={<Icon as={FaRegSave} />} onClick={btnSave} />
						</ButtonGroup>
					</Col>
				</Row>
				<Table loading={Loading} data={Rows} sortColumn="EcSubNo" sortType="asc" autoHeight headerHeight={30} rowHeight={30} onRowClick={onRowSelect} >
					<Column align="right" flexGrow={1}>
						<HeaderCell>EcId</HeaderCell>
						<ReadonlyCell dataKey='EcId' />
					</Column>
					<Column flexGrow={2}>
						<HeaderCell>Mã</HeaderCell>
						<ReadonlyCell dataKey='Code' />
					</Column>
					<Column flexGrow={2}>
						<HeaderCell>Tên</HeaderCell>
						<ReadonlyCell dataKey='Name' />
					</Column>
					<Column flexGrow={2}>
						<HeaderCell>OnlineName</HeaderCell>
						<ReadonlyCell dataKey='OnlineName' />
					</Column>
					<Column align="right" flexGrow={1} sortable>
						<HeaderCell>No</HeaderCell>
						<NumCell dataKey='EcSubNo' />
					</Column>
					<Column flexGrow={2}>
						<HeaderCell>EcSub</HeaderCell>
						<InputCell dataKey='EcSubName' />
					</Column>
				</Table>
			</Grid>
		</Drawer>
		<_Confirm open={Confirming} onSave={btnSave} onClose={btnClose} onDismiss={btnDismiss} />
	</div >
}
