import { Icon } from "@rsuite/icons";
import React from "react";
import { IconType } from "react-icons";
import { FaCopy } from "react-icons/fa";
import { Button, IconButton, Input, InputGroup, Message, toaster, Tooltip, Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import InputGroupButton from "rsuite/esm/InputGroup/InputGroupButton";

interface IException {
    ExceptionMessage: string;
    ExceptionType: string;
    Message: string;
    StackTrace: string;
}
//TODO Build Hostname
// const HOST = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "" : "";
const BASE_URL = !!!process.env.REACT_APP_BASE_API_URL ? "" : process.env.REACT_APP_BASE_API_URL;
// const HOST="";
export class Lib {
    public static MapPath(url: string) {
        return BASE_URL + url
    }
    public static async Warp(body?: BodyInit | null | undefined, title?: string, callback?: (response: Response) => Promise<void>, msg?: React.ReactNode) {
        var response = await fetch(this.MapPath("/api/Browser/Warp"), {
            body: body,
            credentials: 'include',
            method: "Post",//mode:'no-cors'
        });
        if (response.status === 200) {
            if (!!title)
                console.info(title);
            else
                toaster.push(<Message type="info" duration={1000} closable>
                    loaded...
                </Message>, { placement: 'bottomEnd' });
            await callback?.(response);
            return true;
        } else if (response.status === 401) {
            window.history.pushState({}, "", window.location.href);
            window.location.href = "/Account/Login";
            return false;
        } else if (response.status == 500) {
            (async () => {
                const ex = await response.json() as IException;
                if (!!!title)
                    console.error(title, ex);
                else
                    toaster.push(<Message type="error" duration={4500} closable>
                        {ex.ExceptionMessage}<br />
                        {ex.StackTrace}
                    </Message>, { placement: 'bottomEnd' });
            })();
            return false;
        } else if (response.status === 404) {//Not Found
            await callback?.(response);
            return true;
        }
    }
    public static async Get(url: string, title?: string, callback?: (response: Response) => Promise<void>, msg?: React.ReactNode) {
        var response = await fetch(this.MapPath(url), {
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            // },
            credentials: 'include',
            method: "Get",//mode:'no-cors'
            // mode: 'same-origin'
        });
        if (response.status === 200) {
            if (!!title)
                console.info(title, url);
            else
                toaster.push(<Message type="info" duration={1000} closable>
                    loaded...
                </Message>, { placement: 'bottomEnd' });
            if (!!!callback) return response;
            await callback(response);
        } else if (response.status === 401) {
            window.history.pushState({}, "", window.location.href);
            window.location.href = "/Account/Login";
        } else if (response.status == 500) {
            (async () => {
                const ex = await response.json() as IException;
                if (!!!title)
                    console.error(title, ex);
                else
                    toaster.push(<Message type="error" duration={4500} closable>
                        {ex.ExceptionMessage}<br />
                        {ex.StackTrace}
                    </Message>, { placement: 'bottomEnd' });
            })();
        } else if (response.status === 404) {//Not Found
            if (!!!callback) return response;
            await callback(response);
        }
    }
    public static async Upload(url: string, body?: BodyInit | null | undefined, title?: string, callback?: (response: Response) => Promise<void>, msg?: React.ReactNode) {
        var response = await fetch(this.MapPath(url), {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: "Post",//mode:'no-cors'
            // mode:'cors',
            // referrerPolicy:'strict-origin-when-cross-origin',
            body: body
        });
        if (response.status === 200) {
            if (!!title)
                console.info(title, url);
            else
                toaster.push(<Message type="info" duration={1000} closable>
                    Save...
                </Message>, { placement: 'bottomEnd' });
            await callback?.(response);
            return true;
        } else if (response.status === 401) {
            window.history.pushState({}, "", window.location.href);
            window.location.href = "/Account/Login";
            return false;
        } else if (response.status == 500) {
            (async () => {
                const ex = await response.json() as IException;
                if (!!!title)
                    console.error(title, ex);
                else
                    toaster.push(<Message type="error" duration={4500} closable>
                        {ex.ExceptionMessage}<br />
                        {ex.StackTrace}
                    </Message>, { placement: 'bottomEnd' });
            })();
            return false;
        }
    }
    public static async Post(url: string, body?: BodyInit | null | undefined, title?: string, callback?: (response: Response) => Promise<void>, msg?: React.ReactNode) {
        var response = await fetch(this.MapPath(url), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: "Post",//mode:'no-cors'
            body: body
        });
        if (response.status === 200) {
            if (!!title)
                console.info(title, url);
            else
                toaster.push(<Message type="info" duration={1000} closable>
                    Save...
                </Message>, { placement: 'bottomEnd' });
            await callback?.(response);
            return true;
        } else if (response.status === 401) {
            window.history.pushState({}, "", window.location.href);
            window.location.href = "/Account/Login";
            return false;
        } else if (response.status == 500) {
            (async () => {
                const ex = await response.json() as IException;
                if (!!!title)
                    console.error(title, ex);
                else
                    toaster.push(<Message type="error" duration={4500} closable>
                        {ex.ExceptionMessage}<br />
                        {ex.StackTrace}
                    </Message>, { placement: 'bottomEnd' });
            })();
            return false;
        }
    }
    public static HasFlag(i: any, f: any): boolean {
        return (i & f) > 0;
    }
}
//#region Hooks

//#endregion
//#region UI
interface IPropsLinkBtn {
    url: string,
    totalUrl?: string,
    icon?: React.ReactElement,
    children?: React.ReactNode,
    appearance?: TypeAttributes.Appearance,
    color?: TypeAttributes.Color,
    onLog?: (log: string) => void,
    onPercent?: (n: number) => void,
    onComplete?: (rs: Response) => void,
    input?: boolean,
    tooltip?: string,
}
export function LinkBtn(props: IPropsLinkBtn) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const onClick = async () => {
        var total = 0;
        props.onPercent?.(0);
        if (!!!props.totalUrl) {
            setLoading(true);
            props.onLog?.(`${props.url}`);
            const rs = await fetch(Lib.MapPath(props.url), {
                credentials: 'include',
                method: "Get",//mode:'no-cors'
            });
            const log = await rs.text();
            if (!!log)
                props.onLog?.(log);
            setLoading(false);
            props.onComplete?.(rs);
        } else {
            setLoading(true);
            props.onLog?.(`${props.url}`);
            const rs = await fetch(Lib.MapPath(props.totalUrl), {
                credentials: 'include',
                method: "Get",//mode:'no-cors'
            });
            total = (await rs.json()).c;
            for (var i = 0; i < total; i++) {
                props.onLog?.(`${props.url.replace("{i}", `${i}`)}`);
                const rs = await fetch(Lib.MapPath(props.url.replace("{i}", `${i}`)), {
                    credentials: 'include',
                    method: "Get",//mode:'no-cors'
                });
                const log = await rs.text();
                if (!!log && log != "null")
                    props.onLog?.(log);
                props.onPercent?.(Math.round((i + 1) * 100 / total));
            }
            setLoading(false);
            props.onComplete?.(rs);
        }
    }
    var Out = props.input ? <InputGroupButton onClick={onClick} loading={loading} color={props.color} appearance={props.appearance}>{props.children}</InputGroupButton> : (
        props.icon ? <IconButton onClick={onClick} loading={loading} icon={props.icon}>{props.children}</IconButton> :
            <Button onClick={onClick} loading={loading} color={props.color} appearance={props.appearance}>{props.children}</Button>);
    return props.tooltip?<Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{props.tooltip}</Tooltip>}>{Out}</Whisper>:Out;
}
interface IPropsTooltipIcon {
    icon?: IconType,
    children?: React.ReactNode,
    value?: string
}
export function InputGroupCopy(props: IPropsTooltipIcon) {
    return <InputGroup.Button onClick={() => navigator.clipboard.writeText(props.value ?? "")}><Icon as={FaCopy} /></InputGroup.Button>
}
export function InputGroupIcon(props: IPropsTooltipIcon) {
    return <Whisper placement="bottom" trigger="hover" speaker={
        <Tooltip>{props.children}</Tooltip>
    }>
        <InputGroup.Addon><Icon as={props.icon} /></InputGroup.Addon>
    </Whisper>
}
//#endregion