export function Index() {
	//#region const
	//#endregion
	//#region function
	//#endregion
	//#region effect
	//#endregion effect
	//#region event
	//#endregion
	//#region btn
	//#endregion
	document.title="Home";
	return (<div>tst</div>);
}
export function NotFound() {
	//#region const
	//#endregion
	//#region function
	//#endregion
	//#region effect
	//#endregion
	//#region event
	//#endregion
	//#region btn
	//#endregion
	document.title="Not Found";
	return (<div>Not Found</div>);
}