import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Col, Drawer, Grid, IconButton, Row } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { CustomCell, IdRow, ReadonlyCell, shortDateY } from "../../c/TableHelper";
import { Lib } from "../../c/Lib";
import { m } from "../../m/Model";
import { Icon } from "@rsuite/icons";
import { FaBoxOpen, FaRegSave, FaStoreAlt } from "react-icons/fa";
import { _PName } from "../Shared/_PName";
import { _Customer } from "../Shared/_Customer";
import { _Confirm } from "../Shared/_Confirm";
import { _TagsPicker } from "../Shared/_TagsPicker";
import { _Detail } from "./_Detail";
import { useNumberQueryParam } from "../../c/UrlHelper";
import { useHotkeys } from "react-hotkeys-hook";
interface IData extends m.Unit, IdRow {
	//Total: number;
}
export function Units() {
	//#region const
	const params = useParams<{ ptype: string, Id: string }>();
	const [Dat, setDat] = useState<m.Product>();
	const [Rows, setRows] = useState<IData[]>([]);
	const [CurRow, setCurRow] = useState<IData>();
	const [Loading, setLoading] = useState<boolean>(false);
	const [Changed, setChanged] = useState<boolean>(false);
	const [Confirming, setConfirming] = useState<boolean>(false);
	const [UId, setUId] = useNumberQueryParam("UId");
	const nav = useNavigate();
	//#endregion
	//#region function
	const submit = (callback?: (Out: m.Product) => void, dat?: m.Product) => {
		if (dat == null) {
			dat = { ...Dat } as m.Product;
		}
		Lib.Post('/API/Products/Update', JSON.stringify(dat), `Update product ${Dat?.Id}`, async (rp) => {
			const Out = await rp.json() as m.Product;
			setDat(Out);
			setChanged(false);
			callback?.(Out);
		});
	};
	const ShortFilterBy = (value: string, _item: any) => {
		return (_item as m.JParam)?.Short?.toUpperCase()?.startsWith(value?.toUpperCase());
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		setLoading(true);
		Lib.Get(`/API/Products/Get?Id=${params.Id}`, `load product ${params.Id}`, async (rs) => {
			const product = await rs.json() as m.Product;
			Lib.Get(`/API/Units/Product?id=${product.Id}`, `load product sub ${product.Id}`, async (rs) => {
				const data = await rs.json();
				const MAX = "999";
				setRows((data.units as m.Unit[])
					.sort((a, b) => (b.SellDate ?? MAX).localeCompare(a.SellDate ?? MAX) == 0 ?
						(b.CheckDate ?? MAX).localeCompare(a.CheckDate ?? MAX) : (b.SellDate ?? MAX).localeCompare(a.SellDate ?? MAX)
					)
					.map(x => ({ ...x, selected: false } as IData)));
				const curRow = (data.units as m.Unit[]).find(x => x.Id == UId)
				if (!!curRow)
					setCurRow({ ...curRow, Selected: true } as IData);
				setDat({ ...product, TagsId: (data.TagsId as number[]) } as m.Product);
				setLoading(false);
				setChanged(false);
			});
		});
		document.title = (params.ptype == "May" ? "Máy" :
			params.ptype == "PhuTung" ? "Phụ tùng" : "Phụ kiện")
			+ params.Id;
	}, [params.ptype, params.Id]);
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows.find(x => x.Id == CurRow.Id))
			setRows([{ ...CurRow },
			...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))]);
		else
			setRows(Rows.map(x => x.Id == CurRow.Id ? { ...CurRow }
				: { ...x, Selected: undefined, Editing: undefined, Warning: !!(x.Serial) && !!Rows.find(a => a.Serial == x.Serial && a.Id != x.Id) }));
	}, [CurRow]);
	// React.useEffect(() => {
	// 	try {
	// 		const els = document.querySelectorAll("canvas.aztec") as unknown as HTMLCanvasElement[];
	// 		els.forEach(e => {
	// 			bwipjs.toCanvas(e, {
	// 				bcid: 'azteccodecompact',       // Barcode type
	// 				text: e.getAttribute("about") ?? "",    // Text to encode
	// 				scale: 3
	// 			});
	// 		})
	// 	} catch (error) {
	// 	}
	// });
	//#endregion effect
	//#region event
	const OnClose = () => {
		if (!!!Changed)
			btnClose();
		else {
			setConfirming(true);
		}
	}
	const onRowSelect = (_row: any) => {
		const row = _row as IData;
		if (row.Editing) return;
		setCurRow({ ..._row, Selected: true, Editing: true });
	}
	const onRowChange = (dat: IData) => {
		setCurRow(dat);
		setChanged(true);
	}
	const onDetailChange = (dat: m.Product) => {
		setChanged(true);
		setDat(dat);
	}
	//#endregion
	//#region btn
	const btnSave = () => {
		submit(() => {
			btnClose();
		});
	}
	const btnClose = () => {
		if (window.history.length > 1)
			nav(-1);
		else
			nav("..");
	}
	const btnDismiss = () => {
		setConfirming(false);
	}
	const btnOrder = (evt: React.MouseEvent, r: IData) => {
		Lib.Get(`/API/OrderDetails/Get?ODId=${r.ODId}`, undefined, async (rs) => {
			const od = await rs.json() as m.OrderDetail;
			const oType = od.OType == m.OTYPE.buy ? "Mua" :
				od.OType == m.OTYPE.fix ? "SuaChua" :
					od.OType == m.OTYPE.sell2 ? "BanSi" : "BanLe";
			if (evt.ctrlKey)
				window.open(`/Orders/${oType}/Details/${od.OId}`, "_bank");
		})
	}
	const btnShowing = () => {
		const strNow = new Date().toISOString();
		setCurRow({
			...CurRow, State: ((CurRow?.State ?? 0) & m.STATE.Showing) ? ((CurRow?.State ?? 0) & ~m.STATE.Showing) : ((CurRow?.State ?? 0) | m.STATE.Showing)
			, Selected: true, CheckDate: strNow, BuyDate: CurRow?.BuyDate ?? strNow, SellDate: undefined
		} as IData);
	}
	const btnSubmitUnits = () => {
		Lib.Post(`/API/Units/UpdateAll`, JSON.stringify(Rows), "Update All m.Unit", async (rs) => {
			OnClose();
		});
	}
	//#region Hotkeys
	// useHotkeys("alt+r", reload);
	useHotkeys("alt+s", btnSave);
	//#endregion
	//#endregion
	return <div>
		<Drawer full open onClose={OnClose} style={{ overflow: "auto" }}>
			<Grid fluid>
				{!!Dat && <_Detail Dat={Dat} onChange={onDetailChange} Changed={Changed} onSave={btnSave} />}
				<Row><Col><ButtonGroup>
					<IconButton appearance="primary" icon={<Icon as={FaStoreAlt} />} onClick={btnShowing} />
					<IconButton color="red" appearance="primary" icon={<Icon as={FaRegSave} />} onClick={btnSubmitUnits} />
				</ButtonGroup></Col></Row>
				<Table loading={Loading} data={Rows} autoHeight headerHeight={30} rowHeight={30} onRowClick={onRowSelect} >
					<Column flexGrow={2}>
						<HeaderCell>Số thùng</HeaderCell>
						<ReadonlyCell dataKey='Id'>
							{(r: IData, v: string) => <Button size="xs" disabled={!!!r.ODId} onClick={(evt: React.MouseEvent) => btnOrder(evt, r)}>{v}</Button>}
						</ReadonlyCell>
					</Column>
					<Column flexGrow={1}>
						<HeaderCell>Vị trí</HeaderCell>
						<CustomCell dataKey='Store' />
					</Column>
					<Column flexGrow={3}>
						<HeaderCell>Serial</HeaderCell>
						<CustomCell dataKey='Serial' />
					</Column>
					<Column flexGrow={1}>
						<HeaderCell>TT</HeaderCell>
						<ReadonlyCell dataKey='State'>{(r: any, v: number) => <span>
							{!!(v & m.STATE.Void) && <Icon title="Thùng không" as={FaBoxOpen} />}
							{!!(v & m.STATE.Showing) && <Icon title="Trưng bày" as={FaStoreAlt} />}
						</span>}</ReadonlyCell>
					</Column>
					<Column align="right" flexGrow={2}>
						<HeaderCell>Mua</HeaderCell>
						<ReadonlyCell>{(r: IData) => shortDateY(r.BuyDate)}</ReadonlyCell>
					</Column>
					<Column align="right" flexGrow={2}>
						<HeaderCell>Kiểm</HeaderCell>
						<ReadonlyCell>{(r: IData) => shortDateY(r.CheckDate)}</ReadonlyCell>
					</Column>
					<Column align="right" flexGrow={2}>
						<HeaderCell>Bán</HeaderCell>
						<ReadonlyCell>{(r: IData) => shortDateY(r.SellDate)}</ReadonlyCell>
					</Column>
				</Table>
			</Grid>
		</Drawer>
		<_Confirm open={Confirming} onSave={btnSave} onClose={btnClose} onDismiss={btnDismiss} />
	</div >
}
