import React, { DragEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Col, Drawer, Grid, IconButton, Row, Tag } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { CustomCell, NumCell, ReadonlyCell, shortDateY } from "../../c/TableHelper";
import { Lib } from "../../c/Lib";
import { m } from "../../m/Model";
import { Icon } from "@rsuite/icons";
import { FaMagic, FaRegSave, FaTimes } from "react-icons/fa";
import { _PName } from "../Shared/_PName";
import { _Customer } from "../Shared/_Customer";
import { _Confirm } from "../Shared/_Confirm";
import { _TagsPicker } from "../Shared/_TagsPicker";
import { _Detail } from "./_Detail";
import { useHotkeys } from "react-hotkeys-hook";
interface IData extends m.Store {
	Selected?: boolean;
	Editing?: boolean;
}
export function Stores() {
	//#region const
	const params = useParams<{ Id: string }>();
	const [Dat, setDat] = useState<m.Product>();
	const [Rows, setRows] = useState<IData[]>([]);
	const [CurRow, setCurRow] = useState<IData>();
	const [Loading, setLoading] = useState<boolean>(false);
	const [Changed, setChanged] = useState<boolean>(false);
	const [Confirming, setConfirming] = useState<boolean>(false);
	const nav = useNavigate();
	//#endregion
	//#region function
	const submit = (callback?: (Out: m.Product) => void, dat?: m.Product) => {
		if (dat == null) {
			dat = { ...Dat } as m.Product;
		}
		Lib.Post('/API/Products/Update', JSON.stringify(dat), `Update product ${Dat?.Id}`, async (rp) => {
			const Out = await rp.json() as m.Product;
			setDat(Out);
			setChanged(false);
			callback?.(Out);
		});
	};
	const reload = () => {
		document.title = `Loading... Vị trí ${params.Id}`;
		setLoading(true);
		Lib.Get(`/API/Products/Get?Id=${params.Id}`, `load product ${params.Id}`, async (rs) => {
			const product = await rs.json() as m.Product;
			Lib.Get(`/API/Stores/ByPId?PId=${product.Id}`, `load product Stores ${product.Id}`, async (rs) => {
				var data = await rs.json() as m.Store[];
				const MAX = "999";
				var total = (product?.Amount ?? 0);
				data.forEach(x => total -= x.Amount);
				data = data
					.sort((a, b) => (a.SId).localeCompare(b.SId))
					.map(x => ({
						...x,
						Change: x.Amount + total > 0 ? total : -x.Amount,
						selected: false
					} as IData));
				setRows(data);
				setDat({ ...product });
				setLoading(false);
				setChanged(false);
			});
			document.title = "Vị trí " + ((product?.Catalog ?? m.CATALOG.Prouduct) == m.CATALOG.Machine ? "Máy" : (product?.Catalog ?? m.CATALOG.Prouduct) == m.CATALOG.SparePart ? "PT" : "PK") + " " + product?.Code;
		});
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		reload();
	}, [params.Id]);
	React.useEffect(() => {
		//var rs = Rows;
		var total = (Dat?.Amount ?? 0);
		Rows.forEach(x => total -= x.Amount);
		if (!!!CurRow) {
			setRows(Rows.map(x => ({ ...x, Change: x.Amount + total > 0 ? total : -x.Amount })));
			return;
		}
		if (!!!Rows.find(x => x.SId == CurRow.SId))
			setRows([{ ...CurRow, Change: CurRow.Amount + total > 0 ? total : -CurRow.Amount },
			...(Rows.map(x => ({ ...x, Change: x.Amount + total > 0 ? total : -x.Amount, Selected: undefined, Editing: undefined } as IData)))]);
		else
			setRows(Rows.map(x => x.SId == CurRow.SId ? { ...CurRow, Change: x.Amount + total > 0 ? total : -x.Amount }
				: { ...x, Change: x.Amount + total > 0 ? total : -x.Amount, Selected: undefined, Editing: undefined }));
	}, [CurRow]);
	React.useEffect(() => {
		if (!!!Dat) return;
		var sum = 0, checkDate = "";
		Rows.forEach(x => {
			sum += Number(x.PreAmount ?? 0);
			checkDate = checkDate.localeCompare(x.CheckDate ?? "") > 0 ? x.CheckDate ?? "" : checkDate;
		});
		setDat({ ...Dat, PreCheckDate: checkDate, PreAmount: sum });
	}, [Rows]);
	//#endregion effect
	//#region event
	const OnClose = () => {
		if (!!!Changed)
			btnClose();
		else {
			setConfirming(true);
		}
	}
	// const [lastClick, setLastClick] = useState(0);
	const onRowSelect = (_row: any, evt: React.MouseEvent) => {
		const row = _row as IData;
		// if (Date.now() - lastClick < 250) {
		// 	btnCheckAmount(evt, row);
		// 	return;
		// }
		if (row.Editing)
			return;
		// else
		setCurRow({ ..._row, Selected: true, Editing: true });
		// setLastClick(Date.now());
	}
	const onRowChange = (dat: IData) => {
		setCurRow({ ...dat, CheckDate: new Date().toISOString() });
		setChanged(true);
	}
	const onDetailChange = (dat: m.Product) => {
		setChanged(true);
		setDat(dat);
	}
	const [drag, setDrag] = useState<IData>();
	const [dropEffect, setDropEffect] = useState<"link" | "none" | "copy" | "move">();
	const _drag = (evt: DragEvent<HTMLDivElement>, r: IData) => {
		//evt.dataTransfer.items[1]=r;
		if (evt.altKey) {
			evt.dataTransfer.dropEffect = "link";
		} else if (evt.ctrlKey) {
			evt.dataTransfer.dropEffect = "move";
		} else {
			evt.dataTransfer.dropEffect = "copy";
		}
		setDropEffect(evt.dataTransfer.dropEffect);
		if (!!!r)
			return;
		setDrag(r);
		evt.preventDefault();
	};
	const _drop = (evt: DragEvent<HTMLDivElement>, r: IData) => {
		if (dropEffect == "move") {
			if (confirm(`Chuyển ${r.Amount} x [${r.PId}] tới ${r.SId} Và Xóa ${drag?.SId}`))
				Lib.Post(`/API/Stores/SetSId?NSId=${drag?.SId}&del=1`, JSON.stringify(r), 'Set SID', async () => { reload() });
		} else if (dropEffect == "link") {
			console.debug("Drag Drop", r, drag, evt);
			if (confirm(`Xóa ${r.Amount} x [${r.PId}] ${drag?.SId}`))
				Lib.Post(`/API/Stores/SetSId?NSId=${drag?.SId}&del=2`, JSON.stringify(r), 'Set SID', async () => { reload() });
		} else {
			if (confirm(`Chuyển ${r.Amount} x [${r.PId}] tới ${r.SId}`))
				Lib.Post(`/API/Stores/SetSId?NSId=${drag?.SId}`, JSON.stringify(r), 'Set SID', async () => { reload() });
		}
	};
	//#endregion
	//#region btn
	const btnAdd = () => {
		Lib.Get(`/API/OrderDetails/Add?OId=${Dat?.Id}&Ptype=${Dat?.Catalog}`, undefined, async (rs) => {
			const nr = await rs.json() as IData;
			setCurRow({ ...nr, Selected: true });
		});
		setChanged(true);
	}
	const btnDelete = () => {
		setCurRow({ ...CurRow, CheckDate: undefined } as IData);
		setChanged(true);
	}
	const btnMagic = () => {
		if (Dat == undefined) return;
		setDat({ ...Dat, Store: Rows.map(x => x.SId).join(", ") });
	}
	const btnSync = () => {
		submit();
	}
	const btnSave = () => {
		submit(() => {
			btnClose();
		});
	}
	const btnClose = () => {
		if (window.history.length > 1)
			nav(-1);
		else
			nav("..");
	}
	const btnDismiss = () => {
		setConfirming(false);
	}
	const btnSubmitStores = () => {
		Lib.Post(`/API/Stores/UpdateAll`, JSON.stringify(Rows), "Update All store", async (rs) => {
			if (Changed)
				submit(reload);
			else
				reload();
		});
	}
	const btnCheckAmount = (evt: React.MouseEvent, r: IData) => {
		// var total = (Dat?.Amount ?? 0) - (r.Change ?? 0);
		// Rows.forEach(x => total -= x.Amount);
		setCurRow({
			...r, Change: (r.Change ?? 0),
			Amount: r.Amount + (r.Change ?? 0), CheckDate: new Date().toISOString(), Selected: true, Editing: undefined
			, PreAmount: (r.Change)
		});
		evt.stopPropagation();
	}
	//#region Hotkeys
	useHotkeys("alt+r", reload, [Changed, Dat]);
	useHotkeys("alt+s", btnSave, [Dat]);
	//#endregion
	//#endregion
	return <div>
		<Drawer size="full" open onClose={OnClose} style={{ overflow: "auto" }}>
			<Grid fluid>
				{!!Dat && <_Detail Side="Store" Dat={Dat} onChange={onDetailChange} Changed={Changed} onSave={btnSave} />}
				<Row><Col>
					<ButtonGroup>
						<IconButton appearance="primary" icon={<Icon as={FaMagic} />} onClick={btnMagic} />
						<IconButton appearance="primary" icon={<Icon as={FaTimes} />} onClick={btnDelete} />
					</ButtonGroup>
					<ButtonGroup>
						<IconButton color="red" appearance="primary" icon={<Icon as={FaRegSave} />} onClick={btnSubmitStores} />
					</ButtonGroup></Col></Row>
				<Table loading={Loading} data={Rows ?? []} virtualized autoHeight headerHeight={30} rowHeight={30} onRowClick={onRowSelect}>
					<Column flexGrow={2}>
						<HeaderCell>Vị trí</HeaderCell>
						<CustomCell dataKey='SId' />
					</Column>
					<Column align="right" flexGrow={1}>
						<HeaderCell>Thay đổi</HeaderCell>
						<ReadonlyCell dataKey='Change'>
							{(r: IData, v: number) => <span>
								{/* <IconButton icon={<Icon as={FaTimes}/>}></IconButton> */}
								<Button size="xs" appearance="ghost" disabled={v == 0 || (v + r.Amount < 0)} color={v >= 0 ? "blue" : "red"} onClick={(evt: React.MouseEvent) => btnCheckAmount(evt, r)}>{v > 0 ? "+" : ""}{v}</Button>
							</span>}
						</ReadonlyCell>
					</Column>
					<Column align="right" flexGrow={1}>
						<HeaderCell>Số lượng</HeaderCell>
						<NumCell btns dataKey='Amount' onChange={onRowChange} />
					</Column>
					<Column align="right" flexGrow={2}>
						<HeaderCell>Kiểm Ngày</HeaderCell>
						<ReadonlyCell>{(r: IData) => <Tag draggable onDragOver={(evt: DragEvent<HTMLDivElement>) => { _drag(evt, r); }}
							onDragEnd={(evt: DragEvent<HTMLDivElement>) => { _drop(evt, r); }}>{shortDateY(r.CheckDate)}</Tag>}</ReadonlyCell>
					</Column>
				</Table>
			</Grid>
		</Drawer>
		<_Confirm open={Confirming} onSave={btnSave} onClose={btnClose} onDismiss={btnDismiss} />
	</div >
}
