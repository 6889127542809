import React, { useEffect, useState } from "react";
import { CheckTreePicker, Tag } from "rsuite";
import { Db } from "../../c/Db";
import { Lib } from "../../c/Lib";
import { m } from "../../m/Model";
interface IProps {
    value?: number[];
    onSelect?: (value: number[], count?: number) => void;
    onClean?: () => void;
}
interface INode extends m.Tag {
    FullName: string;
    Childs: INode[];
}
var TREE_DATA: INode[] = [];
export function _TagsPicker(props: IProps) {
    const [Value, setValue] = useState<number[]>(props.value ?? []);
    //#region function
    // const loadTags = async () => {
    //     const rs= await Db.All<m.Tag>("Tags");
    //     return rs;
    // }
    //#endregion
    useEffect(() => {
        if (TREE_DATA.length == 0) {
            var node: any = {};
            (async () => {
                const rs=await Db.All<m.Tag>("Tags");
                rs?.forEach(x => {
                    node[x.ID] = { ...x, FullName: x.UName, Childs: [] } as INode;
                });
                for (var k in node) {
                    var x = node[k] as INode;
                    if (!!x.PID) {
                        x.FullName = node[x.PID].FullName + " " + x.UName
                        node[x.PID].Childs.push(x);
                    }
                }
                var root = node[1] as INode;
                TREE_DATA = root.Childs;
            })();
        }
        setValue(props.value ?? []);
    }, [props.value]);
    // useEffect(()=>{
    //     Lib.debug("Tagspicker callback");

    // },[Value]);
    var valueCache: number[] = [];
    const clearTree = (node: INode) => {
        valueCache = valueCache.filter((v, i, a) => v != (node.ID as never));
        node.Childs.forEach(c => {
            clearTree(c);
        });
    };
    const handleSelect = (activeNode: any, value: any) => {
        var node = activeNode as INode;
        if (Value.includes(activeNode.ID as never)) {
            valueCache = Value;
            clearTree(activeNode);
            valueCache = valueCache.filter(x => x != 0);
            // const lst=TREE_ARR.filter(x=>valueCache.includes(x.ID)&&!!x.PID).map(x=>x.PID);
            props.onSelect?.(valueCache.sort());//,lst.length);
            setValue(valueCache);
        } else {
            var val = [...Value, ...(node.TreeKeys?.split(".").map(x => Number(x)) as [])];
            valueCache = val.filter((v, i, a) => a.indexOf(v) === i) as [];
            valueCache = valueCache.filter(x => x != 0);
            // const lst=TREE_ARR.filter(x=>valueCache.includes(x.ID)&&!!x.PID).map(x=>x.PID).sort().filter((v,i,a)=>a.indexOf(v)==i);
            props.onSelect?.(valueCache.sort());//,lst.length);
            setValue(valueCache);
        }
    };
    const searchBy = (keyword: string, label: React.ReactNode, item: any) => (item.FullName as string).includes(keyword.toUpperCase());
    const renderValue = (value: any[], checkedItems: any[]) => {
        return (
            <span>
                {checkedItems.filter(x => x.IsMDisplay|x.IsSpDisplay|x.IsAsDisplay).map(item => item.Name).join(' ') + ' '}
            </span>
        );
    };
    return <CheckTreePicker value={Value} data={TREE_DATA} labelKey="Name"
        childrenKey="Childs" cascade={false} onSelect={handleSelect}
        valueKey="ID" appearance="default" defaultExpandItemValues={Value}
        searchBy={searchBy}
        renderValue={renderValue}
        cleanable block
        onClean={props.onClean}
    />
}