import { createRoot } from 'react-dom/client'

import 'rsuite/dist/rsuite.min.css';
import './dist/index.min.css';
import { CustomProvider } from 'rsuite';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './v/Shared/Layout';
import { Index as Home_Index, NotFound } from './v/Home/Index';
import { NIndex as Orders_NIndex } from './v/Orders/NIndex';
import { Index as Orders_Index } from './v/Orders/Index';
import { Details as Orders_Details } from './v/Orders/Details';
import { Index as Products_Index } from './v/Products/Index';
import { Units as Products_Units } from './v/Products/Units';
import { Stores as Store_ByProduct } from './v/Products/Stores';
import { Orders as Products_ODetails } from './v/Products/Orders';
import { Products as Store_Index } from './v/Stores/Products';
import { Units as Stores_Units } from './v/Stores/Units';
import { SI as Products_SI } from './v/Products/SI';
import { Login } from './v/Account/Login';
import { Index as Print_Index } from './v/Print/Index';
import { Default as Print_Default } from './v/Print/Default';
import { P145 as Print_P145 } from './v/Print/UnitCode';
import { Parameters_Index } from './v/Dev/Parameters';
import { Index as Dev_Index } from './v/Dev/Index';
import { SIBrowser as Dev_SIBrowser } from './v/Dev/SIBrowser';
import { Tools as Dev_Tools } from './v/Dev/Tools';
import { Tags as Dev_Tags } from './v/Dev/Tags';
import { Scan } from './v/Home/Scan';
import { Price } from './v/Print/Price';
import { SubLable } from './v/Print/SubLable';
import { Index as Ecp_Index } from './v/EcProducts/Index';
import { Detail as EcP_Detail } from './v/EcProducts/Detail';

const body = document.querySelector("#root");
const root = createRoot(body!);
// Db.install().then(() =>
root.render(
	<CustomProvider theme="light">
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home_Index />} />
					<Route path='Orders/:otype' element={<Orders_Index />}>
						<Route path='Details/:Id' element={<Orders_Details />} />
					</Route>
					<Route path='NewOrders/:otype' element={<Orders_NIndex />}>
					</Route>
					<Route path='EcProducts'>
						<Route index element={<Ecp_Index />} />
						<Route path='Detail/:Id' element={<EcP_Detail />} />
					</Route>
					<Route path='Products'>
						<Route path='Units/:Id' element={<Products_Units />} />
						<Route path='Stores/:Id' element={<Store_ByProduct />} />
						<Route path='ODetails/:Id' element={<Products_ODetails />} />
						<Route path='SI' element={<Products_SI />} />
						<Route path=':ptype' element={<Products_Index />} />
					</Route>
					<Route path='Stores'>
						<Route path='Units' element={<Stores_Units />} />
						<Route path='BySId' element={<Store_Index />} />
					</Route>
					<Route path='Print' element={<Print_Index />}>
						<Route index element={<Print_Default />} />
						<Route path='P145' element={<Print_P145 />} />
						<Route path='Price' element={<Price/>}/>
						<Route path='SubLable' element={<SubLable/>}/>
					</Route>
					<Route path='Home'>
						<Route path='Scan' element={<Scan />} />
					</Route>
					<Route path="Dev">
						<Route index element={<Dev_Index />} />
						<Route path='Parameters' element={<Parameters_Index />} />
						<Route path='SIBrowser' element={<Dev_SIBrowser />} />
						<Route path='Tools' element={<Dev_Tools />} />
						<Route path='Tags' element={<Dev_Tags />} />
					</Route>
					<Route path='Account/Login' element={<Login />} />
					<Route path='*' element={<NotFound />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</CustomProvider>
);
// );