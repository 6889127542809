import { Icon } from "@rsuite/icons";
import { FaSearch } from "react-icons/fa";
import { Modal } from "rsuite";
import InputGroupButton from "rsuite/esm/InputGroup/InputGroupButton";
import { _SearchTags } from "../Shared/_SearchTags";
interface IProps {
    open?: boolean;
    onClose?: () => void;
}
export function _FindProduct(props: IProps) {
    return <Modal open={props.open} onClose={props.onClose}>
        <Modal.Header><_SearchTags>
            <InputGroupButton type="submit"><Icon as={FaSearch} /></InputGroupButton>
        </_SearchTags></Modal.Header>
        <Modal.Body>

        </Modal.Body>
    </Modal>
}