import { Icon } from '@rsuite/icons';
import { useState } from 'react';
import { FaCalculator, FaCogs, FaDatabase, FaHistory, FaImages, FaPlug, FaQrcode, FaRecycle, FaRegClone, FaSlash, FaStopwatch, FaTags, FaUndo } from 'react-icons/fa';
import { Button, ButtonGroup, ButtonToolbar, Col, Container, Content, DatePicker, Grid, IconButton, Input, InputGroup, Progress, Row, Timeline, Whisper } from 'rsuite';
import { Db } from '../../c/Db';
import { Lib, LinkBtn } from '../../c/Lib';
import { jm } from '../../m/JModel';
import { m } from '../../m/Model';
import { CtrBarcode } from '../../c/CtrBarcode';
export function Tools(props: any) {
    const [percent, setPercent] = useState(0);
    const [Log, setLog] = useState<jm.TimeLog[]>([]);
    const [date, setDate] = useState<Date>();
    const [sql, setSql] = useState<string>("");
    const [status, setStatus] = useState<"active" | "success" | "fail">("success");
    const [barcode, setBarcode] = useState("");
    const btnClear = () => {
        setLog([]);
    };
    const OnLog = (log?: string) => {
        const now = new Date();
        // const l=[...Log, {time:`${now.getSeconds()}s ${now.getMilliseconds()}`,log}];
        // setLog(l);
        setLog(ls => [{ time: `${now.toISOString()}`, log }, ...ls]);
    }
	const onChange = (value: any, key: string) => {
		var gDat = props.Dat as any;
		gDat[key] = value;
		// setDat(gDat);
		props.onChange?.(gDat);
		console.debug(gDat);
	}
    const btnDbReset = async () => {
        OnLog("Reset Database");
        await Db.Reset();
        OnLog("Update Database");
        await Db.install();
        // Db.RefreshData("Changelogs");
    }
    const btnBarcodeTest = () => {
        var barcodes = ["http://code.lananhpt.com/+U/1234"];
        barcodes.forEach(code => {
            OnLog(`${code} ${CtrBarcode.getCodeType(code)}`);
        });
        OnLog(`${barcode} ${CtrBarcode.getCodeType(barcode)}`);
    }
    const OnKey = (event: React.KeyboardEvent<Element>) => {
        //console.debug(event.key,event.ctrlKey);
        if (event.key == "Enter") {
            OnLog(`${barcode} ${CtrBarcode.getCodeType(barcode)}`);
        }
    }
    const btnTest = () => {
        Db.instance(OnLog, setPercent);
    }
    const btnTest2 = () => {
        fetch("https://asia.makitaonlineorder.com/vn/api/item/getPrice?itemNo=0010408350", {
            "headers": {
                "accept": "application/json, text/plain, */*",
                "accept-language": "en-US,en;q=0.9,vi;q=0.8",
                "content-type": "multipart/form-data; boundary=----WebKitFormBoundarysRQDniBOon1ng8hT",
                "sec-ch-ua": "\"Microsoft Edge\";v=\"107\", \"Chromium\";v=\"107\", \"Not=A?Brand\";v=\"24\"",
                "sec-ch-ua-mobile": "?0",
                "sec-ch-ua-platform": "\"Windows\"",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin",
                "x-requested-with": "XMLHttpRequest",
                "cookie": "__RequestVerificationToken=MzAxU3T3V5-skuzMxNeUzTu52mwIr7tx_ZRzEQMH8T9n82Dd_yvjs4NdQXeGbxujNEqqZHNqNUPTYky1YdKCtHaKwt065sspYAIRXSwPfoc1; ASP.NET_SessionId=poixiz4wqxjufwqsn3yke0dr; .AspNet.ApplicationCookie=UhAZZrlapS43vKZDA7LZfDb-1dwaMsUOfb7uey-zZdv2DtyAi4NENl8OzAykuuQ-d_HCV5aWxQvm_7xYAo5dXMXDlb3dVIWndLJFY4sXDOt97nZ_huXSuHzsoL_TacID6QO60Zy4mo32sn3HgKv21xTDN1Pf3siJyRthj5krroY4tUVCxdf1NsgvuYaz7O3czvUlVinehPhZmJowQioxRBtR-KGDsW3Gov_zTajRV9hzJHWVHKUNV8u7vFTWAQVD4XS69-Yj_bFj1_H10pIOAtb4ucHq-bIbGQavTcaV5Jm8vNf4wX9T5lfnQfSINJJHiwdgfUt09ldqWjHTaJ0gxqlkrsIsbz4Qj9AXHdQUv_lqSWcEbjQI_DjoW7l5WCrB9wGp9kMwSehSj361a_EvpYmqamuB0MvYsMVNFow2GX8",
                "Referer": "https://asia.makitaonlineorder.com/vn/shop",
                "Referrer-Policy": "strict-origin-when-cross-origin"
            },
            "body": "------WebKitFormBoundarysRQDniBOon1ng8hT\r\nContent-Disposition: form-data; name=\"itemNo\"\r\n\r\n0010408350\r\n------WebKitFormBoundarysRQDniBOon1ng8hT\r\nContent-Disposition: form-data; name=\"__RequestVerificationToken\"\r\n\r\nqUvCWdfh67j0fzjeM-4di5q1c10nMz77lXO7FXWMmfx9ME7AN-Shwpa3Jq3TquSiQ2DT1fnVeWJ1ElZwJzeb2qixIECfV4MkCYVmtzCNlfYCScBCTZBg7WnPpC4fZcxTMZSOLEX6CxS6waVyKji2BA2\r\n------WebKitFormBoundarysRQDniBOon1ng8hT--\r\n",
            "method": "POST"
        }).then((rs) => {
            console.info(rs);
        });
    }
    const btnTest3 = async () => {
        const rs = await Db.FindAll<m.Tag>("Tags", 'by_UName', "MAY");
        console.debug("Test", rs);
    }
    const btnTest4 = async () => {
        const rs = await Db.Where<m.Tag>("Tags", t => t.UName.includes("MA"));
        console.debug("Test", rs);
    }
    const btnSql = () => {
        setStatus("active");
        setPercent(0);
        Lib.Post("/API/Tools/SQL",JSON.stringify({sql:sql}),"SQL Execute", async (rs) => {
            setPercent(50);
            var txt=await rs.text();
            setPercent(100);
            setStatus("success");
            OnLog(txt);
        });
    }
    return <Container><Content>
        <Grid fluid>
            <Row>
                <Col xs={24}>
                    <Progress percent={percent} status={status} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ButtonToolbar title=''>
                        <LinkBtn url="/API/Orders/DeleteDulicatedDetails" onLog={OnLog} icon={
                            <><Icon className='bg' color='red' as={FaSlash} /><Icon className='fg' as={FaRegClone} /></>}>Fix</LinkBtn>
                        <ButtonGroup>
                            <IconButton onClick={btnDbReset} icon={
                                <><Icon className='bg' color='violet' as={FaRecycle} /><Icon className='fg' as={FaDatabase} /></>}>Reset Db</IconButton>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
                <Col xs={12}>
                    <ButtonToolbar title=''>
                        <LinkBtn url="/API/Products/ResetSellRate" onLog={OnLog} icon={<>
                            <Icon className='bg' color='red' as={FaSlash} />
                            <Icon className='fg' as={FaStopwatch} /></>}></LinkBtn>
                        <DatePicker onChange={(x) => setDate(x ?? undefined)} />
                        <LinkBtn url={`/API/Products/UpdateSellRate?date=${date?.toISOString()}&year=false`} onLog={OnLog} icon={<Icon as={FaStopwatch} />}>Tháng</LinkBtn>
                        <LinkBtn url={`/API/Products/UpdateSellRate?date=${date?.toISOString()}&year=true`} onLog={OnLog} icon={<Icon as={FaStopwatch} />}>Năm</LinkBtn>
                    </ButtonToolbar>
                </Col>
                <Col xs={12}>
                    <ButtonToolbar title=''>
                        <ButtonGroup title='Log'>
                            <IconButton onClick={btnClear} icon={
                                <><Icon className='bg' color='red' as={FaSlash} /><Icon className='fg' as={FaHistory} /></>}>Clean log</IconButton>
                        </ButtonGroup>
                        <ButtonGroup title='Phụ tùng/phụ kiện'>
                            <LinkBtn url="/API/Products/TrimLocalImgs" onLog={OnLog} icon={<Icon as={FaHistory} />}></LinkBtn>
                            <LinkBtn url="/API/Tools/DangCapNhat" onLog={OnLog} icon={<Icon as={FaTags} />}></LinkBtn>
                            <LinkBtn url="/API/Products/ResetAmount?Catalog=0&p=-2" onLog={OnLog} icon={<Icon as={FaCogs} />}></LinkBtn>
                            <LinkBtn url="/API/Products/ResetAmount?Catalog=0&p={i}" onPercent={setPercent} onLog={OnLog} icon={<Icon as={FaCalculator} />} totalUrl="/API/Products/ResetAmount?Catalog=0&p=-1"></LinkBtn>
                            <LinkBtn url="/API/Products/CountAmount?Catalog=0&p={i}" onPercent={setPercent} onLog={OnLog} icon={<Icon as={FaCalculator} />} totalUrl="/API/Products/CountAmount?Catalog=0&p=-1"></LinkBtn>
                        </ButtonGroup>
                        <ButtonGroup title='Máy'>
                            <LinkBtn url="/API/Products/ResetAmount?Catalog=1&p=-2" onLog={OnLog} icon={<Icon as={FaPlug} />}></LinkBtn>
                            <LinkBtn url="/API/Products/ResetAmount?Catalog=1&p={i}" onPercent={setPercent} onLog={OnLog} icon={<Icon as={FaCalculator} />} totalUrl="/API/Products/ResetAmount?Catalog=1&p=-1"></LinkBtn>
                        </ButtonGroup>
                        <ButtonGroup title='PNames'>
                            <LinkBtn url="/API/PNames/UpdateCount" onLog={OnLog} icon={<Icon as={FaCalculator} />}></LinkBtn>
                            <LinkBtn url="/API/Tools/ImagesProc" onLog={OnLog} icon={<Icon as={FaImages} />}></LinkBtn>
                            <LinkBtn tooltip='Fix SI' url="/API/Tools/FixSI?p={i}" onPercent={setPercent} onLog={OnLog} icon={<Icon as={FaImages} />} totalUrl="/API/Tools/FixSI?p=-1"></LinkBtn>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
                <Col xs={12}>
                    <ButtonToolbar title='Test'>
                        <InputGroup>
                            <InputGroup.Button onClick={btnBarcodeTest}>
                                <Icon as={FaQrcode} />
                            </InputGroup.Button>
                            <Input value={barcode} onChange={setBarcode} onKeyPress={OnKey} />
                        </InputGroup>
                    </ButtonToolbar>
                </Col>
                <Col xs={12}>
                    <ButtonToolbar title='Test'>
                        <Button onClick={btnTest}>Test</Button>
                        <Button onClick={btnTest2}>Test2</Button>
                        <Button onClick={btnTest3}>Test3</Button>
                        <Button onClick={btnTest4}>Test4</Button>
                    </ButtonToolbar>
                </Col>
                <Col xs={12}>
                    <ButtonToolbar title='Sql'>
                        <Button onClick={btnSql}>Sql</Button>
                        <LinkBtn tooltip='Fix SI' url="/API/Tools/DbBackup" onPercent={setPercent} onLog={OnLog} icon={<Icon as={FaDatabase} />}/>
                    </ButtonToolbar>
					<Input as="textarea" rows={5} defaultValue={sql} onChange={setSql}></Input>
                </Col>
            </Row>
            <Row>
                <Timeline align="left">
                    {Log.map(x =>
                        <Timeline.Item time={x.time}><span dangerouslySetInnerHTML={{ __html: x.log ?? "" }}></span></Timeline.Item>
                    )}
                </Timeline>
            </Row>
        </Grid>
    </Content></Container>
}