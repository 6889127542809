import "./dist/A4.min.css"
interface IProps {
    children?: React.ReactNode;
    className?: string;
    A5?:boolean;
}
//visible
export function A4(props: IProps) {
    return <div className={`A4 ${props.className}`}>
        {props.A5?<div id="A5">{props.children}</div>:props.children}
    </div>
}