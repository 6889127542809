import { useEffect, useState, useTransition } from "react";
import { Content, IconButton, InputGroup, Loader, Row, Slider, Tag } from "rsuite"
import { A4 } from "./A4"
import { getCache, setCache } from "../../c/UrlHelper";
import { m } from "../../m/Model";
import { Barcode } from "../Shared/_Barcode";
import { FaPlay, FaPrint, FaTimes } from "react-icons/fa";
import { Icon } from "@rsuite/icons";
import { Column, HeaderCell, Table } from "rsuite-table";
import { IdRow, NumCell, ReadonlyCell } from "../../c/TableHelper";
interface IData extends m.Product, IdRow {
    Qty: number;
}
export function SubLable(props: any) {
    const [skipNo, setSkipNo] = useState<number>(-1);
    const [Products, setProducts] = useState<m.Product[][]>([]);
    const [isPending, procRender] = useTransition();
    const [Rows, setRows] = useState<IData[]>([]);
    const [CurRow, setCurRow] = useState<IData>();
    const [Total, setTotal] = useState<number>();
    useEffect(() => {
        // setRows((getCache<IData[]>("Cart") ?? []));
    }, []);
    useEffect(() => {
        setTotal(65 - Rows.reduce((a, b) => {
            const n: any = Number(b.Qty);
            return a + (isNaN(n) ? 0 : n);
        }, 0));
        var l: m.Product[][] = [];
        var x = 0;
        var y = 0;
        l.push([]);
        Rows.reverse().forEach(r => {
            for (var i = r.Qty; i > 0; i--) {
                if (x >= 5) {
                    x = 0; y++;
                    l.push([]);
                }
                if ((y * 5 + x) >= 65) break;
                l[y].push(r);
                x++;
            }
        });
        setProducts(l);
    }, [Rows]);
    useEffect(() => {
        if (!!!CurRow) return;
        if (!!!Rows.find(x => x.Id == CurRow.Id))
            setRows([{ ...CurRow },
            ...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))]);
        else
            setRows(Rows.map(x => x.Id == CurRow.Id ?
                { ...CurRow, Selected: true }
                : { ...x, Selected: undefined, Editing: undefined }));
        //setId(CurRow.Id, { replace: true, state: "" });
    }, [CurRow]);
    const onProduct = (product?: m.Product) => {
        if (!!!product) return;
        var r = Rows.find(x => x.Id == product.Id);
        if (!!!r && CurRow?.Id == null) r = CurRow;
        if (!!!r)
            setCurRow({ ...product, Qty: 1 });
        else
            setCurRow({ ...r, Qty: r.Qty + 1, Editing: undefined });
    }

    const onRowSelect = (_row: any, evt: React.MouseEvent) => {
        const row = _row as IData;
        if (evt.altKey) {
            setCurRow({ ..._row, Quantity: _row.OrderQuantity, Selected: true, Editing: undefined });
        } else {
            if (row.Editing) return;
            setCurRow({ ..._row, Selected: true, Editing: true });
        }
    }

    const onRowChange = (dat: IData) => {
        setCurRow(dat);
    }
    const btnClean = () => {
        setRows([]);
        setCache("Cart", "[]");
    }
    const btnComtine = () => {
        const ps = Products.flatMap(x => x);
        const nRows = Rows.map(x => ({ ...x, Qty: x.Qty - ps.filter(p => p.Id == x.Id).length })).filter(x => x.Qty > 0);
        setCache("Cart", JSON.stringify(nRows));
    }
    const btnPrint = () => {
        //setSkipNo(skipNo);
        // window.print();
        btnReRender(() => {
            window.print();
        })
    }
    const btnReRender = (callback?: () => void) => {
        procRender(() => {
            try {
                callback?.();
            } catch (error) {
            }
        });
    }
    // React.useEffect(() => {
    //     btnReRender();
    // }, [Ids]);
    return <Content>
        <Row className="PrintNone">
            <InputGroup>
                <Barcode catalog='1,3,2' onManualProduct={onProduct} onProduct={onProduct} />
                <IconButton icon={<Icon as={FaTimes} />} color="red" appearance="primary" onClick={btnClean} />
                <IconButton icon={<Icon as={FaPlay} />} color="green" appearance="primary" onClick={btnComtine} />
                <IconButton icon={<Icon as={FaPrint} />} color="blue" appearance="primary" onClick={btnPrint}>{Total}</IconButton>
            </InputGroup>
            <Slider max={64} onChange={setSkipNo} />
        </Row>
        <Row className="PrintNone">
            <Table data={Rows} height={30 * 18} headerHeight={30} rowHeight={30} onRowClick={onRowSelect} rowKey="Id" rowExpandedHeight={150}>
                <Column>
                    <HeaderCell>Mã</HeaderCell>
                    <ReadonlyCell dataKey='Code'>
                        {(r: IData, v: string) => <Tag className="pointer">{v}</Tag>}
                    </ReadonlyCell>
                </Column>
                <Column flexGrow={8}>
                    <HeaderCell>Tên</HeaderCell>
                    <ReadonlyCell dataKey='Name' />
                </Column>
                <Column flexGrow={2} align="right">
                    <HeaderCell>SL</HeaderCell>
                    <NumCell dataKey='Qty' onChange={onRowChange} />
                </Column>
                <Column flexGrow={3} align="right">
                    <HeaderCell>Đơn giá</HeaderCell>
                    <NumCell dataKey='Price' onChange={onRowChange} />
                </Column>
            </Table>
        </Row>
        <A4 className="p145labels visible SubLable">
            <table>
                <tbody>
                    {Products.map((r, ri) => <tr key={`r${ri}`}>
                        {r.map((item, ci) => skipNo <= (ri * 5 + ci) ? <td key={`r${ri}c${ci}`}>
                            <span>{item.Name.replace(item.Code, "").trim()}<br />
                                <u>Ký hiệu sản phẩm</u>: <b>{item.Code}</b><br />
                                <u>Xuất sứ</u>: Trung Quốc<br />
                                <u>Nhà sản xuất</u>: Makita (Kunshan) Co.,Ltd<br />Nan Zi Road, Kunshan Free Trade Zone, Jiangsu P.R, China,215301<br />
                                <u>Nhà cung cấp</u>: Makita Corporation<br />3-11-8 Sumiyoshi-cho, Anjo, Aichi, 446-8502, Japan<br />
                                <u>Nhà nhập khẩu</u>: Công ty TNHH Makita Việt Nam. Kho 4-5-6, Bock 16, 18L1-2, Đường số 3, VSIPII, Khu liên hợp công nghiệp-dịch vụ-đô thị Bình Dương, phường Hòa Phú, thành phố Thủ Dầu Một, tỉnh Bình Dương</span>
                        </td> : <td key={`r${ri}c${ci}`}></td>)}
                    </tr>)}
                </tbody>
            </table>
        </A4>
        {isPending ?? <Loader center />}
    </Content>
}