import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, Grid } from "rsuite";
import { Column, HeaderCell, Table } from "rsuite-table";
import { CustomCell, IdRow, ReadonlyCell, shortDateY } from "../../c/TableHelper";
import { Lib } from "../../c/Lib";
import { m } from "../../m/Model";
import { _PName } from "../Shared/_PName";
import { _Customer } from "../Shared/_Customer";
import { _Confirm } from "../Shared/_Confirm";
import { _TagsPicker } from "../Shared/_TagsPicker";
import { _Detail } from "./_Detail";
import { useHotkeys } from "react-hotkeys-hook";
interface IData extends m.OrderDetail, IdRow {
	TempBA: number;
	TempA: number;
}
export function Orders() {
	//#region const
	const params = useParams<{ ptype: string, Id: string }>();
	const [Dat, setDat] = useState<m.Product>();
	const [Rows, setRows] = useState<IData[]>([]);
	const [CurRow, setCurRow] = useState<IData>();
	const [Loading, setLoading] = useState<boolean>(false);
	const [Changed, setChanged] = useState<boolean>(false);
	const [Confirming, setConfirming] = useState<boolean>(false);
	const nav = useNavigate();
	//#endregion
	//#region function
	const submit = (callback?: (Out: m.Product) => void, dat?: m.Product) => {
		if (dat == null) {
			dat = { ...Dat } as m.Product;
		}
		Lib.Post('/API/Products/Update', JSON.stringify(dat), `Update product ${Dat?.Id}`, async (rp) => {
			const Out = await rp.json() as m.Product;
			setDat(Out);
			setChanged(false);
			callback?.(Out);
		});
	};
	const ShortFilterBy = (value: string, _item: any) => {
		return (_item as m.JParam)?.Short?.toUpperCase()?.startsWith(value?.toUpperCase());
	}
	//#endregion
	//#region effect
	React.useEffect(() => {
		const ptype = params.ptype == "May" ? m.CATALOG.Machine :
			params.ptype == "PhuTung" ? m.CATALOG.SparePart : m.CATALOG.Accessory;
		document.title = `Loading... Lịch sử ${params.Id}`;
		setLoading(true);
		Lib.Get(`/API/Products/Get?Id=${params.Id}`, `load product ${params.Id}`, async (rs) => {
			const product = await rs.json() as m.Product;
			Lib.Get(`/API/Orders/ByProduct?PId=${product.Id}`, `load product sub ${product.Id}`, async (rs) => {
				const data = await rs.json();
				const orders = data.Orders as m.Order[];
				const MAX = "999";
				var Ton = product.Amount;
				setRows((data.OrderDetails as m.OrderDetail[]).map(x => ({
					...x,
					Order: orders.find(y => y.Id == x.OId && y.Otype == x.OType),
					Quantity: (x.OType == m.OTYPE.buy ? 1 : -1) * x.Quantity,
					Disabled: !!!orders.find(y => y.Id == x.OId && y.Otype == x.OType)?.ShipedDate,
					TempA: Ton,
					TempBA: Ton -= !!!orders.find(y => y.Id == x.OId && y.Otype == x.OType)?.ShipedDate ? 0 : ((x.OType == m.OTYPE.buy ? 1 : -1) * x.Quantity),
				} as IData)));
				setDat(product);
				setLoading(false);
				setChanged(false);
			});
			document.title = "Lịch sử " + ((product?.Catalog ?? m.CATALOG.Prouduct) == m.CATALOG.Machine ? "Máy" : (product?.Catalog ?? m.CATALOG.Prouduct) == m.CATALOG.SparePart ? "PT" : "PK") + " " + product?.Code;
			// document.title = (params.ptype == "May" ? "Máy" :
			// 	params.ptype == "PhuTung" ? "Phụ tùng" : "Phụ kiện")
			// 	+ " " + product.Code;
		});
		// document.title = (params.ptype == "May" ? "Máy" :
		// 	params.ptype == "PhuTung" ? "Phụ tùng" : "Phụ kiện")
		// 	+ params.Id;
	}, [params.ptype, params.Id]);
	React.useEffect(() => {
		if (!!!CurRow) return;
		if (!!!Rows.find(x => x.Id == CurRow.Id))
			setRows([{ ...CurRow },
			...(Rows.map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))]);
		else
			setRows(Rows.map(x => x.Id == CurRow.Id ?
				{ ...CurRow }
				: { ...x, Selected: undefined, Editing: undefined }));
		//setId(CurRow.Id, { replace: true, state: "" });
	}, [CurRow]);
	// React.useEffect(() => {
	// 	try {
	// 		const els = document.querySelectorAll("canvas.aztec") as unknown as HTMLCanvasElement[];
	// 		els.forEach(e => {
	// 			bwipjs.toCanvas(e, {
	// 				bcid: 'azteccodecompact',       // Barcode type
	// 				text: e.getAttribute("about") ?? "",    // Text to encode
	// 				scale: 3
	// 			});
	// 		})
	// 	} catch (error) {
	// 	}
	// });
	//#endregion effect
	//#region event
	const OnClose = () => {
		if (!!!Changed)
			btnClose();
		else {
			setConfirming(true);
		}
	}
	const onRowSelect = (_row: any) => {
		const row = _row as IData;
		if (row.Editing) return;
		setCurRow({ ..._row, Selected: true, Editing: true });
	}
	const onDetailChange = (dat: m.Product) => {
		setChanged(true);
		setDat(dat);
	}
	const onRowChange = (dat: IData) => {
		var Ton = dat.TempA;
		var rs = Rows.filter(x => x.OId >= dat.OId).sort((a, b) => a.OId - b.OId);
		rs = rs.map(x => (x.OId == dat.OId ? dat : {
			...x,
			TempBA: Ton = Ton > 0 ? Ton : 0,
			TempA: Ton = Ton - (-x.Quantity),// > 0 ? (Ton - ((x.OType == m.OTYPE.buy ? -1 : 1) * x.Quantity)) : 0),
			Selected: undefined,
			Editing: undefined
		}));
		setRows(Rows.map(x => rs.find(y => y.OId == x.OId) ?? { ...x, Selected: undefined, Editing: undefined } as IData));
		if (!!Dat) {
			setDat({ ...Dat, Amount: Ton > 0 ? Ton : 0, CheckAmount: dat.TempA });
		}
		setCurRow(dat);
		// console.debug("Row change");
		setChanged(true);
	}
	//#endregion
	//#region btn
	const btnAdd = () => {
		Lib.Get(`/API/OrderDetails/Add?OId=${Dat?.Id}&Ptype=${Dat?.Catalog}`, undefined, async (rs) => {
			const nr = await rs.json() as IData;
			setCurRow({ ...nr, Selected: true });
		});
		setChanged(true);
	}
	const btnDelete = () => {
		setCurRow({ ...CurRow, Quantity: 0, OrderQuantity: 0 } as IData);
		setChanged(true);
	}
	const btnSync = () => {
		submit();
	}
	const btnSave = () => {
		submit(() => {
			btnClose();
		});
	}
	const btnClose = () => {
		if (window.history.length > 1)
			nav(-1);
		else
			nav("..");
	}
	const btnDismiss = () => {
		setConfirming(false);
	}
	//#region Hotkeys
	// useHotkeys("alt+r", reload);
	useHotkeys("alt+s", btnSave);
	//#endregion
	//#endregion
	//document.title = `${Id}`;
	return <div>
		<Drawer full open onClose={OnClose}>
			<Grid fluid>
				{!!Dat && <_Detail Dat={Dat} onChange={onDetailChange} Changed={Changed} onSave={btnSave} />}
				<Table loading={Loading} data={Rows} height={30 * 18} headerHeight={30} rowHeight={30} onRowClick={onRowSelect} >
					<Column flexGrow={1}>
						<HeaderCell>SHĐ</HeaderCell>
						<ReadonlyCell dataKey='OId'>{(r: IData) => m.OIdStr(r.Order)}</ReadonlyCell>
					</Column>
					<Column flexGrow={2}>
						<HeaderCell>Khách</HeaderCell>
						<ReadonlyCell dataKey='Order.Customer.ShortName' />
					</Column>
					<Column flexGrow={1}>
						<HeaderCell>Sđt</HeaderCell>
						<ReadonlyCell dataKey='Order.CId' />
					</Column>
					<Column align="right" flexGrow={1}>
						<HeaderCell>Trước</HeaderCell>
						<ReadonlyCell dataKey='TempBA' />
					</Column>
					<Column align="right" flexGrow={1}>
						<HeaderCell>SL</HeaderCell>
						<ReadonlyCell dataKey='Quantity' />
					</Column>
					<Column align="right" flexGrow={1}>
						<HeaderCell>Tồn</HeaderCell>
						<CustomCell dataKey='TempA' onChange={onRowChange} />
					</Column>
					<Column align="right" flexGrow={2}>
						<HeaderCell>Xong</HeaderCell>
						<ReadonlyCell>{(r: IData) => shortDateY(r.Order?.CompleteDate)}</ReadonlyCell>
					</Column>
					<Column align="right" flexGrow={2}>
						<HeaderCell>Giao</HeaderCell>
						<ReadonlyCell>{(r: IData) => shortDateY(r.Order?.ShipedDate)}</ReadonlyCell>
					</Column>
					<Column align="right" flexGrow={2}>
						<HeaderCell>Thu</HeaderCell>
						<ReadonlyCell>{(r: IData) => shortDateY(r.Order?.PayDate)}</ReadonlyCell>
					</Column>
				</Table>
			</Grid>
		</Drawer>
		<_Confirm open={Confirming} onSave={btnSave} onClose={btnClose} onDismiss={btnDismiss} />
	</div >
}
