import { Icon } from "@rsuite/icons";
import { m } from "../../m/Model";
import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight, FaExpand, FaMinus, FaPlus, FaPrint } from "react-icons/fa";
import { Button, ButtonGroup, ButtonToolbar, CheckPicker, Col, IconButton, Modal, Row } from "rsuite";
import { Lib } from "../../c/Lib";
interface IData extends m.SparePart {
    check?: boolean;
    fulltext?: string;
}
interface IProps {
    open?: boolean;
    onClose?: () => void;
    onSave?: (lsp: m.SparePart[]) => void;
    product?: m.Product;
    order?: m.Order;
}
export function _MSI(props: IProps) {
    const svgRef = React.createRef();
    const [svg, setSvg] = React.useState("");
    const [sps, setSps] = React.useState<IData[]>([]);//Selected Spareparts
    const [s_sps, setS_sps] = React.useState<IData[]>([]);//Selecting Spareparts
    const [zoom, setZoom] = React.useState<number>(1);
    const [pageNo, setPageNo] = useState(0);
    React.useEffect(() => {
        const product = props.product ?? props.order?.Product;
        if (product == undefined || !props.open) {
            return;
        }
        Lib.Get(`/api/SparePart/SI?id=${product?.Id}`, "load SI", async (rs) => {
            const data = await rs.json() as m.SparePart[];
            if (data != null) {
                // setSps(data.sort((a, b) => a.PartNo - b.PartNo).map(x => ({
                //     ...x,
                //     fulltext: `[${x.illCode}] ${x.Part.Code} ${x.Part.Name} x ${x.Quantity}`, check: false
                // } as IData)));
                var pids = ((props.order?.OrderDetails ?? []) as m.OrderDetail[]).map(x => x.PId);
                setSps(data.map(x => ({
                    ...x, fulltext: `[${x.illCode}] ${x.Part.Code} ${x.Part.Name} x ${x.Quantity}`
                    , check: pids.indexOf(x.SPId) > -1
                })));
            }
            Lib.Get(`/Content/si/${product?.Code}/${pageNo}.svg`, "load SVG", async (rs) => {
                const dat = await rs.text();
                setSvg(dat.replaceAll(/(\/Backend\/Content\/si)/g, Lib.MapPath("") + "$1"));
            });
        });
    }, [props.open]);
    React.useEffect(() => {
        const product = props.product ?? props.order?.Product;
        Lib.Get(`/Content/si/${product?.Code}/${pageNo}.svg`, "load SVG", async (rs) => {
            const dat = await rs.text();
            setSvg(dat.replaceAll(/(\/Backend\/Content\/si)/g, Lib.MapPath("") + "$1"));
        });
    }, [pageNo]);
    const handelClick = (event: any) => {
        const evt = event as MouseEvent;
        const elm = evt.target as SVGElement;
        const exm = elm?.getAttribute("xlink:href")?.match(/\#\w{3}((\d+)\.?\d+)/i);
        if (!!!exm) return;
        var sp = sps.find(x => (`${x.PartNo}` == exm[2]));
        if (!!!sp) return;
        sp.check = !!!sp?.check;
        setSps(sps.map(x => (x.SPId == sp?.SPId) ? sp : x));
    };
    const [draging, setDraging] = React.useState(false);
    const ref = React.createRef<HTMLDivElement>();
    const handelMD = (_event: any) => {
        setDraging(true);
    };
    const handelMU = (_event: any) => {
        setDraging(false);
    };
    const handelMU_ML = (_event: any) => {
        setDraging(false);
    };
    const handelMM = (_event: any) => {
        if (!draging) return;
        const event = _event as MouseEvent;
        const elm = ref.current as HTMLDivElement;
        elm.scrollTop = elm.scrollTop - event.movementY;
        elm.scrollLeft = elm.scrollLeft - event.movementX;
    };
    const handelMW = (_event: any) => {
        const event = _event as WheelEvent;
        var z = zoom - (event.deltaY * 0.001);
        setZoom(z < 0.25 ? 0.25 : z > 5 ? 5 : z);
    };
    const btnSiSave = () => {
        var lsp: IData[] = [];
        sps.filter(x => (x.check == true)).sort((a,b)=>a.illCode.localeCompare(b.illCode)).map(x => ({ ...x, parent: undefined }))
            .forEach(sp => {
                var o = lsp.find(x => x.SPId == sp.SPId);
                if (!!!o) lsp.push(sp);
                else lsp = lsp.map(x => ((x.SPId == sp.SPId ? { ...o, Quantity: ((o?.Quantity ?? 0) + (sp?.Quantity ?? 0)) } : x) as IData));
            });
        props.onSave?.(lsp);
    };
    const renderValue = (value: any, _items: any) => {
        const items = _items as IData[];
        const html = items.map(x => `<span className="rs-picker-value-item" title="${x.Part.Code}">${x.Part.Name}</span>`)
            .join(`<span class="rs-picker-value-separator">,</span>`);
        return (
            <span className="rs-picker-toggle-value">
                <span className="rs-picker-value-list" dangerouslySetInnerHTML={{ __html: html }}>
                </span><span className="rs-picker-value-count">{items.length}</span>
            </span>);
    }
    const btnZoomIn = () => {
        var z = zoom + 0.25;
        if (z < 5)
            setZoom(z);
    }
    const btnZoom100 = () => {
        setZoom(1);
    }
    const btnZoomOut = () => {
        var z = zoom - 0.25;
        if (z > 0)
            setZoom(z);
    }
    const btnFPage = () => {
        setPageNo(pageNo - 1);
    }
    const btnNPage = () => {
        setPageNo(pageNo + 1);
    }
    const btnPrint = () => {
        window.print();
    }
    const change = (_value: any) => {
        const values = _value as string[];
        setSps(sps.map(x => ({ ...x, check: values.includes(x.illCode) })));
    }
    return props.open ?
        <Modal id="SI" full backdrop="static" role="alertdialog" open={props.open} onClose={props?.onClose} size="lg">
            <style>
                {sps.filter(x => x.check).map(x => `#txt${x.PartNo}\\.1,#ref${x.PartNo}\\.1`).join(',')
                    + `{
    fill-opacity:30%;
    fill:red;
                }
image{
    width:100%;
    height:100%;
}
use{
    fill-opacity:5%;
}
use:hover{
    fill-opacity:25%;
    fill:blue;
}
#SI .rs-modal-header{
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20px;
    height: 72px;
    z-index: 1;
    user-select: none;
}
#SI .rs-modal-body{
    margin:0;
    padding:0;
    max-height:525px !important;
}
                `}
            </style>
            <Modal.Header>
                <CheckPicker sticky block value={sps.filter(x => x.check).map(x => x.illCode)} groupBy="PartNo"
                    onChange={change}
                    renderValue={renderValue} data={sps} labelKey="fulltext" valueKey="illCode" />
                <Row>
                    <Col xs={12}>
                        <ButtonToolbar>
                            <ButtonGroup>
                                <IconButton onClick={btnPrint} icon={<Icon as={FaPrint} />}>In SI</IconButton>
                                <IconButton disabled={pageNo <= 0} onClick={btnFPage} icon={<Icon as={FaAngleLeft} />}></IconButton>
                                <IconButton disabled={pageNo >= ((props.product?.SiCount ?? props.order?.Product?.SiCount) ?? 0)} onClick={btnNPage} icon={<Icon as={FaAngleRight} />}></IconButton>
                            </ButtonGroup>
                            <ButtonGroup>
                                <IconButton onClick={btnZoomIn} icon={<Icon as={FaPlus} />}></IconButton>
                                <IconButton onClick={btnZoom100} icon={<Icon as={FaExpand} />}></IconButton>
                                <IconButton onClick={btnZoomOut} icon={<Icon as={FaMinus} />}></IconButton>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                    <Col xs={6}>
                        {props.product?.Code}
                    </Col>
                    <Col xs={6}>
                        <ButtonGroup>
                            <Button onClick={btnSiSave} appearance="primary">
                                Lưu
                            </Button>
                            <Button onClick={props.onClose} color="orange" appearance="primary">
                                Hủy
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body ref={ref} onWheel={handelMW} onMouseDown={handelMD} onMouseMove={handelMM} onMouseUp={handelMU} onMouseLeave={handelMU_ML}>
                <div
                    style={{ width: `${zoom * 100}%` }} onClick={handelClick} dangerouslySetInnerHTML={{ __html: svg }}>
                </div>
            </Modal.Body>
        </Modal> : <Modal></Modal>;
}