import { Icon } from "@rsuite/icons";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { FaBarcode, FaBell, FaBoxOpen, FaBoxes, FaCalculator, FaCheck, FaCode, FaDollarSign, FaDolly, FaEraser, FaExternalLinkAlt, FaGlobe, FaHeading, FaImages, FaMinus, FaRegObjectGroup, FaReplyAll, FaRuler, FaRulerHorizontal, FaRulerVertical, FaSave, FaSearchengin, FaShopify, FaShoppingCart, FaSlash, FaSubscript, FaTruck, FaWeight, FaWrench } from "react-icons/fa";
import { Carousel, Col, IconButton, Input, InputGroup, InputNumber, Panel, Row, Toggle, Uploader } from "rsuite";
import { Lib, InputGroupIcon, LinkBtn, InputGroupCopy } from "../../c/Lib";
import { m } from "../../m/Model";
import { _TagsPicker } from "../Shared/_TagsPicker";
import { StoreMap } from "../Stores/StoreMap";
import InputGroupButton from "rsuite/esm/InputGroup/InputGroupButton";

interface IProps {
	Side?: "Store";
	Dat?: m.Product;
	Changed?: boolean;
	onSave?: () => void;
	onChange?: (dat: m.Product) => void;
}
export function _Detail(props: IProps) {
	//#region const
	const [UploadingPro, setUploadingPro] = useState(false);
	const [UploadingSto, setUploadingSto] = useState(false);
	const [update, setUpdate] = useState("");
	const [imgIdx, setImgIdx] = useState(0);
	const [SiIdx, setSiIdx] = useState(0);
	const [loading, setLoading] = useState(false);
	//#endregion
	//#region Func
	const imgs = () => {
		var Out = [];
		for (var i = 0; i < (props.Dat?.ImgCount ?? 0); i++) {
			Out.push(
				<Uploader draggable fileListVisible={false} onUpload={() => { setUploadingPro(true) }} onSuccess={onUploaded} action={Lib.MapPath(`/API/Products/Upload?id=${props.Dat?.Id}&dir=Products&imgNo=${i}`)}>
					<img style={{ width: "100%" }} src={Lib.MapPath(`/Content/imgs/Products/${props.Dat?.Code}/${i}.jpg?update=${update}`)} />
				</Uploader>
			);
		}
		return Out;
	}
	const localImgs = () => {
		var Out = [];
		for (var i = 0; i < (props.Dat?.LocalImgCount ?? 0); i++) {
			Out.push(
				<Uploader draggable fileListVisible={false} onUpload={() => { setUploadingPro(true) }} onSuccess={onUploaded} action={Lib.MapPath(`/API/Products/Upload?id=${props.Dat?.Id}&dir=LocalData&imgNo=${i}`)}>
					<img style={{ width: "100%" }} src={Lib.MapPath(`/Content/imgs/LocalData/${props.Dat?.Code}/${i}.jpg?update=${update}`)} />
				</Uploader>
			);
		}
		return Out;
	}
	//#endregion
	//#region effect
	//#endregion effect

	//#region event
	const onNumChange = (value: any, key: string) => {
		const nValue = Number(value);
		if (isNaN(nValue)) return;
		var gDat = props.Dat as any;
		gDat[key] = nValue;
		// setDat(gDat);
		props.onChange?.(gDat);
	}
	const onChange = (value: any, key: string) => {
		var gDat = props.Dat as any;
		gDat[key] = value;
		// setDat(gDat);
		props.onChange?.(gDat);
		console.debug(gDat);
	}
	const onStateChange = (value: boolean) => {
		var state = props.Dat?.State ?? 0;
		var gDat = props.Dat as any;
		state |= m.PROD_STATE.Deprecated;
		if (!value)
			state ^= m.PROD_STATE.Deprecated;
		gDat["State"] = state;
		props.onChange?.(gDat);
		// console.info("State:", value, state);

		// console.info("Bin:", ((~m.PROD_STATE.Deprecated)).toString(2), (m.PROD_STATE.Deprecated).toString(2));
		// console.info("To:", (state).toString(2), (state | m.PROD_STATE.Deprecated).toString(2));
	}
	const onUploaded = () => {
		setUpdate(new Date().toISOString());
		setUploadingPro(false);
		setUploadingSto(false);
	}
	const onImgIdx = (idx: number) => {
		setImgIdx(idx);
	}
	//#endregion

	//#region btn
	const btnDeleteAllImg = () => {
		Lib.Post(`/API/Products/DeleteImg?id=${props.Dat?.Id}&dir=Products&imgNo=-1`, undefined, undefined, async (rs: any) => {
		});
	}
	const btnDeleteImage = () => {
		Lib.Post(`/API/Products/DeleteImg?id=${props.Dat?.Id}&dir=Products&imgNo=${imgIdx}`, undefined, undefined, async (rs: any) => {
			setUpdate(new Date().toISOString());
		});
	}
	const btnDeleteLocalImage = () => {
		Lib.Post(`/API/Products/DeleteImg?id=${props.Dat?.Id}&dir=LocalData&imgNo=${imgIdx}`, undefined, undefined, async (rs: any) => {
			setUpdate(new Date().toISOString());
		});
	}
	const quantity = (!!!props.Dat?.PreAmount) ? (props.Dat?.CheckAmount ?? 0) - (props.Dat?.Amount ?? 0) : props.Dat?.PreAmount;
	const btnCheckByStore = () => {
		var gDat = {
			...props.Dat, CheckAmount: props.Dat?.Amount ?? 0,
			CheckDate: (props.Dat?.PreCheckDate ?? "") == "" ? new Date().toISOString() : props.Dat?.PreCheckDate
		} as m.Product;
		props.onChange?.(gDat);
	}
	const btnCheck = () => {
		var gDat = {
			...props.Dat, Amount: (props.Dat?.Amount ?? 0) + quantity,
			CheckAmount: (props.Dat?.CheckAmount ?? 0) + (props.Dat?.PreAmount ?? 0),
			CheckDate: (props.Dat?.PreCheckDate ?? "") == "" ? new Date().toISOString() : props.Dat?.PreCheckDate
		} as m.Product;
		props.onChange?.(gDat);
	}
	const btnUnpack = () => {
		if (window.confirm("Tính giá 1 món!!")) {
			setLoading(true);
			Lib.Post('/API/Products/Unpack', JSON.stringify(props.Dat), `Unpack product ${props.Dat?.Id}`, async (rp) => {
				setLoading(false);
			});
		}
	}
	//#region Hotkeys
	useHotkeys("alt+c", btnCheck);
	//#endregion

	const renderTogle = (state: m.PROD_STATE, icon: React.ReactNode, dat?: m.Product) => {
		return <Toggle onChange={(c) => onCheck(c, state)} checked={((dat?.State ?? 0) & state) > 0} checkedChildren={icon}
			unCheckedChildren={<><Icon className='bg' color='red' as={FaSlash} />{icon}</>} />
	}
	const renderImTogle = (state: m.PROD_STATE, icon: React.ReactNode, dat?: m.Product) => {
		return <Toggle onChange={(c) => onCheck(c, state)} checked={((dat?.State ?? 0) & state) > 0} unCheckedChildren={icon}
			checkedChildren={<><Icon className='bg' color='red' as={FaSlash} />{icon}</>} />
	}
	const onCheck = (value: boolean, state: m.PROD_STATE) => {
		if (!!!props.Dat) return;
		const noState = ~state;
		var product = props.Dat;
		if (value)
			product = { ...props.Dat, State: props.Dat.State | state };
		else
			product = { ...props.Dat, State: props.Dat.State & noState };
		props.onChange?.(product);
	}
	const OnlinePrice = () => {
		if (!!!props.Dat) return;
		var product = props.Dat;
		var rPrice = Math.floor(Math.random() * 9);
		product = { ...props.Dat, OnlinePrice: (product?.Price ?? 0) - rPrice };
		props.onChange?.(product);
	}
	//#endregion
	return <Panel>
		<Row>
			<Col xs={24} sm={8} xl={3} xxl={3}>
				<InputGroup>
					<InputGroupIcon icon={FaCode}>Mã</InputGroupIcon>
					<Input value={props.Dat?.Code} onChange={(val: any) => onChange(val, "Code")} />
				</InputGroup>
			</Col>
			<Col xs={24} sm={12} xl={6} xxl={5}>
				<InputGroup>
					<InputGroupIcon icon={FaHeading}>Tên</InputGroupIcon>
					<Input defaultValue={props.Dat?.Name} onChange={(val: any) => onChange(val, "Name")} />
				</InputGroup>
			</Col>
			<Col xs={24} sm={12} xl={6} xxl={5}>
				<_TagsPicker value={props.Dat?.TagsId} onSelect={val => onChange(val, "TagsId")} />
			</Col>
			<Col xs={24} sm={12} xl={6} xxl={8}>
				<InputGroup>
					<InputGroupIcon icon={FaBoxes}>Vị trí</InputGroupIcon>
					<Input defaultValue={props.Dat?.Store ?? ""} onChange={(val: any) => onChange(val, "Store")} />
				</InputGroup>
			</Col>
			<Col xs={24} sm={14} xl={6} xxl={5}>
				<InputGroup>
					<InputGroupIcon icon={FaSearchengin}>Từ khóa</InputGroupIcon>
					<Input defaultValue={props.Dat?.PNames} onChange={(val: any) => onChange(val, "PNames")}></Input>
				</InputGroup>
			</Col>
			<Col xs={24} sm={10} xl={6} xxl={3}>
				<InputGroup>
					<InputGroupIcon icon={FaBarcode}>Mã vạch</InputGroupIcon>
					<Input defaultValue={props.Dat?.SKU ?? 0} onChange={(val: any) => onChange(val, "SKU")} />
				</InputGroup>
			</Col>
			<Col sm={12} md={8}>
				<InputGroup>
					<InputGroupIcon icon={FaTruck}>Mua</InputGroupIcon>
					<InputNumber defaultValue={props.Dat?.BuyPrice ?? 0} onChange={(val: any) => onNumChange(val, "BuyPrice")} />
					<InputGroupIcon icon={FaDolly}>Sỉ</InputGroupIcon>
					<InputNumber defaultValue={props.Dat?.Price2 ?? 0} onChange={(val: any) => onNumChange(val, "Price2")} />
				</InputGroup>
			</Col>
			<Col sm={12} md={8}>
				<InputGroup>
					<InputGroupIcon icon={FaShoppingCart}>Lẻ</InputGroupIcon>
					<InputNumber defaultValue={props.Dat?.Price ?? 0} onChange={(val: any) => onNumChange(val, "Price")} />
					<InputGroupIcon icon={FaWrench}>Sửa</InputGroupIcon>
					<InputNumber defaultValue={props.Dat?.FPrice ?? 0} onChange={(val: any) => onNumChange(val, "FPrice")} />
					<InputGroup.Button onClick={OnlinePrice}><Icon as={FaGlobe} /></InputGroup.Button>
					<InputNumber defaultValue={props.Dat?.OnlinePrice ?? 0} onChange={(val: any) => onNumChange(val, "OnlinePrice")} />
				</InputGroup>
			</Col>
		</Row>
		<Row>
			<Col smHidden mdHidden lg={6}>
				<InputGroup>
					<InputGroup.Addon>Mô tả</InputGroup.Addon>
					<Input as="textarea" rows={5} defaultValue={props.Dat?.Description} onChange={(val: any) => onChange(val, "Description")}></Input>
				</InputGroup>
			</Col>
			<Col sm={24} md={18}>
				<Row>
					<Col xs={12}>
						<InputGroup>
							{props.Side == "Store" &&
								(<InputGroup.Button onClick={btnCheck} appearance={props.Dat?.PreAmount == 0 ? "ghost" : "primary"}
									color={quantity > 0 ? "blue" : "red"}>{(props.Dat?.PreAmount ?? 0) == 0 ? `Xác nhận ` : ""}
									{quantity > 0 ? "+" : ""}{quantity}</InputGroup.Button>)}
							<InputGroupIcon icon={FaCalculator}>Số lượng</InputGroupIcon>
							<InputNumber defaultValue={props.Dat?.Amount ?? ""} onChange={(val: any) => onNumChange(val, "Amount")} />
							<InputGroupIcon icon={FaBell}>Số lượng tối thiểu</InputGroupIcon>
							<InputNumber defaultValue={props.Dat?.Priority ?? ""} onChange={(val: any) => onNumChange(val, "Priority")} />
							{props.Side == "Store" && <InputGroup.Button onClick={btnCheckByStore}><Icon as={FaCheck} /></InputGroup.Button>}
						</InputGroup>
					</Col>
					<Col xs={12}>
						<InputGroup>
							<InputGroup.Button loading={loading} onClick={btnUnpack}><Icon as={FaBoxOpen} /></InputGroup.Button>
							<InputGroup.Button onClick={() => window.open(Lib.MapPath('/Products/Stores/' + props.Dat?.PCS_Id), '_blank')}><Icon as={FaExternalLinkAlt} /></InputGroup.Button>
							<Input defaultValue={props.Dat?.PCS_Id} onChange={(val: any) => onChange(val, "PCS_Id")} />
							<InputGroup.Addon><Icon as={FaSubscript} /></InputGroup.Addon>
							<InputNumber defaultValue={props.Dat?.PCSinPKG ?? 1} onChange={(val: any) => onNumChange(val, "PCSinPKG")} />
						</InputGroup>
					</Col>
					<Col xs={24} sm={10} xl={6} xxl={3}>
						<InputGroup>
							<InputGroup.Button onClick={() => window.open(Lib.MapPath('/Products/Stores/' + props.Dat?.NId), '_blank')}><Icon as={FaReplyAll} />Mã Mới</InputGroup.Button>
							<Input defaultValue={props.Dat?.NId} onChange={(val: any) => onChange(val, "NId")} />
						</InputGroup>
					</Col>
					<Col xs={24} sm={14} xl={18} xxl={21}>
						<InputGroup>
							<InputGroupIcon icon={FaWeight}>Cân nặng</InputGroupIcon>
							<InputNumber defaultValue={props.Dat?.Weight ?? ""} onChange={(val: any) => onNumChange(val, "Weight")} />
							<InputGroupIcon icon={FaRulerHorizontal}>W</InputGroupIcon>
							<InputNumber defaultValue={props.Dat?.W ?? ""} onChange={(val: any) => onNumChange(val, "W")} />
							<InputGroupIcon icon={FaRulerVertical}>L</InputGroupIcon>
							<InputNumber defaultValue={props.Dat?.L ?? ""} onChange={(val: any) => onNumChange(val, "L")} />
							<InputGroupIcon icon={FaRuler}>H</InputGroupIcon>
							<InputNumber defaultValue={props.Dat?.H ?? ""} onChange={(val: any) => onNumChange(val, "H")} />
						</InputGroup>
					</Col>
					<Col xs={24} sm={14} xl={18} xxl={21}>
						<InputGroup>
							<InputGroupIcon icon={FaShopify}>ShopeeId</InputGroupIcon>
							<Input readOnly value={props.Dat?.ShopeeId} />
							<InputGroupCopy value={`${props.Dat?.ShopeeId}`} />
							<InputGroup.Button onClick={() => window.open(`https://shopee.vn/lananhltd/${props.Dat?.ShopeeId}/`, '_blank')}><Icon as={FaExternalLinkAlt} /></InputGroup.Button>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col xs={18}>
						<Panel header="Ảnh" collapsible>
							<Col xs={8}>
								<IconButton icon={<Icon as={FaMinus} />} onClick={btnDeleteImage} />
								<IconButton icon={<Icon as={FaImages} />} onClick={btnDeleteAllImg} />
								<Carousel onSelect={onImgIdx}>
									{imgs()}
									<Uploader draggable fileListVisible={false} onUpload={() => { setUploadingPro(true) }} onSuccess={onUploaded} action={Lib.MapPath(`/API/Products/Upload?id=${props.Dat?.Id}&dir=Products`)}>
										<IconButton loading={UploadingPro} icon={<Icon as={FaImages} />}>Hình</IconButton>
									</Uploader>
								</Carousel>
							</Col>
							<Col xs={8}>
								<IconButton icon={<Icon as={FaMinus} />} onClick={btnDeleteLocalImage} />
								<Carousel onSelect={onImgIdx}>
									{localImgs()}
									<Uploader draggable fileListVisible={false} onUpload={() => { setUploadingPro(true) }} onSuccess={onUploaded} action={Lib.MapPath(`/API/Products/Upload?id=${props.Dat?.Id}&dir=LocalData`)}>
										<IconButton loading={UploadingPro} icon={<Icon as={FaImages} />}>Local Images</IconButton>
									</Uploader>
								</Carousel>
							</Col>
							<Col xs={8}>
								<img style={{ width: "100%" }} src={Lib.MapPath(`/Content/imgs/Store/${props.Dat?.Code}/0.jpg?update=${update}`)} />
								<Uploader draggable fileListVisible={false} onUpload={() => { setUploadingSto(true) }} onSuccess={onUploaded} action={Lib.MapPath(`/API/Products/Upload?id=${props.Dat?.Id}&dir=Store`)}>
									<IconButton loading={UploadingSto} icon={<Icon as={FaBoxes} />}>Kho</IconButton>
								</Uploader>
							</Col>
							<IconButton onClick={() => window.open((!!!process.env.REACT_APP_BASE_API_URL ? "https://mng.lananhpt.com" : "http://lananhpt.local") + '/Products/Stores/' + props.Dat?.Id, '_blank')} icon={<Icon as={FaExternalLinkAlt} />} appearance="primary" />
						</Panel>
					</Col>
					<Col xs={3}>
						<InputNumber defaultValue={0} value={SiIdx} onChange={(val: any) => { setSiIdx(val) }} />
						<Uploader multiple draggable fileListVisible={false} action={Lib.MapPath(`/API/SparePart/SIUpload?id=${props.Dat?.Id}&no=${SiIdx}`)}>
							<IconButton icon={<Icon as={FaRegObjectGroup} />}>Upload SI</IconButton>
						</Uploader>
					</Col>
					<Col xs={3}>
						{renderTogle(m.PROD_STATE.Web, <Icon as={FaGlobe} />, props.Dat)}
						{renderImTogle(m.PROD_STATE.Deprecated, <Icon as={FaShoppingCart} />, props.Dat)}
						{renderImTogle(m.PROD_STATE.DeprecatedSp, <Icon as={FaDollarSign} />, props.Dat)}
						<LinkBtn url={`/API/SparePart/SIDelete?id=${props.Dat?.Id}`} icon={<Icon as={FaEraser} />}></LinkBtn>
						<IconButton onClick={props.onSave} icon={<Icon as={FaSave} />} appearance="primary" color="red" disabled={!!!props.Changed} />
					</Col>
				</Row>
			</Col>
			<Col sm={24} md={24}>
				<Panel header="Map" collapsible>
					<StoreMap stores={props.Dat?.Store} />
				</Panel>
			</Col>
		</Row >
	</Panel >
}