import React from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { Lib } from "./Lib";

export function useQueryParam<T>(key: string): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] {
	let [searchParams, setSearchParams] = useSearchParams();
	let paramValue = searchParams.get(key);

	let value = React.useMemo(() => paramValue as any, [paramValue]);

	let setValue = React.useCallback(
		(newValue: T, options?: NavigateOptions) => {
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.set(key, `${newValue}`);
			setSearchParams(newSearchParams, options);
		},
		[key, searchParams, setSearchParams]
	);

	return [value, setValue];
}
export function useNumberQueryParam(key: string): [number, (newQuery: number, options?: NavigateOptions) => void] {
	let [searchParams, setSearchParams] = useSearchParams();
	let paramValue = searchParams.get(key);

	let value = React.useMemo(() => Number(paramValue), [paramValue]);

	let setValue = React.useCallback(
		(newValue: number, options?: NavigateOptions) => {
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.set(key, `${newValue}`);
			setSearchParams(newSearchParams, options);
		},
		[key, searchParams, setSearchParams]
	);

	return [value, setValue];
}
export function useCache<T>(key: string): [T | undefined, (newValue: T) => void] {
	const storagedValue = localStorage.getItem(key);
	const value = React.useMemo(() => !!!storagedValue ? undefined : JSON.parse(storagedValue) as T, [storagedValue]);
	const setValue = React.useCallback((newValue: T) => {
		localStorage.setItem(key, JSON.stringify(newValue));
	}, [key, storagedValue]);
	return [value, setValue];
}
export async function Load2Cache(key: string, url: string) {
	Lib.Get(url, undefined, async (rs) => {
		localStorage.setItem(key, await rs.text());
		const urlDate = getCache<any>("cacheDate") ?? {};
		localStorage.setItem("cacheDate", JSON.stringify({ ...urlDate, [key]: new Date(Date.now()).toISOString() }));
	});
}
export function useUrlCache<T>(key: string, url: string): [T | undefined, (newValue: T) => void] {
	const storagedValue = localStorage.getItem(key);
	const urlDate = _getCache("cacheDate") ?? {};
	if (!!!urlDate[key] || new Date(urlDate[key]).getTime() < (Date.now() - 864e5)) {
		Load2Cache(key, url);
	}
	const value = React.useMemo(() => !!!storagedValue ? undefined : JSON.parse(storagedValue) as T, [storagedValue]);
	const setValue = React.useCallback((newValue: T) => {
		localStorage.setItem(key, JSON.stringify(newValue));
	}, [key, storagedValue]);
	return [value, setValue];
}
export function setCache<T>(key: string, Data: T) {
	localStorage.setItem(key, JSON.stringify(Data));
}
export function getCache<T>(key: string): T {
	return _getCache(key) as T;
}
export function _getCache(key: string) {
	const str = localStorage.getItem(key);
	return !!!str ? undefined : JSON.parse(str);
}