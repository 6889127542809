import { Icon } from "@rsuite/icons";
import { m } from "../../m/Model";
import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight, FaCopy, FaCross, FaExpand, FaMinus, FaPlus, FaSave } from "react-icons/fa";
import { ButtonGroup, ButtonToolbar, CheckPicker, Col, Container, Content, Header, IconButton, Panel, Row, Table } from "rsuite";
import { Lib } from "../../c/Lib";
import { useNavigate } from "react-router-dom";
import { loadPNames, _PName } from "../Shared/_PName";
import { _HNav } from "./_HNav";
import { Column, HeaderCell } from "rsuite-table";
import { CompactSpeakerReadonlyCell, CustomCell, IdRow, ReadonlyCell } from "../../c/TableHelper";
interface IData extends m.SparePart, IdRow {
    check?: boolean;
    fulltext?: string;
}
interface IProps {
}
export function SI(props: IProps) {
    //const svgRef = React.createRef();
    const [svg, setSvg] = React.useState("");
    const [sps, setSps] = React.useState<IData[]>([]);
    const [CurRow, setCurRow] = useState<IData>();
    const [zoom, setZoom] = React.useState<number>(1);
    const [PID, setPID] = React.useState<number>();
    const [pageNo, setPageNo] = useState(0);
    const [product, setProduct] = useState<m.Product | undefined>();
    const nav = useNavigate();
    React.useEffect(() => {
        if (!!!PID) {
            loadPNames();
            return;
        }
        Lib.Get(`/api/SparePart/SI?id=${PID}`, "load SI", async (rs) => {
            const data = await rs.json() as m.SparePart[];
            if (data != null) {
                // var pids = ((props.order?.OrderDetails ?? []) as OrderDetail[]).map(x => x.PId);
                setSps(data.map(x => ({
                    ...x, fulltext: `[${x.illCode}] ${x.Part.Code} ${x.Part.Name} x ${x.Quantity}`
                    // , check: pids.indexOf(x.SPId) > -1
                })));
            }
            Lib.Get(`/api/Products/Get?id=${PID}`, "load product", async (rs) => {
                const product = await rs.json() as m.Product;
                setProduct(product);
                Lib.Get(`/Content/si/${product?.Code}/${pageNo}.svg`, "load SVG", async (rs) => {
                    if (rs.ok) {
                        const dat = await rs.text();
                        setSvg(dat.replaceAll(/(\/Backend\/Content\/si)/g, Lib.MapPath("") + "$1"));
                    } else {
                        console.info("not found");
                        Lib.Warp(JSON.stringify({
                            url: "https://msi.makita.co.jp/MsiWW/MsiWW/MSIWMain.aspx"
                            , values: {}
                        }), "", async (rs) => {

                        });
                    }
                });
            });
        });
    }, [PID]);
    React.useEffect(() => {
        Lib.Get(`/Content/si/${product?.Code}/${pageNo}.svg`, "load SVG", async (rs) => {
            if (rs.ok) {
                const dat = await rs.text();
                setSvg(dat.replaceAll(/(\/Backend\/Content\/si)/g, Lib.MapPath("") + "$1"));
            } else {
                console.info("not found");
                Lib.Warp(JSON.stringify({
                    url: "https://msi.makita.co.jp/MsiWW/MsiWW/MSIWMain.aspx"
                    , values: {}
                }), "", async (rs) => {

                });
            }
        });
    }, [pageNo]);
    React.useEffect(() => {
        if (!!!CurRow) return;
        if (!!sps.find(x => x.SPId == CurRow.SPId && x.PartNo == CurRow.PartNo)) {
            var rows = [{ ...CurRow },
            ...(sps.filter(x => x.SPId != CurRow.SPId || x.PartNo != CurRow.PartNo).map(x => ({ ...x, Selected: undefined, Editing: undefined } as IData)))];
            rows = rows.sort((a, b) => a.illCode.localeCompare(b.illCode));
            rows = rows.map((x, i, a) => { if (i >= a.length - 1) return x; x.Warning = x.illCode == a[i + 1].illCode ? true : undefined; return x; });
            setSps(rows.sort(x => x.PartNo));
        }
    }, [CurRow]);
    //#region event
    const handelClick = (event: any) => {
        const evt = event as MouseEvent;
        const elm = evt.target as SVGElement;
        const exm = elm?.getAttribute("xlink:href")?.match(/\#\w{3}((\d+)\.?\d+)/i);
        if (!!!exm) return;
        var sp = sps.find(x => (`${x.PartNo}` == exm[2]));
        if (!!!sp) return;
        sp.check = !!!sp?.check;
        setSps(sps.map(x => (x.SPId == sp?.SPId) ? sp : x));
    };
    const [draging, setDraging] = React.useState(false);
    const ref = React.createRef<HTMLDivElement>();
    const handelMD = (_event: any) => {
        setDraging(true);
    };
    const handelMU = (_event: any) => {
        setDraging(false);
    };
    const handelMU_ML = (_event: any) => {
        setDraging(false);
    };
    const handelMM = (_event: any) => {
        if (!draging) return;
        const event = _event as MouseEvent;
        const elm = ref.current as HTMLDivElement;
        elm.scrollTop = elm.scrollTop - event.movementY;
        elm.scrollLeft = elm.scrollLeft - event.movementX;
    };
    const handelMW = (_event: any) => {
        const event = _event as WheelEvent;
        var z = zoom - (event.deltaY * 0.001);
        setZoom(z < 0.25 ? 0.25 : z > 5 ? 5 : z);
    };
    const onPName = (pname?: string, PID?: number) => {
        setPID(PID);
    }
    //#endregion
    const renderValue = (value: any, _items: any) => {
        const items = _items as IData[];
        const html = items.map(x => `<span className="rs-picker-value-item" title="${x.Part.Code}">${x.Part.Name}</span>`)
            .join(`<span class="rs-picker-value-separator">,</span>`);
        return (
            <span className="rs-picker-toggle-value">
                <span className="rs-picker-value-list" dangerouslySetInnerHTML={{ __html: html }}>
                </span><span className="rs-picker-value-count">{items.length}</span>
            </span>);
    }
    const btnSiSave = () => {
        // (async () => {
        navigator.clipboard.writeText(JSON.stringify(sps.filter(x => x.check).map(x => x.Part)));
        // })();
    };
    const btnZoomIn = () => {
        var z = zoom + 0.25;
        if (z < 5)
            setZoom(z);
    }
    const btnZoom100 = () => {
        setZoom(1);
    }
    const btnZoomOut = () => {
        var z = zoom - 0.25;
        if (z > 0)
            setZoom(z);
    }
    const btnFPage = () => {
        setPageNo(pageNo - 1);
    }
    const btnNPage = () => {
        setPageNo(pageNo + 1);
    }
    const change = (_value: any) => {
        const values = _value as string[];
        setSps(sps.map(x => ({ ...x, check: values.includes(x.illCode) })));
    }
    const onRowSelect = (_row: any, evt: React.MouseEvent) => {
        setCurRow({ ..._row, Selected: true, Editing: true });
    }
    const onRowChange = (dat: IData) => {
        setCurRow(dat);
    }
    const btnSave = () => {
        if (!!!CurRow) return;
        setCurRow({ ...CurRow, Alt: true });
        Lib.Post('/API/SparePart/Update', JSON.stringify(CurRow), `Update ${CurRow?.fulltext}`, async (rp) => {
            setCurRow({ ...CurRow, Alt: undefined });
        });
    }
    const btnDelete = () => {
        if (!!!CurRow) return;
        setCurRow({ ...CurRow, Alt: true });
        Lib.Post('/API/SparePart/Delete', JSON.stringify(CurRow), `Delete ${CurRow?.fulltext}`, async (rp) => {
            setCurRow({ ...CurRow, Alt: undefined });
        });
    }
    return <Container>
        <Header>
            <_HNav />
            <style>
                {sps.filter(x => x.check).map(x => `#txt${x.PartNo}\\.1,#ref${x.PartNo}\\.1`).join(',')
                    + `{
    fill-opacity:30%;
    fill:red;
                }
image{
    width:100%;
    height:100%;
}
use{
    fill-opacity:5%;
}
use:hover{
    fill-opacity:25%;
    fill:blue;
}
#SIHeader{
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20px;
    height: 72px;
    z-index: 1;
    user-select: none;
}
#SIDia{
    margin:0;
    padding:0;
    max-height:525px !important;
}
                `}
            </style>
        </Header>
        <Content>
            <Row>
                <Col xs={5}>
                    <ButtonToolbar>
                        <IconButton onClick={btnSiSave} icon={<Icon as={FaCopy} />}>Copy</IconButton>
                        <ButtonGroup>
                            <IconButton disabled={pageNo <= 0} onClick={btnFPage} icon={<Icon as={FaAngleLeft} />}></IconButton>
                            <IconButton disabled={pageNo >= (product?.SiCount ?? 0)} onClick={btnNPage} icon={<Icon as={FaAngleRight} />}></IconButton>
                        </ButtonGroup>
                        <ButtonGroup>
                            <IconButton onClick={btnZoomIn} icon={<Icon as={FaPlus} />}></IconButton>
                            <IconButton onClick={btnZoom100} icon={<Icon as={FaExpand} />}></IconButton>
                            <IconButton onClick={btnZoomOut} icon={<Icon as={FaMinus} />}></IconButton>
                        </ButtonGroup>
                        <ButtonGroup>
                            <IconButton loading={CurRow?.Alt} size="xs" onClick={btnSave} icon={<Icon as={FaSave} />}></IconButton>
                            <IconButton loading={CurRow?.Alt} size="xs" onClick={btnDelete} icon={<Icon as={FaCross} />}></IconButton>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
                <Col xs={6}>
                    <_PName disabled={false} onChange={onPName} />
                </Col>
                <Col xs={13}>
                    <CheckPicker sticky block value={sps.filter(x => x.check).map(x => x.illCode)} groupBy="PartNo"
                        onChange={change}
                        renderValue={renderValue} data={sps} labelKey="fulltext" valueKey="illCode" />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Panel id="SIDia" ref={ref} onWheel={handelMW} onMouseDown={handelMD} onMouseMove={handelMM} onMouseUp={handelMU} onMouseLeave={handelMU_ML}>
                        <div style={{ width: `${zoom * 100}%` }} onClick={handelClick} dangerouslySetInnerHTML={{ __html: svg }}>
                        </div>
                    </Panel></Col>
                <Col md={12}>
                    <Table data={sps ?? []} height={30 * 18} headerHeight={30} rowHeight={30} onRowClick={onRowSelect} >
                        <Column>
                            <HeaderCell>PartNo</HeaderCell>
                            <ReadonlyCell dataKey='PartNo'></ReadonlyCell>
                        </Column>
                        {/* <Column>
                            <HeaderCell>SPId</HeaderCell>
                            <ReadonlyCell dataKey='SPId'></ReadonlyCell>
                        </Column> */}
                        <Column flexGrow={1}>
                            <HeaderCell>illCode</HeaderCell>
                            <CustomCell dataKey='illCode' onChange={onRowChange} />
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>fullText</HeaderCell>
                            <ReadonlyCell dataKey='fulltext'></ReadonlyCell>
                        </Column>
                    </Table></Col>
            </Row>
        </Content>
    </Container>;
}